import React, { useState, useEffect, Suspense } from 'react';
import styles from './ChatButton.module.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ChatApp from './ChatApp'; // Import the ChatApp component

const ChatButton = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isChatEnabled, setIsChatEnabled] = useState(false);

  // Helper function to check if the user is logged in and chat is enabled
  const checkChatEnabled = () => {
    const token = localStorage.getItem('accessToken');
    const chatEnabled = localStorage.getItem('chat_enabled') === 'true';
    return token && chatEnabled;
  };

  useEffect(() => {
    // Initial check for chat status
    setIsChatEnabled(checkChatEnabled());

    // Custom event listener for login state change
    const handleLoginStateChange = () => {
      setIsChatEnabled(checkChatEnabled());
    };

    // Listen for custom login state change event
    window.addEventListener('loginStateChange', handleLoginStateChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('loginStateChange', handleLoginStateChange);
    };
  }, []);

  // Function to handle closing the snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  // Toggle the chat modal
  const toggleChat = () => {
    setIsChatOpen((prevState) => !prevState);
  };

  if (!isChatEnabled) {
    return null; // Don't render the button if chat is not enabled
  }

  return (
    <>
      {!isChatOpen && ( // Hide button when chat is open
        <div className={styles.chatButtonContainer}>
          <button
            onClick={toggleChat}
            className={styles.chatButton}
            aria-label="Open Chat"
            title="Chat"
          >
            <img
              src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/RocketChatIcon.png"
              alt="Chat Icon"
              className={styles.chatIcon}
            />
          </button>
        </div>
      )}

      {isChatOpen && (
        <Suspense fallback={<div>Loading Chat...</div>}>
          <ChatApp closeChat={() => setIsChatOpen(false)} /> {/* Render ChatApp instead of ChatModal */}
        </Suspense>
      )}

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ChatButton;
