import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
  Grid,
  Container
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PricingInfo = () => {
  const [isAnnual, setIsAnnual] = useState(true);
  const navigate = useNavigate();

  const pricingInfo = {
    annual: [
      { tier: "Single Student", cost: "£149", savings: "Save 38% compared to monthly" },
      { tier: "Duo (two students)", cost: "£179", savings: "Save 63% compared to two individual plans" },
      { tier: "Family Pack (up to 5 students)", cost: "£199", savings: "Save 83% compared to five individual plans" }
    ],
    monthly: [
      { tier: "Single Student", cost: "£20", savings: "" },
      { tier: "Duo (two students)", cost: "£30", savings: "Pay 25% less for a second student" },
      { tier: "Family Pack (up to 5 students)", cost: "£40", savings: "Save 60% per student compared to individual" }
    ]
  };

  const handleCardClick = (plan) => {
    const userType = localStorage.getItem('usertype');
    if (userType === 'parent') {
      navigate('/Checkout');
    } else {
      navigate('/Register');
    }
  };

  const handleSwitchChange = (event) => {
    setIsAnnual(event.target.checked);
  };

  return (
    <Container maxWidth="md" component="section" sx={{ my: 4 }}>
      <FormGroup sx={{ justifyContent: 'center', display: 'flex', width: '100%' }}>
        <FormControlLabel
          control={<Switch checked={isAnnual} onChange={handleSwitchChange} />}
          label={
            <Typography variant="h6" style={{ color: 'inherit' }}>
              {isAnnual ? "Annual" : "Monthly"}
            </Typography>
          }
          sx={{ margin: 'auto', '.MuiTypography-root': { fontSize: '1.25rem' } }}
        />
      </FormGroup>

      <Grid container spacing={4} justifyContent="center">
        {pricingInfo[isAnnual ? 'annual' : 'monthly'].map((plan, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            {/* Card style updated to a complementary orange */}
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                cursor: 'pointer',
                backgroundColor: '#ff8c00', // <-- Complementary color to #4a91e3
                color: '#ffffff'
              }}
              onClick={() => handleCardClick(plan)}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{ fontWeight: 'bold', color: '#ffffff' }}
                >
                  {plan.tier}
                </Typography>

                {plan.tier !== "Family Pack (up to 5 students)" && (
                  <div style={{ height: '20px' }}></div> // Spacer to align prices
                )}

                {/* Cost in bright red */}
                <Typography
                  variant="h4"
                  style={{
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    color: '#e80909'
                  }}
                >
                  {plan.cost}
                </Typography>

                <Typography
                  variant="body1"
                  style={{
                    fontSize: '1.25rem',
                    fontWeight: 'medium',
                    color: '#ffffff'
                  }}
                >
                  {plan.savings}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PricingInfo;
