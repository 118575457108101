import * as React from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Typography,
  createTheme,
  ThemeProvider,
  Container
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import useApi from '../Utils/useApi';
import LoadingSpinner from '../Utils/LoadingSpinner';

const theme = createTheme({
  typography: {
    htmlFontSize: 20,
    h1: {
      fontSize: 'clamp(2rem, 5vw, 3rem)',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: 'clamp(1rem, 2.5vw, 1.5rem)',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '1.2rem',
    },
  },
});

export default function StudentLogin() {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const api = useApi();
  const location = useLocation();
  const from = location.state?.from || '/studentdashboard';
  const reason = location.state?.reason;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMsg('');
    setLoading(true);

    try {
      const response = await api.post('/user/login', {
        login_value: username,
        password,
        userType: 'student'
      });

      const { access_token, refresh_token, user_id, usertype, chat_enabled } = response.data;

      localStorage.setItem('accessToken', access_token);
      localStorage.setItem('refreshToken', refresh_token);
      localStorage.setItem('user_id', user_id);
      localStorage.setItem('usertype', usertype);
      localStorage.setItem('chat_enabled', chat_enabled);

      // Dispatch the custom event to notify components of login state change
      window.dispatchEvent(new Event('loginStateChange'));

      navigate(from);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMsg('Incorrect username or password. Please try again.');
      } else {
        setErrorMsg('Error logging in. Please try again.');
      }
      console.log('Error logging in as student:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      
      {/* Full-screen gradient background */}
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'linear-gradient(135deg, #4a91e3 0%, #4a91e3 100%)',
          // If you'd rather use a background image:
          // background: "url('https://websitecontent.ams3.cdn.digitaloceanspaces.com/CLoginBackground.png') center/cover no-repeat",
        }}
      >
        <Container maxWidth="sm">
          <Paper
            elevation={6}
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: 2,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#f2182c' }}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography variant="h5" component="h1" sx={{ mb: 2 }}>
              Student Sign In
            </Typography>

            {/* Optional subtext / tagline */}
            <Typography variant="body1" align="center" sx={{ mb: 2 }}>
              Continue your journey with Rocket Learning
            </Typography>

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {(errorMsg || reason) && (
                <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
                  {errorMsg || reason}
                </Typography>
              )}

              <Box sx={{ position: 'relative' }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading}
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: '#ffcc00',
                    color: '#000',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    '&:hover': { backgroundColor: '#ffb700' },
                  }}
                >
                  Sign In
                </Button>

                {loading && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  >
                    <LoadingSpinner size={24} />
                  </Box>
                )}
              </Box>

              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Link
                  href="#"
                  variant="body2"
                  onClick={(e) => {
                    e.preventDefault();
                    alert('Please contact your parent to reset the password through the Parent Dashboard.');
                  }}
                >
                  Forgot password?
                </Link>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
