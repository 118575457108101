// useApi.js
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const useApi = () => {
  const navigate = useNavigate();

  // Handling session expiry and redirection
  function handleSessionExpiry() {
    // Clear token from local storage
    localStorage.removeItem('accessToken');
    const userType = localStorage.getItem('userType');
    localStorage.removeItem('userType');

    // Decide where to redirect based on user type
    if (userType === 'parent') {
      navigate('/ParentLogin');
    } else if (userType === 'student') {
      navigate('/StudentLogin');
    } else {
      // Default case if user type is unknown or not set
      navigate('/');  // Redirect to a default location, e.g., homepage or common login page
    }
  }

  async function refreshAccessToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    try {
      const response = await axios.post(
        'https://orca-app-mna7u.ondigitalocean.app/user/token/refresh',
        {}, // Empty body
        { headers: { 'Authorization': `Bearer ${refreshToken}` } }
      );
      const { access_token } = response.data;
      localStorage.setItem('accessToken', access_token);
      return access_token;
    } catch (error) {
      console.error('Refresh token failed:', error);
      return null;
    }
  }
  
  // Create axios instance
  const api = axios.create({
    baseURL: 'https://orca-app-mna7u.ondigitalocean.app',  // Set your API base URL here
    //baseURL: 'http://localhost:5000', 
  });

  // Set the Authorization header dynamically for each request
  api.interceptors.request.use(config => {
    const token = localStorage.getItem('accessToken');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });

  api.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const newAccessToken = await refreshAccessToken();
        if (newAccessToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        }
        // Only reject the Promise here, let the component handle what happens next
      }
      return Promise.reject(error);
    }
  );

  return api;
}

export default useApi;