// useCoreApi.js
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const useCoreApi = () => {
  const navigate = useNavigate();

  function handleSessionExpiry() {
    localStorage.removeItem('accessToken');
    const userType = localStorage.getItem('userType');
    localStorage.removeItem('userType');

    if (userType === 'parent') {
      navigate('/ParentLogin');
    } else if (userType === 'student') {
      navigate('/StudentLogin');
    } else {
      navigate('/');
    }
  }

  async function refreshAccessToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    try {
      const response = await axios.post(
        'https://orca-app-mna7u.ondigitalocean.app/user/token/refresh',
        {},
        { headers: { Authorization: `Bearer ${refreshToken}` } }
      );
      const { access_token } = response.data;
      localStorage.setItem('accessToken', access_token);
      return access_token;
    } catch (error) {
      console.error('Core API Refresh token failed:', error);
      return null;
    }
  }

  // Create axios instance for Core API
  const coreApi = axios.create({
    baseURL: 'https://rl-core-app-kpx3b.ondigitalocean.app',
  });

  coreApi.interceptors.request.use(config => {
    const token = localStorage.getItem('accessToken');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });

  coreApi.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const newAccessToken = await refreshAccessToken();
        if (newAccessToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return coreApi(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );

  return coreApi;
};

export default useCoreApi;
