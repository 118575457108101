import React, { useEffect, useState } from 'react';
import useApi from '../Utils/useApi';
import { Box, Button, Typography, TextField, Card, CardContent, Grid, IconButton } from '@mui/material';
import { debounce } from 'lodash';  // Use lodash for debouncing input changes
import DeleteIcon from '@mui/icons-material/Delete'; // Material UI delete icon
import AddIcon from '@mui/icons-material/Add';

const QuestionAdmin = () => {
  const [flaggedQuestions, setFlaggedQuestions] = useState([]);
  const [reportedIssues, setReportedIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [editingIssue, setEditingIssue] = useState(null);
  const [updatedQuestionText, setUpdatedQuestionText] = useState('');
  const [updatedAnswer, setUpdatedAnswer] = useState('');
  const [updatedOptions, setUpdatedOptions] = useState([]);
  const api = useApi();

  // Fetch flagged questions and reported issues when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [flaggedQuestionsResponse, reportedIssuesResponse] = await Promise.all([
          api.get('/quiz/flagged_questions'),
          api.get('/quiz/reported_issues')
        ]);
        setFlaggedQuestions(flaggedQuestionsResponse.data);
        setReportedIssues(reportedIssuesResponse.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load flagged questions and reported issues.');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSaveQuestion = async (question_id) => {
    try {
      const payload = {
        question_text: updatedQuestionText,
        answer: updatedAnswer,
        options: updatedOptions.length > 0 ? updatedOptions : null // Set to null if no options
      };

      const updatedQuestionResponse = await api.put(`/quiz/questions/${question_id}`, payload);
  
      const updatedQuestion = updatedQuestionResponse.data;
  
      setFlaggedQuestions(prevFlaggedQuestions =>
        prevFlaggedQuestions.map(q =>
          q.question_id === question_id
            ? { ...q, question_text: updatedQuestion.question_text, answer: updatedQuestion.answer, options: updatedQuestion.options }
            : q
        )
      );
  
      setReportedIssues(prevReportedIssues =>
        prevReportedIssues.map(issue =>
          issue.question_id === question_id
            ? { ...issue, question_text: updatedQuestion.question_text, answer: updatedQuestion.answer, options: updatedQuestion.options }
            : issue
        )
      );
  
      alert('Question updated successfully!');
      setEditingQuestion(null);
      setEditingIssue(null);
    } catch (err) {
      console.error('Error updating question:', err);
      alert('Failed to update question.');
    }
  };

  const handleResolveFlaggedQuestion = async (question_id) => {
    try {
      await api.put(`/quiz/flagged_questions/${question_id}/resolve`);
      setFlaggedQuestions(flaggedQuestions.filter(q => q.question_id !== question_id));
    } catch (err) {
      console.error('Error resolving flagged question:', err);
    }
  };

  const handleDeleteReportedIssue = async (issue_id) => {
    try {
      await api.delete(`/quiz/reported_issues/${issue_id}`);
      setReportedIssues(reportedIssues.filter(issue => issue.issue_id !== issue_id));
      alert('Reported issue deleted!');
    } catch (err) {
      console.error('Error deleting reported issue:', err);
    }
  };

  const handleDeleteQuestion = async (question_id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this question? This action cannot be undone.');
    if (!confirmDelete) return;

    try {
      await api.delete(`/quiz/questions/${question_id}`);
      setFlaggedQuestions(flaggedQuestions.filter(q => q.question_id !== question_id));
      setReportedIssues(reportedIssues.filter(issue => issue.question_id !== question_id));
      alert('Question deleted!');
    } catch (err) {
      console.error('Error deleting question:', err);
    }
  };

// Debounced input handlers
const debouncedUpdateQuestionText = debounce((value) => {
  setUpdatedQuestionText(value);
}, 300);

const debouncedUpdateAnswer = debounce((value) => {
  setUpdatedAnswer(value);
}, 300);

// Handler for updating options
const handleOptionChange = (index, value) => {
  const newOptions = [...updatedOptions];
  newOptions[index] = value;
  setUpdatedOptions(newOptions);
};

const handleAddOption = () => {
  setUpdatedOptions([...updatedOptions, '']);
};

const handleRemoveOption = (index) => {
  const newOptions = [...updatedOptions];
  newOptions.splice(index, 1);
  setUpdatedOptions(newOptions);
};

if (loading) {
  return <div>Loading issues and questions...</div>;
}

if (error) {
  return <div>{error}</div>;
}

return (
  <Grid container spacing={3}>
    {/* Left-hand side: Reported Issues */}
    <Grid item xs={12} md={7}>
      <Box>
        <Typography variant="h4" gutterBottom>
          Reported Issues
        </Typography>

        <Grid container direction="column" spacing={3}>
          {reportedIssues.map((issue) => (
            <Grid item key={issue.issue_id}>
              <Card>
                <CardContent style={{ position: 'relative' }}>
                  {editingIssue === issue.issue_id ? (
                    <>
                      <TextField
                        fullWidth
                        label="Question Text"
                        defaultValue={updatedQuestionText}
                        onChange={(e) => debouncedUpdateQuestionText(e.target.value)}
                      />
                      <TextField
                        fullWidth
                        label="Answer"
                        defaultValue={updatedAnswer}
                        onChange={(e) => debouncedUpdateAnswer(e.target.value)}
                        margin="normal"
                      />
                      {/* Options Editing for Reported Issues if applicable */}
                      {/* If reported issues also have options, include similar option handlers here */}
                      <Button
                        variant="contained"
                        onClick={() => handleSaveQuestion(issue.question_id)}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => setEditingIssue(null)}
                        style={{ marginLeft: '10px' }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Typography variant="h6">
                        {issue.question_text}
                      </Typography>
                      <Typography>
                        <strong>Reported Issue:</strong> {issue.reported_issue_text}
                      </Typography>
                      <Typography>
                        <strong>Answer:</strong> {issue.answer}
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setEditingIssue(issue.issue_id);
                          setUpdatedQuestionText(issue.question_text);
                          setUpdatedAnswer(issue.answer);
                          setUpdatedOptions(issue.options || []); // Initialize options
                        }}
                      >
                        Edit Question
                      </Button>
                      <Button
                        variant="contained"
                        style={{ marginLeft: '10px' }}
                        onClick={() => window.open(`/Classroom/${issue.lesson_id}`, '_blank')}
                      >
                        Launch Lesson
                      </Button>
                      <Button
                        variant="contained"
                        style={{ marginLeft: '10px' }}
                        onClick={() => handleDeleteReportedIssue(issue.issue_id)}
                      >
                        Resolve Issue
                      </Button>
                      <IconButton
                        aria-label="delete"
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '10px',
                          color: 'red',
                        }}
                        size="small"
                        onClick={() => handleDeleteQuestion(issue.question_id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Grid>

    {/* Right-hand side: Flagged Questions */}
    <Grid item xs={12} md={5}>
      <Box>
        <Typography variant="h4" gutterBottom>
          Flagged Questions
        </Typography>

        <Grid container direction="column" spacing={3}>
          {flaggedQuestions.map((question) => (
            <Grid item key={question.question_id}>
              <Card>
                <CardContent style={{ position: 'relative' }}>
                  {editingQuestion === question.question_id ? (
                    <>
                      <TextField
                        fullWidth
                        label="Question Text"
                        defaultValue={updatedQuestionText}
                        onChange={(e) => debouncedUpdateQuestionText(e.target.value)}
                      />
                      <TextField
                        fullWidth
                        label="Answer"
                        defaultValue={updatedAnswer}
                        onChange={(e) => debouncedUpdateAnswer(e.target.value)}
                        margin="normal"
                      />

                      {/* Options Section */}
                      <Box mt={2}>
                        <Typography variant="subtitle1">Options</Typography>
                        {updatedOptions && updatedOptions.map((option, index) => (
                          <Box key={index} display="flex" alignItems="center" mb={1}>
                            <TextField
                              fullWidth
                              label={`Option ${index + 1}`}
                              value={option}
                              onChange={(e) => handleOptionChange(index, e.target.value)}
                            />
                            <IconButton
                              aria-label="delete-option"
                              color="error"
                              onClick={() => handleRemoveOption(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                        <Button
                          variant="outlined"
                          startIcon={<AddIcon />}
                          onClick={handleAddOption}
                        >
                          Add Option
                        </Button>
                      </Box>

                      <Box mt={2}>
                        <Button
                          variant="contained"
                          onClick={() => handleSaveQuestion(question.question_id)}
                        >
                          Save
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setEditingQuestion(null);
                            setUpdatedOptions([]); // Reset options state
                          }}
                          style={{ marginLeft: '10px' }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          style={{ marginLeft: '10px' }}
                          onClick={() => handleResolveFlaggedQuestion(question.question_id)}
                        >
                          Resolve Question
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography variant="h6">
                        {question.question_text}
                      </Typography>
                      <Typography>
                        <strong>Answer:</strong> {question.answer}
                      </Typography>
                      <Typography>
                        <strong>Explanation:</strong> {question.explanation}
                      </Typography>
                      {question.options && (
                        <Box mt={1}>
                          <Typography variant="subtitle1"><strong>Options:</strong></Typography>
                          <ul>
                            {question.options.map((opt, idx) => (
                              <li key={idx}>{opt}</li>
                            ))}
                          </ul>
                        </Box>
                      )}

                      <Box mt={2}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setEditingQuestion(question.question_id);
                            setUpdatedQuestionText(question.question_text);
                            setUpdatedAnswer(question.answer);
                            setUpdatedOptions(question.options || []); // Initialize options
                          }}
                        >
                          Edit Question
                        </Button>
                        <Button
                          variant="contained"
                          style={{ marginLeft: '10px' }}
                          onClick={() => window.open(`/Classroom/${question.lesson_id}`, '_blank')}
                        >
                          Launch Lesson
                        </Button>
                        <Button
                          variant="contained"
                          style={{ marginLeft: '10px' }}
                          onClick={() => handleResolveFlaggedQuestion(question.question_id)}
                        >
                          Resolve Question
                        </Button>
                        <IconButton
                          aria-label="delete"
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                            color: 'red',
                          }}
                          size="small"
                          onClick={() => handleDeleteQuestion(question.question_id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Grid>
  </Grid>
);
};

export default QuestionAdmin;