import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Button,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi';
import LoadingSpinner from '../Utils/LoadingSpinner';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const SUBJECT_ID = 2;
const defaultTheme = createTheme();

function Science() {
  const navigate = useNavigate();
  const api = useApi();
  const coreApi = useCoreApi();

  const access_token = localStorage.getItem('accessToken');
  const student_id = localStorage.getItem('user_id');
  const parsedYear = parseInt(localStorage.getItem('student_year'), 10);
  const initialYear = Number.isNaN(parsedYear) ? 1 : parsedYear;

  const [selectedYear, setSelectedYear] = useState(initialYear);
  const [yearInitialized, setYearInitialized] = useState(false);
  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  const [completedLessons, setCompletedLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userHasChosenYear, setUserHasChosenYear] = useState(false);


  const subTopics = useMemo(() => [
    // Paste your science subtopics    { id: 31, title: "Plants", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-plants.avif" },
    { id: 32, title: "Animals, including humans", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-animals.avif" },
    { id: 33, title: "Everyday materials", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-materials.avif" },
    { id: 34, title: "Seasonal changes", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-seasonal.avif" },
    { id: 35, title: "Living things and their habitats", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-livingthings.avif" },
    { id: 36, title: "Plants", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-plants.avif" },
    { id: 37, title: "Animals, including humans", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-animals.avif" },
    { id: 38, title: "Uses of everyday materials", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-materials.avif" },
    { id: 39, title: "Plants", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-plants.avif" },
    { id: 40, title: "Animals, including humans", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-animals.avif" },
    { id: 41, title: "Rocks", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-rocks.avif" },
    { id: 42, title: "Light", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-light.avif" },
    { id: 43, title: "Forces and Magnets", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-magnets.avif" },
    { id: 44, title: "Living things and their habitats", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-livingthings.avif" },
    { id: 45, title: "Animals, including humans", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-animals.avif" },
    { id: 46, title: "States of matter", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-statesofmatter.avif" },
    { id: 47, title: "Sound", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-sound.avif" },
    { id: 48, title: "Electricity", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-electricity.avif" },
    { id: 49, title: "Living things and their habitats", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-livingthings.avif" },
    { id: 50, title: "Animals, including humans", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-animals.avif" },
    { id: 51, title: "Properties and changes of materials", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-materials.avif" },
    { id: 52, title: "Earth and space", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-earthandspace.avif" },
    { id: 53, title: "Forces", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-magnets.avif" },
    { id: 54, title: "Living things and their habitats", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-livingthings.avif" },
    { id: 55, title: "Animals including humans", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-animals.avif" },
    { id: 56, title: "Evolution and inheritance", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-evolution.avif" },
    { id: 57, title: "Light", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-light.avif" },
    { id: 58, title: "Electricity", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-electricity.avif" },
    { id: 7, title: "Biology", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-biology.avif" },
    { id: 8, title: "Chemistry", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-chemistry.avif" },
    { id: 9, title: "Physics", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-physics.avif" },
    { id: 7, title: "Biology", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-biology.avif" },
    { id: 8, title: "Chemistry", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-chemistry.avif" },
    { id: 9, title: "Physics", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-physics.avif" },
    { id: 7, title: "Biology", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-biology.avif" },
    { id: 8, title: "Chemistry", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-chemistry.avif" },
    { id: 9, title: "Physics", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-physics.avif" },
    { id: 7, title: "Biology", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-biology.avif" },
    { id: 8, title: "Chemistry", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-chemistry.avif" },
    { id: 9, title: "Physics", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-physics.avif" },
    { id: 7, title: "Biology", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-biology.avif" },
    { id: 8, title: "Chemistry", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-chemistry.avif" },
    { id: 9, title: "Physics", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-physics.avif" }, 
  ], []);

  // Axios 401 Interceptor
  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response?.status === 401) {
        navigate('/StudentLogin');
      }
      return Promise.reject(err);
    }
  );

  useEffect(() => {
    if (!access_token) {
      console.warn('Missing access token. Redirecting to login.');
      navigate('/StudentLogin');
    }
  }, [access_token, navigate]);

  const fetchLastCompletedYear = async () => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/last_completed_year_for_subject/${SUBJECT_ID}`,
        { headers: { Authorization: `Bearer ${access_token}` }, timeout: 5000 }
      );
      if (response.status === 200 && response.data?.year_id) {
        return response.data.year_id;
      }
    } catch (err) {
      console.error('Error fetching last completed year:', err);
    }
    return null;
  };

  const fetchCompletedLessons = async (subTopicId, year) => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/completed_lessons/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data.map((l) => l.lesson_id);
      }
    } catch (err) {
      console.error(`Error fetching completed lessons for ${subTopicId}:`, err);
    }
    return [];
  };

  const fetchLessonsBySubtopic = async (subTopicId, year) => {
    try {
      const response = await coreApi.get(
        `/lessons/lessons_by_subject/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      console.error(`Error fetching lessons for subtopic ${subTopicId}:`, err);
    }
    return [];
  };

  const fetchAllDataForYear = async (year) => {
    setIsLoading(true);
    setError(null);

    try {
      const relevantSubTopics = subTopics.filter((st) => st.year === year);

      const completedPromises = relevantSubTopics.map((st) =>
        fetchCompletedLessons(st.id, year)
      );
      const completedArrays = await Promise.all(completedPromises);
      const allCompletedIds = completedArrays.flat();
      setCompletedLessons(allCompletedIds);

      const lessonPromises = relevantSubTopics.map(async (st) => {
        const lessons = await fetchLessonsBySubtopic(st.id, year);
        return { subTopicId: st.id, lessons };
      });
      const lessonsArray = await Promise.all(lessonPromises);

      const newLessonsBySubtopic = {};
      for (let item of lessonsArray) {
        const updated = item.lessons.map((lesson) => ({
          ...lesson,
          is_completed: allCompletedIds.includes(lesson.id),
        }));
        newLessonsBySubtopic[item.subTopicId] = updated;
      }

      setLessonsBySubtopic(newLessonsBySubtopic);
    } catch (err) {
      console.error('fetchAllDataForYear error:', err);
      setError('Error fetching lessons. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const retryFetch = () => {
    fetchAllDataForYear(selectedYear);
  };

  useEffect(() => {
    if (!access_token) return;

    if (userHasChosenYear) {
      setYearInitialized(true);
      return;
    }

    let canceled = false;
    (async () => {
      const lastYear = await fetchLastCompletedYear();
      if (!canceled) {
        if (lastYear && lastYear !== selectedYear) {
          setSelectedYear(lastYear);
        }
        setYearInitialized(true);
      }
    })();

    return () => {
      canceled = true;
    };
  }, [access_token, userHasChosenYear, selectedYear]);

  useEffect(() => {
    if (!access_token || !yearInitialized) return;
    fetchAllDataForYear(selectedYear);
  }, [access_token, selectedYear, yearInitialized]);

  if (!access_token) return null;
  if (isLoading) return <LoadingSpinner />;

  const CustomMenuItem = ({ completed, children, ...props }) => (
    <MenuItem {...props}>
      {completed ? (
        <ListItemText
          primary={children}
          primaryTypographyProps={{
            style: { textDecoration: 'line-through', color: 'green' },
          }}
        />
      ) : (
        <ListItemText primary={children} />
      )}
    </MenuItem>
  );

  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
        value={selectedYear}
        onChange={(e) => {
          setUserHasChosenYear(true);
          setSelectedYear(Number(e.target.value));
        }}
      >
        {[...Array(11).keys()].map((_, idx) => (
          <MenuItem key={idx + 1} value={idx + 1}>
            Year {idx + 1}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderSubtopicAccordions = () => {
    const relevantSubTopics = subTopics.filter((st) => st.year === Number(selectedYear));
  
    return (
      <Box>
        {relevantSubTopics.map((subTopic) => {
          const subLessons = lessonsBySubtopic[subTopic.id] || [];
          const noLessonsFound = subLessons.length === 0;
  
          return (
            <Accordion key={`${subTopic.id}-${subTopic.year}`} sx={{ mb: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center">
                  <CardMedia
                    component="img"
                    image={subTopic.image}
                    alt="Subtopic thumbnail"
                    sx={{ width: 60, height: 60, borderRadius: 1, mr: 2 }}
                  />
                  <Typography variant="subtitle1" fontWeight="bold">
                    {subTopic.title}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {subTopic.isStaticLink ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(subTopic.externalLink, '_blank')}
                  >
                    Go to resource
                  </Button>
                ) : (
                  <Box>
                    {noLessonsFound && (
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        Oops, no lessons found.{' '}
                        <Button
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            retryFetch();
                          }}
                        >
                          Retry?
                        </Button>
                      </Typography>
                    )}
  
                    {(() => {
                      const seriesMap = {};
                      const standalone = [];
  
                      for (let lesson of subLessons) {
                        if (lesson.series_id) {
                          if (!seriesMap[lesson.series_id]) {
                            seriesMap[lesson.series_id] = [];
                          }
                          seriesMap[lesson.series_id].push(lesson);
                        } else {
                          standalone.push(lesson);
                        }
                      }
  
                      const rendered = [];
  
                      Object.values(seriesMap).forEach((seriesLessons) => {
                        const sorted = [...seriesLessons].sort(
                          (a, b) => a.series_order - b.series_order
                        );
  
                        rendered.push(
                          <Box
                            key={`series-${sorted[0].id}`}
                            sx={{
                              border: '2px dashed #888',
                              borderRadius: 2,
                              p: 1,
                              mb: 1,
                              backgroundColor: '#f0f4ff',
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ mb: 1, color: '#333' }}
                            >
                              📚 Lesson Series: {sorted[0].title.split(' – ')[0]} Series
                            </Typography>
  
                            {sorted.map((lesson) => (
                              <Button
                                key={lesson.id}
                                fullWidth
                                variant="outlined"
                                sx={{
                                  justifyContent: 'flex-start',
                                  mb: 1,
                                  textDecoration: lesson.is_completed ? 'line-through' : 'none',
                                  color: lesson.is_completed ? 'green' : 'inherit',
                                  textTransform: 'none',
                                }}
                                onClick={() => navigate(`/Classroom/${lesson.id}`)}
                              >
                                {lesson.title} (Part {lesson.series_order})
                              </Button>
                            ))}
                          </Box>
                        );
                      });
  
                      standalone.forEach((lesson) => {
                        rendered.push(
                          <Button
                            key={lesson.id}
                            fullWidth
                            variant="outlined"
                            sx={{
                              justifyContent: 'flex-start',
                              mb: 1,
                              textDecoration: lesson.is_completed ? 'line-through' : 'none',
                              color: lesson.is_completed ? 'green' : 'inherit',
                              textTransform: 'none',
                            }}
                            onClick={() => navigate(`/Classroom/${lesson.id}`)}
                          >
                            {lesson.title}
                          </Button>
                        );
                      });
  
                      return rendered;
                    })()}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    );
  };
  

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate('/LessonSelect')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Science
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ bgcolor: '#f9fafb', pt: 8, pb: 6, minHeight: '100vh' }}>

        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a year first.
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Don’t be afraid to revise earlier years or take on something new!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <b>Year Selector</b>
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
          (This will default to the year of your last Science lesson.)
        </Typography>

        <Container maxWidth="lg">
  <Box sx={{ backgroundColor: 'white', p: 4, borderRadius: 3, boxShadow: 3 }}>
    {renderYearDropdown()}
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" align="center" color="text.secondary" paragraph>
        <b>Lesson Selector</b>
      </Typography>
      {renderSubtopicAccordions()}
    </Box>
  </Box>
</Container>

      </Box>
    </ThemeProvider>
  );
}

export default Science;
