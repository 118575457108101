import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Button,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi';
import LoadingSpinner from '../Utils/LoadingSpinner';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SUBJECT_ID = 1;
const defaultTheme = createTheme();

function Maths() {
  const navigate = useNavigate();
  const api = useApi();
  const coreApi = useCoreApi();

  // ----------------------------------------------------------------
  // 1) AUTH & INITIAL SETUP
  // ----------------------------------------------------------------
  const access_token = localStorage.getItem('accessToken');
  const student_id = localStorage.getItem('user_id');

  // Attempt to parse a year from localStorage
  const parsedYear = parseInt(localStorage.getItem('student_year'), 10);
  const initialYear = Number.isNaN(parsedYear) ? 1 : parsedYear;

  // ----------------------------------------------------------------
  // 2) STATE
  // ----------------------------------------------------------------
  const [selectedYear, setSelectedYear] = useState(initialYear);

  // This will be false until we’ve definitively resolved what year the student should be on
  const [yearInitialized, setYearInitialized] = useState(false);

  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  const [completedLessons, setCompletedLessons] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Tracks if the user has explicitly chosen a year in the dropdown
  const [userHasChosenYear, setUserHasChosenYear] = useState(false);

  // Hard-coded subTopics (memoized)
  const subTopics = useMemo(
    () => [
    // Your entire subtopic array for Maths
    { id: 1, title: "Number", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 4, title: "Geometry & Measures", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 1, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },
    
    { id: 1, title: "Number", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 4, title: "Geometry & Measures", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 6, title: "Statistics", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 2, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 4, title: "Geometry & Measures", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 6, title: "Statistics", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 3, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 4, title: "Geometry & Measures", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 6, title: "Statistics", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 4, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 4, title: "Geometry & Measures", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 6, title: "Statistics", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 5, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 2, title: "Algebra", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-algebra.avif" },
    { id: 3, title: "Ratio, proportion & rates of change", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-ratio.avif" },
    { id: 4, title: "Geometry & Measures", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 6, title: "Statistics", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 6, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 2, title: "Algebra", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-algebra.avif" },
    { id: 3, title: "Ratio, proportion & rates of change", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-ratio.avif" },
    { id: 4, title: "Geometry & Measures", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 5, title: "Probability", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-probability.avif" },
    { id: 6, title: "Statistics", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 7, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 2, title: "Algebra", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-algebra.avif" },
    { id: 3, title: "Ratio, proportion & rates of change", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-ratio.avif" },
    { id: 4, title: "Geometry & Measures", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 5, title: "Probability", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-probability.avif" },
    { id: 6, title: "Statistics", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 8, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 2, title: "Algebra", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-algebra.avif" },
    { id: 3, title: "Ratio, proportion & rates of change", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-ratio.avif" },
    { id: 4, title: "Geometry & Measures", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 5, title: "Probability", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-probability.avif" },
    { id: 6, title: "Statistics", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 9, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 2, title: "Algebra", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-algebra.avif" },
    { id: 3, title: "Ratio, proportion & rates of change", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-ratio.avif" },
    { id: 4, title: "Geometry & Measures", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 5, title: "Probability", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-probability.avif" },
    { id: 6, title: "Statistics", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 10, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 2, title: "Algebra", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-algebra.avif" },
    { id: 3, title: "Ratio, proportion & rates of change", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-ratio.avif" },
    { id: 4, title: "Geometry & Measures", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 5, title: "Probability", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-probability.avif" },
    { id: 6, title: "Statistics", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 11, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    ],
    []
  );

  // ----------------------------------------------------------------
  // 3) AXIOS INTERCEPTOR FOR 401
  // ----------------------------------------------------------------
  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response && err.response.status === 401) {
        navigate('/StudentLogin');
      }
      return Promise.reject(err);
    }
  );

  // ----------------------------------------------------------------
  // 4) REDIRECT IF NO TOKEN
  // ----------------------------------------------------------------
  useEffect(() => {
    if (!access_token) {
      console.warn('Missing access token. Redirecting to login.');
      navigate('/StudentLogin');
    }
  }, [access_token, navigate]);

  // ----------------------------------------------------------------
  // 5) DATA FETCHING (UTILITY FUNCTIONS)
  // ----------------------------------------------------------------

  // 5A) Fetch last completed year
  const fetchLastCompletedYear = async () => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/last_completed_year_for_subject/${SUBJECT_ID}`,
        { headers: { Authorization: `Bearer ${access_token}` }, timeout: 5000 }
      );

      if (response.status >= 200 && response.status < 300 && response.data?.year_id) {
        return response.data.year_id;
      }
    } catch (err) {
      console.error('Error fetching last completed year:', err);
    }
    return null;
  };

  // 5B) Fetch completed lessons for one subtopic
  const fetchCompletedLessons = async (subTopicId, year) => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/completed_lessons/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data.map((l) => l.lesson_id);
      }
    } catch (err) {
      console.error(`Error fetching completed lessons for ${subTopicId}:`, err);
    }
    return [];
  };

  // 5C) Fetch lessons for one subtopic
  const fetchLessonsBySubtopic = async (subTopicId, year) => {
    try {
      const response = await coreApi.get(
        `/lessons/lessons_by_subject/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      console.error(`Error fetching lessons for subtopic ${subTopicId}:`, err);
    }
    return [];
  };

  // ----------------------------------------------------------------
  // 6) MASTER FETCH (LESSONS & COMPLETED)
  // ----------------------------------------------------------------
  const fetchAllDataForYear = async (year) => {
    setIsLoading(true);
    setError(null);

    try {
      // Get relevant subtopics
      const relevantSubTopics = subTopics.filter((st) => st.year === year);

      // 1) Fetch all completed lesson IDs in parallel
      const completedPromises = relevantSubTopics.map((st) =>
        fetchCompletedLessons(st.id, year)
      );
      const completedArrays = await Promise.all(completedPromises);
      const allCompletedIds = completedArrays.flat();
      setCompletedLessons(allCompletedIds);

      // 2) Fetch all lesson data in parallel
      const lessonPromises = relevantSubTopics.map(async (st) => {
        const lessons = await fetchLessonsBySubtopic(st.id, year);
        return { subTopicId: st.id, lessons };
      });
      const lessonsArray = await Promise.all(lessonPromises);

      // 3) Combine into a map
      const newLessonsBySubtopic = {};
      for (let item of lessonsArray) {
        // Mark as completed if in the completed list
        const updated = item.lessons.map((lesson) => ({
          ...lesson,
          is_completed: allCompletedIds.includes(lesson.id),
        }));
        newLessonsBySubtopic[item.subTopicId] = updated;
      }

      setLessonsBySubtopic(newLessonsBySubtopic);
    } catch (err) {
      console.error('fetchAllDataForYear error:', err);
      setError('Error fetching lessons. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Simple function to retry fetch
  const retryFetch = () => {
    fetchAllDataForYear(selectedYear);
  };

  // ----------------------------------------------------------------
  // 7) EFFECTS
  // ----------------------------------------------------------------

  /**
   * Effect A: On initial mount, attempt to fetch last completed year (unless the user
   * has already chosen a year). Once done, we set yearInitialized = true.
   */
  useEffect(() => {
    if (!access_token) return;

    if (userHasChosenYear) {
      // The user has explicitly chosen a year; skip server check
      setYearInitialized(true);
      return;
    }

    let canceled = false;
    (async () => {
      const lastYear = await fetchLastCompletedYear();
      if (!canceled) {
        if (lastYear && lastYear !== selectedYear) {
          setSelectedYear(lastYear);
        }
        setYearInitialized(true);
      }
    })();

    return () => {
      canceled = true;
    };
  }, [access_token, userHasChosenYear, selectedYear]);

  /**
   * Effect B: Once the year is initialized and we have a final selectedYear,
   * fetch the data for that year.
   */
  useEffect(() => {
    if (!access_token || !yearInitialized) return;
    fetchAllDataForYear(selectedYear);
  }, [access_token, selectedYear, yearInitialized]);

  // ----------------------------------------------------------------
  // 8) RENDERING
  // ----------------------------------------------------------------

  // If no token, short-circuit
  if (!access_token) {
    return null;
  }

  // If we're still fetching, show spinner
  if (isLoading) {
    return <LoadingSpinner />;
  }

  // A custom menu item to show completed lessons (strikethrough)
  const CustomMenuItem = ({ completed, children, ...props }) => (
    <MenuItem {...props}>
      {completed ? (
        <ListItemText
          primary={children}
          primaryTypographyProps={{
            style: { textDecoration: 'line-through', color: 'green' },
          }}
        />
      ) : (
        <ListItemText primary={children} />
      )}
    </MenuItem>
  );

  // The dropdown to choose a year
  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
        value={selectedYear}
        onChange={(e) => {
          setUserHasChosenYear(true);
          setSelectedYear(Number(e.target.value));
        }}
      >
        {[...Array(11).keys()].map((_, idx) => {
          const yr = idx + 1;
          return (
            <MenuItem key={yr} value={yr}>
              Year {yr}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

// The main grid of subtopics
const renderSubtopicAccordions = () => {
  const relevantSubTopics = subTopics.filter((st) => st.year === Number(selectedYear));

  return (
    <Box>
      {relevantSubTopics.map((subTopic) => {
        const subLessons = lessonsBySubtopic[subTopic.id] || [];
        const noLessonsFound = subLessons.length === 0;

        return (
          <Accordion key={`${subTopic.id}-${subTopic.year}`} sx={{ mb: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <CardMedia
                  component="img"
                  image={subTopic.image}
                  alt="Subtopic thumbnail"
                  sx={{ width: 60, height: 60, borderRadius: 1, mr: 2 }}
                />
                <Typography variant="subtitle1" fontWeight="bold">
                  {subTopic.title}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {subTopic.isStaticLink ? (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => window.open(subTopic.externalLink, '_blank')}
                >
                  Go to resource
                </Button>
              ) : (
                <Box>
                  {noLessonsFound && (
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                      Oops, no lessons found.{' '}
                      <Button
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          retryFetch();
                        }}
                      >
                        Retry?
                      </Button>
                    </Typography>
                  )}

                  {(() => {
                    const seriesMap = {};
                    const standalone = [];

                    for (let lesson of subLessons) {
                      if (lesson.series_id) {
                        if (!seriesMap[lesson.series_id]) {
                          seriesMap[lesson.series_id] = [];
                        }
                        seriesMap[lesson.series_id].push(lesson);
                      } else {
                        standalone.push(lesson);
                      }
                    }

                    const rendered = [];

                    Object.values(seriesMap).forEach((seriesLessons) => {
                      const sorted = [...seriesLessons].sort(
                        (a, b) => a.series_order - b.series_order
                      );

                      rendered.push(
                        <Box
                          key={`series-${sorted[0].id}`}
                          sx={{
                            border: '2px dashed #888',
                            borderRadius: 2,
                            p: 1,
                            mb: 1,
                            backgroundColor: '#f0f4ff',
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{ mb: 1, color: '#333' }}
                          >
                            📚 Lesson Series: {sorted[0].title.split(' – ')[0]} Series
                          </Typography>

                          {sorted.map((lesson) => (
                            <Button
                              key={lesson.id}
                              fullWidth
                              variant="outlined"
                              sx={{
                                justifyContent: 'flex-start',
                                mb: 1,
                                textDecoration: lesson.is_completed ? 'line-through' : 'none',
                                color: lesson.is_completed ? 'green' : 'inherit',
                                textTransform: 'none',
                              }}
                              onClick={() => navigate(`/Classroom/${lesson.id}`)}
                            >
                              {lesson.title} (Part {lesson.series_order})
                            </Button>
                          ))}
                        </Box>
                      );
                    });

                    standalone.forEach((lesson) => {
                      rendered.push(
                        <Button
                          key={lesson.id}
                          fullWidth
                          variant="outlined"
                          sx={{
                            justifyContent: 'flex-start',
                            mb: 1,
                            textDecoration: lesson.is_completed ? 'line-through' : 'none',
                            color: lesson.is_completed ? 'green' : 'inherit',
                            textTransform: 'none',
                          }}
                          onClick={() => navigate(`/Classroom/${lesson.id}`)}
                        >
                          {lesson.title}
                        </Button>
                      );
                    });

                    return rendered;
                  })()}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};


  
  // Final return section
  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate('/LessonSelect')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Maths
          </Typography>
        </Toolbar>
      </AppBar>
  
      <Box sx={{ bgcolor: '#f9fafb', pt: 8, pb: 6, minHeight: '100vh' }}>

        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a year first.
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Don't be scared to drop down or move up years, there's value in
          practicing the basics, or going for a challenge!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <b>Year Selector</b>
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
          (Note: This will default to the year of the last Maths lesson you completed,
          but feel free to change it whenever!)
        </Typography>
  
        <Container maxWidth="lg">
  <Box sx={{ backgroundColor: 'white', p: 4, borderRadius: 3, boxShadow: 3 }}>
    {renderYearDropdown()}
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" align="center" color="text.secondary" paragraph>
        <b>Lesson Selector</b>
      </Typography>
      {renderSubtopicAccordions()}
    </Box>
  </Box>
</Container>
      </Box>
    </ThemeProvider>
  );
}

export default Maths;
