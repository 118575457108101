import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi';
import { useWindowSize } from 'react-use';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});




const SUBJECT_NAME_TO_ID = {
  "Maths": 1,
  "Science": 2,
  "Geography": 3,
  "History": 4,
  "English": 5,
  "ICT": 6,
  "Business Studies": 7,
};

// Motivational messages for different score ranges

const motivationalMessagesLow = [
"It’s all about the grind! You’re just getting started—next time, you’ll smash it. 💪",
"Every expert was once a beginner. Keep going, legend! 🚀",
"Tough lesson? No worries! Even rockets need a few test flights before they launch. 🔥",
"Learning isn’t about getting it right the first time. Keep at it and you’ll be unstoppable! 💡",
"You’re in the warm-up phase. Next round, you’ll be on fire! 🔥🔥",
"Even the best astronauts had a few tough landings before they reached the stars. You’ve got this! 🚀",
"Rome wasn’t built in a day… and neither are champions. One step closer to greatness! 🏆",
"Not gonna lie, that was a bit of a spicy round. But hey, every failure is just fuel for your comeback. 🔥",
"This wasn’t your best run, but hey, even superheroes need training montages. Suit up for the next one! 🦸‍♂️",
"Nobody aces it on the first try. You’re learning. Keep going! 🤖",
"Plot twist: You just unlocked ‘Room for Improvement’ mode. Time to level up! 🎮",
"Tough round? Good! That means you're pushing yourself. Next time, you'll fly through this. ✈️",
"Your brain just got a workout. A few more reps, and you'll be unstoppable! 💪",
"If you never fall, you never learn to get back up. Now get up and let’s go again! 🔄",
"You just completed the ‘learning’ part of the process. Next time: the ‘winning’ part. 💡",
"Failure? Pffft. More like a ‘Pre-Success Event.’ Ready for the real thing? 🚀",
"Not your best? That’s fine—because your best is still on its way! 💥",
"One rough lesson doesn’t define you. Champions bounce back. Are you a champion? Yes. Keep going! 🏆",
"Don’t stress! You just got a preview of what’s waiting for you in the ‘Aced It’ section. 😉",
"Think of this as XP points—you're levelling up, even if it didn’t feel like it! 🎮",
"First attempt? Learning. Second attempt? Progress. Third attempt? Watch out world, here you come! 🌍",
"Every rocket starts on the ground. The next launch will be smoother. 🚀",
"You just took one step towards being better than you were yesterday. That’s a win in my book! 📖",
"Oof. That one stung a bit, huh? Shake it off, champ—your next big win is coming. 💪",
"Remember: The best stories start with struggle. This is just Chapter One of your success story. 📖"
];

const motivationalMessagesMedium = [
"Solid effort! You’re getting there—just a bit more and you’ll be flying! 🚀",
"Nice one! Keep the momentum going, and soon you'll be at the top. 🔝",
"Not bad at all! A little more practice and you'll be a pro. 🎯",
"You're on the right track! Keep learning, keep growing. 🌱",
"You’re building the foundation for greatness. Keep stacking those wins! 🏗️",
"Nice! You’re climbing the leaderboard. Just a little more and you’ll be unstoppable! 🚀",
"You’re in the ‘Almost Aced It’ zone. One more push and you’re legendary! 💪",
"Solid work! If this were a video game, you’d be one power-up away from boss mode. 🎮🔥",
"You’re not just learning—you’re evolving. Next stop: Genius Town. 🧠💡",
"That was great—but I know you’ve got ‘amazing’ in you. Next time, let’s go for gold! 🏅",
"You’re officially in the danger zone—dangerous to anyone trying to beat your score! 😏",
"Look at you, smashing through challenges like a legend! Next round? Even better. 💥",
"You’re like a rocket mid-launch—just a bit more fuel and you’re hitting orbit! 🚀",
"This was a solid win, but I see even bigger wins in your future. Keep at it! 🔥",
"You're not just learning—you’re levelling up! Next round: Boss Level unlocked. 🎮",
"Hey, that was GOOD. But you? You’re capable of GREAT. Let’s go! 💪",
"You're in the ‘So Close’ zone. Just a little push and you’re at the top! 🚀",
"You handled that like a pro! Next step: doing it with style. 😎",
"If this were a race, you’d be in podium position. Time to go for gold next time! 🏆",
"This is the kind of score champions get on their way to victory. You’re on track! 🏅",
"You’re on fire! 🔥 Not full-blazing yet, but definitely heating up! Keep going!",
"Nice! That was a solid round. Now, what happens when you give it 10% more? Let’s find out! 🚀",
"Some people stop here. But not you. You’re aiming higher, aren’t you? I see that fire. 🔥",
"Progress, progress, progress! This was a win, but let’s make the next one a massive win! 🏆",
"You’re officially in ‘Impressively Dangerous’ territory. Keep pushing and you’ll be elite. 💥"
];

const motivationalMessagesHigh = [
 "Absolute beast mode! You smashed that lesson! 🔥🔥🔥",
"That’s how it’s done! You’re making this look easy. 💪",
"Rocket 🚀 fuelled and ready for takeoff! Keep up the stellar work!",
"Legend status unlocked. Now let’s see if you can top this next time! 🏆",
"You’re smashing it! One step closer to world domination (or at least acing this subject). 😏",
"BOOM! That was pure excellence. You didn’t just pass—you DOMINATED. 💥🔥",
"This is ‘Effortless Genius’ territory. How does it feel being this good? 😎",
"Someone call NASA, because you’re officially in orbit with that score! 🚀🌍",
"That’s what elite looks like. You’re making this look too easy! 🏆🔥",
"Whatever you’re doing, keep doing it—because that was a masterclass! 🎓",
"You didn’t just complete that lesson—you owned it. That’s how it’s done. 💪",
"This is ‘Frame It On The Wall’ level work. That’s how good you are. 🎖️",
"Legends get scores like this. And guess what? You’re one of them. ⚡",
"That was lightning-fast and laser-accurate. Unstoppable! ⚡🎯",
"Forget ‘top marks’—this was record-breaking. You’re setting the standard now! 🏅",
"Your brain is working on God Mode. You didn’t just learn—you leveled up. 🧠💥",
"Scores like this don’t happen by accident. That’s pure skill. Keep going! 🚀",
"If this were a video game, you’d have just unlocked Ultra Mega Pro Mode. 🎮🔥",
"Look at you—just casually destroying this challenge like a total pro. 💪",
"At this point, you might as well start teaching the class. That was a masterwork. 🎓",
"This isn’t just progress—it’s domination. You’re rewriting the rulebook! 🚀",
"You’re in the Top 1% now. Champions train, and you’ve trained well! 🏆🔥",
"When you go all in, this is what happens. Absolute greatness. 💯",
"No hesitation. No doubts. Pure excellence. That’s how it’s done! 👏",
"RocketFuel? You don’t need it. You ARE the rocket. Straight to the top! 🚀🔥"

];

export default function CompletedDialog({ 
  lessonName, 
  score, 
  open, 
  onClose, 
  onNextLesson, 
  onBackToLessons,
  isLessonAlreadyCompleted, 
  rocketFuel,
  studentid,
  subjectName,
}) {
  const subjectId = SUBJECT_NAME_TO_ID[subjectName];
  const [nextLesson, setNextLesson] = useState(null);
  const [randomMessage, setRandomMessage] = useState("");
  const access_token = localStorage.getItem('accessToken');
  const api = useApi();
  const coreApi = useCoreApi();
  const navigate = useNavigate();
  const { width, height } = useWindowSize(); // For confetti dimensions
  const [topicCompleted, setTopicCompleted] = useState(false);
  const [topicRewardGiven, setTopicRewardGiven] = useState(false); // Avoid double-awarding

  const handleNextLessonClick = async () => {
    if (nextLesson?.lesson_id) {
      const pathToNavigate = `/Classroom/${nextLesson.lesson_id}`;
      window.location.href = pathToNavigate;
      onClose();
    } else {
      // If topic already completed, we don't alert – we show special dialog UI instead
      setTopicCompleted(true);
    }
  };
  
  
  useEffect(() => {
    if (open) {
      // 🎯 Motivational message
      let messageArray;
      if (score < 35) messageArray = motivationalMessagesLow;
      else if (score < 70) messageArray = motivationalMessagesMedium;
      else messageArray = motivationalMessagesHigh;
      setRandomMessage(messageArray[Math.floor(Math.random() * messageArray.length)]);
  
      // 🔍 Get next lesson
      coreApi.get(`/progress/student/${studentid}/last_completed_lessons`, {
        headers: { 'Authorization': `Bearer ${access_token}` }
      })
      .then(response => {
        const lessonData = response.data.find(lesson => lesson.subject_id === subjectId);
        if (lessonData?.next_lesson_id) {
          setNextLesson({
            lesson_id: lessonData.next_lesson_id,
            title: lessonData.next_lesson_title
          });
          setTopicCompleted(false); // Reset
        } else {
          setNextLesson(null);
          setTopicCompleted(true);
        }
      })
      .catch(err => {
        console.error('Error fetching next lesson:', err);
        setNextLesson(null);
      });
    }
  }, [open, studentid, subjectId, score]);
  

  // 🎉 Animation for the RocketFuel bar
  const barVariants = {
    hidden: { width: "0%" },
    visible: { width: `${rocketFuel}%` }
  };

  // Container animation variants for overall content entrance
  const containerVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.6, ease: "easeOut" } },
  };

  // Gradient background animation style for a festive feel
  const gradientAnimation = {
    background: "linear-gradient(45deg, #4a90e2, #7ab7f0, #4a90e2)",
    backgroundSize: "400% 400%",
    animation: "gradientBG 20s ease infinite", // pulsing speed
    borderRadius: "8px",
    padding: "20px"
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          overflow: "hidden",
          boxShadow: "0px 8px 16px rgba(0,0,0,0.3)",
          borderRadius: "15px"
        }
      }}
    >
      {/* 🎉 Enhanced Confetti Effect */}
      {open && score >= 70 && (
  <Confetti 
    width={width} 
    height={height} 
    numberOfPieces={200} 
    gravity={0.3} 
  />
)}

      <DialogTitle>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {"🎉 Lesson Completed!"}
        </motion.div>
      </DialogTitle>

      <DialogContent>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          style={gradientAnimation}
        >
          <DialogContentText id="alert-dialog-slide-description">
            {isLessonAlreadyCompleted ? (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                You've already completed this lesson. Let's try another!
              </motion.p>
            ) : (
              <>
                <motion.div 
                  initial={{ opacity: 0, y: -10 }} 
                  animate={{ opacity: 1, y: 0 }} 
                  transition={{ duration: 1.5 }}
                >
                  <p>🎓 <strong>Congratulations!</strong> You completed another lesson: <em>{lessonName}</em></p>
                  <p>📊 You scored <strong>{Math.ceil(score)}%</strong>.</p>
                </motion.div>

                {/* Display a random motivational message based on the score */}
                <motion.p
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1.2, delay: 0.5 }}
                  style={{ fontStyle: "italic", marginTop: "10px" }}
                >
                  {randomMessage}
                </motion.p>

                <p style={{ marginTop: "10px" }}>
                  🚀 You earned <strong>{rocketFuel} RocketFuel</strong>!
                </p>

                {/* 🔥 Animated RocketFuel Bar */}
                <div style={{ background: "#e0e0e0", borderRadius: "5px", overflow: "hidden", height: "24px", marginTop: "10px" }}>
                  <AnimatePresence>
                    <motion.div 
                      variants={barVariants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      transition={{ duration: 1.5, ease: "easeOut" }}
                      style={{ background: "#4CAF50", height: "100%" }}
                    />
                  </AnimatePresence>
                </div>
                {topicCompleted && (
  <motion.div
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 1 }}
    style={{
      marginTop: "20px",
      padding: "15px",
      background: "#fff5d1",
      borderRadius: "10px",
      border: "2px dashed #ffc107",
      textAlign: "center"
    }}
  >
    <p style={{ fontSize: "18px", fontWeight: "bold" }}>
      🎉 You’ve completed this topic!
    </p>
    <p>You earned <strong>+100 RocketFuel</strong> as a reward.</p>
    <p style={{ fontStyle: "italic", marginTop: "5px" }}>
      Time to choose a new topic and keep your streak going! 🚀
    </p>
  </motion.div>
)}

              </>
            )}
          </DialogContentText>
        </motion.div>
      </DialogContent>

      <DialogActions>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.5 }}
          style={{ width: "100%", textAlign: "center" }}
        >
          <Button onClick={onBackToLessons}>Back to the Lesson Menu</Button>
          <Button onClick={handleNextLessonClick} disabled={!nextLesson}>
            Next Lesson
          </Button>
          {!nextLesson && (
            <p style={{ color: "red", textAlign: "center" }}>
              No next lesson. Go back to the menu.
            </p>
          )}
        </motion.div>
      </DialogActions>

      {/* CSS Keyframes for Gradient Animation */}
      <style jsx="true">{`
  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
      filter: brightness(0.95);
    }
    50% {
      background-position: 100% 50%;
      filter: brightness(1.05);
    }
    100% {
      background-position: 0% 50%;
      filter: brightness(0.95);
    }
  }
`}</style>




    </Dialog>
  );
}
