import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
  Button,
  Card,
  CardContent,
  Grid,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi';
import LoadingSpinner from '../Utils/LoadingSpinner';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const SUBJECT_ID = 3;
const defaultTheme = createTheme();

function Geography() {
  const navigate = useNavigate();
  const api = useApi();
  const coreApi = useCoreApi();

  // ---------------------------------------------------------------
  // 1) AUTH & INITIAL SETUP
  // ---------------------------------------------------------------
  const access_token = localStorage.getItem('accessToken');
  const student_id = localStorage.getItem('user_id');

  // Parse year from localStorage or default to 1
  const parsedYear = parseInt(localStorage.getItem('student_year'), 10);
  const initialYear = Number.isNaN(parsedYear) ? 1 : parsedYear;

  // ---------------------------------------------------------------
  // 2) STATE
  // ---------------------------------------------------------------
  const [selectedYear, setSelectedYear] = useState(initialYear);
  const [yearInitialized, setYearInitialized] = useState(false);
  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  const [completedLessons, setCompletedLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userHasChosenYear, setUserHasChosenYear] = useState(false);

  // Subtopics array for Geography – update as needed.
  const subTopics = useMemo(() => ([
    { id: 59, title: "Locational knowledge", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 63, title: "Locational knowledge", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-knowledge.avif" },
    { id: 64, title: "Maps, fieldwork and geographical skills", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-fieldwork.avif" },
    { id: 65, title: "Place: processes and relationships", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-places.avif" },
    { id: 66, title: "Physical geography: processes and change", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-physical.avif" },
    { id: 67, title: "People and environment: processes and interactions ", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-environment.avif" },
    { id: 68, title: "Human geography: processes and change", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 63, title: "Locational knowledge", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-knowledge.avif" },
    { id: 64, title: "Maps, fieldwork and geographical skills", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-fieldwork.avif" },
    { id: 65, title: "Place: processes and relationships", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-places.avif" },
    { id: 66, title: "Physical geography: processes and change", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-physical.avif" },
    { id: 67, title: "People and environment: processes and interactions ", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-environment.avif" },
    { id: 68, title: "Human geography: processes and change", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    // ... add additional subTopics for years 3-11 as needed
  ]), []);

  // ---------------------------------------------------------------
  // 3) AXIOS INTERCEPTOR FOR 401
  // ---------------------------------------------------------------
  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response && err.response.status === 401) {
        navigate('/StudentLogin');
      }
      return Promise.reject(err);
    }
  );

  // ---------------------------------------------------------------
  // 4) REDIRECT IF NO TOKEN
  // ---------------------------------------------------------------
  useEffect(() => {
    if (!access_token) {
      console.warn('Missing access token. Redirecting to login.');
      navigate('/StudentLogin');
    }
  }, [access_token, navigate]);

  // ---------------------------------------------------------------
  // 5) DATA FETCHING FUNCTIONS
  // ---------------------------------------------------------------

  // 5A) Fetch last completed year for Geography
  const fetchLastCompletedYear = async () => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/last_completed_year_for_subject/${SUBJECT_ID}`,
        { headers: { Authorization: `Bearer ${access_token}` }, timeout: 5000 }
      );
      if (response.status >= 200 && response.status < 300 && response.data?.year_id) {
        return response.data.year_id;
      }
    } catch (err) {
      console.error('Error fetching last completed year:', err);
    }
    return null;
  };

  // 5B) Fetch completed lessons for one subtopic
  const fetchCompletedLessons = async (subTopicId, year) => {
    if (typeof subTopicId !== 'number') return [];
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/completed_lessons/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data.map((lesson) => lesson.lesson_id);
      }
    } catch (err) {
      console.error(`Error fetching completed lessons for subtopic ${subTopicId}:`, err);
    }
    return [];
  };

  // 5C) Fetch lessons for one subtopic
  const fetchLessonsBySubtopic = async (subTopicId, year) => {
    if (typeof subTopicId !== 'number') return [];
    try {
      const response = await coreApi.get(
        `/lessons/lessons_by_subject/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      console.error(`Error fetching lessons for subtopic ${subTopicId}:`, err);
    }
    return [];
  };

  // 5D) Master fetch function: fetch all data concurrently
  const fetchAllDataForYear = async (year) => {
    setIsLoading(true);
    setError(null);
    try {
      // Filter subtopics for the selected year
      const relevantSubTopics = subTopics.filter((st) => st.year === Number(year));

      // 1) Fetch all completed lessons concurrently
      const completedPromises = relevantSubTopics.map((st) =>
        fetchCompletedLessons(st.id, year)
      );
      const completedArrays = await Promise.all(completedPromises);
      const allCompletedIds = completedArrays.flat();
      setCompletedLessons(allCompletedIds);

      // 2) Fetch all lessons concurrently
      const lessonPromises = relevantSubTopics.map(async (st) => {
        const lessons = await fetchLessonsBySubtopic(st.id, year);
        return { subTopicId: st.id, lessons };
      });
      const lessonsArray = await Promise.all(lessonPromises);

      // 3) Combine lesson data with completed status
      const newLessonsBySubtopic = {};
      lessonsArray.forEach((item) => {
        newLessonsBySubtopic[item.subTopicId] = item.lessons.map((lesson) => ({
          ...lesson,
          is_completed: allCompletedIds.includes(lesson.id),
        }));
      });
      setLessonsBySubtopic(newLessonsBySubtopic);
    } catch (err) {
      console.error("Error fetching all data for year:", err);
      setError("Error fetching lessons. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Retry function to allow users to re-fetch data if needed
  const retryFetch = () => {
    fetchAllDataForYear(selectedYear);
  };

  // ---------------------------------------------------------------
  // 6) EFFECTS
  // ---------------------------------------------------------------

  // Effect A: On mount, if user hasn't manually selected a year, fetch last completed year then mark yearInitialized
  useEffect(() => {
    if (!access_token) return;
    if (userHasChosenYear) {
      setYearInitialized(true);
      return;
    }
    let canceled = false;
    (async () => {
      const lastYear = await fetchLastCompletedYear();
      if (!canceled) {
        if (lastYear && lastYear !== selectedYear) {
          setSelectedYear(lastYear);
        }
        setYearInitialized(true);
      }
    })();
    return () => {
      canceled = true;
    };
  }, [access_token, userHasChosenYear, selectedYear]);

  // Effect B: Once the year is initialized, fetch all data for that year.
  useEffect(() => {
    if (!access_token || !yearInitialized) return;
    fetchAllDataForYear(selectedYear);
  }, [access_token, selectedYear, yearInitialized]);

  // ---------------------------------------------------------------
  // 7) RENDER HELPERS
  // ---------------------------------------------------------------
  const CustomMenuItem = ({ completed, children, ...props }) => (
    <MenuItem {...props}>
      {completed ? (
        <ListItemText
          primary={children}
          primaryTypographyProps={{ style: { textDecoration: 'line-through', color: 'green' } }}
        />
      ) : (
        <ListItemText primary={children} />
      )}
    </MenuItem>
  );

  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
        value={selectedYear}
        onChange={(e) => {
          setUserHasChosenYear(true);
          setSelectedYear(Number(e.target.value));
        }}
      >
        {[...Array(11).keys()].map((_, idx) => {
          const yearVal = idx + 1;
          return (
            <MenuItem key={yearVal} value={yearVal}>
              Year {yearVal}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const renderSubtopicAccordions = () => {
    const relevantSubTopics = subTopics.filter((st) => st.year === Number(selectedYear));
  
    return (
      <Box>
        {relevantSubTopics.map((subTopic) => {
          const subLessons = lessonsBySubtopic[subTopic.id] || [];
          const noLessonsFound = subLessons.length === 0;
  
          return (
            <Accordion key={`${subTopic.id}-${subTopic.year}`} sx={{ mb: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center">
                  <CardMedia
                    component="img"
                    image={subTopic.image}
                    alt="Subtopic thumbnail"
                    sx={{ width: 60, height: 60, borderRadius: 1, mr: 2 }}
                  />
                  <Typography variant="subtitle1" fontWeight="bold">
                    {subTopic.title}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {subTopic.isStaticLink ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(subTopic.externalLink, '_blank')}
                  >
                    Go to resource
                  </Button>
                ) : (
                  <Box>
                    {noLessonsFound && (
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        Oops, no lessons found.{' '}
                        <Button
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            retryFetch();
                          }}
                        >
                          Retry?
                        </Button>
                      </Typography>
                    )}
  
                    {(() => {
                      const seriesMap = {};
                      const standalone = [];
  
                      for (let lesson of subLessons) {
                        if (lesson.series_id) {
                          if (!seriesMap[lesson.series_id]) {
                            seriesMap[lesson.series_id] = [];
                          }
                          seriesMap[lesson.series_id].push(lesson);
                        } else {
                          standalone.push(lesson);
                        }
                      }
  
                      const rendered = [];
  
                      Object.values(seriesMap).forEach((seriesLessons) => {
                        const sorted = [...seriesLessons].sort(
                          (a, b) => a.series_order - b.series_order
                        );
  
                        rendered.push(
                          <Box
                            key={`series-${sorted[0].id}`}
                            sx={{
                              border: '2px dashed #888',
                              borderRadius: 2,
                              p: 1,
                              mb: 1,
                              backgroundColor: '#f0f4ff',
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ mb: 1, color: '#333' }}
                            >
                              📚 Lesson Series: {sorted[0].title.split(' – ')[0]} Series
                            </Typography>
  
                            {sorted.map((lesson) => (
                              <Button
                                key={lesson.id}
                                fullWidth
                                variant="outlined"
                                sx={{
                                  justifyContent: 'flex-start',
                                  mb: 1,
                                  textDecoration: lesson.is_completed ? 'line-through' : 'none',
                                  color: lesson.is_completed ? 'green' : 'inherit',
                                  textTransform: 'none',
                                }}
                                onClick={() => navigate(`/Classroom/${lesson.id}`)}
                              >
                                {lesson.title} (Part {lesson.series_order})
                              </Button>
                            ))}
                          </Box>
                        );
                      });
  
                      standalone.forEach((lesson) => {
                        rendered.push(
                          <Button
                            key={lesson.id}
                            fullWidth
                            variant="outlined"
                            sx={{
                              justifyContent: 'flex-start',
                              mb: 1,
                              textDecoration: lesson.is_completed ? 'line-through' : 'none',
                              color: lesson.is_completed ? 'green' : 'inherit',
                              textTransform: 'none',
                            }}
                            onClick={() => navigate(`/Classroom/${lesson.id}`)}
                          >
                            {lesson.title}
                          </Button>
                        );
                      });
  
                      return rendered;
                    })()}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    );
  };
  
  // ---------------------------------------------------------------
  // 8) FINAL RENDER
  // ---------------------------------------------------------------
  if (!access_token) return null;
  if (isLoading) return <LoadingSpinner />;

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate('/LessonSelect')}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Geography
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ bgcolor: '#f9fafb', pt: 8, pb: 6, minHeight: '100vh' }}>

        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a year first.
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Don't be scared to drop down or move up years, there's value in practicing the basics, or going for a challenge!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <b>Year Selector</b>
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
          (Note: This will default to the year of the last Geography lesson you completed, but feel free to change it whenever!)
        </Typography>
        <Container maxWidth="lg">
  <Box sx={{ backgroundColor: 'white', p: 4, borderRadius: 3, boxShadow: 3 }}>
    {renderYearDropdown()}
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" align="center" color="text.secondary" paragraph>
        <b>Lesson Selector</b>
      </Typography>
      {renderSubtopicAccordions()}
    </Box>
  </Box>
</Container>
      </Box>
    </ThemeProvider>
  );
}

export default Geography;
