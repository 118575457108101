// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatButton_chatButtonContainer__lbr-o {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.ChatButton_chatButton__YmyQK {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.ChatButton_chatIcon__d-a61 {
  width: 90px;
  height: 80px;
}

.ChatButton_minimizeButton__lIfLx {
  background: none;
  border: none;
  font-size: 20px;
  color: white;
  cursor: pointer;
  padding: 5px;
  margin-left: 5px;
}

.ChatButton_miniChatButton__Zd2CE {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4a90e2;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ChatButton_miniChatButton__Zd2CE:hover {
  background-color: #357ABD;
}
`, "",{"version":3,"sources":["webpack://./src/Chat/ChatButton.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".chatButtonContainer {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  display: flex;\n  align-items: center;\n  gap: 5px;\n}\n\n.chatButton {\n  background: none;\n  border: none;\n  cursor: pointer;\n  padding: 0;\n}\n\n.chatIcon {\n  width: 90px;\n  height: 80px;\n}\n\n.minimizeButton {\n  background: none;\n  border: none;\n  font-size: 20px;\n  color: white;\n  cursor: pointer;\n  padding: 5px;\n  margin-left: 5px;\n}\n\n.miniChatButton {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  background-color: #4a90e2;\n  color: white;\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 18px;\n  cursor: pointer;\n  transition: all 0.3s ease-in-out;\n}\n\n.miniChatButton:hover {\n  background-color: #357ABD;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatButtonContainer": `ChatButton_chatButtonContainer__lbr-o`,
	"chatButton": `ChatButton_chatButton__YmyQK`,
	"chatIcon": `ChatButton_chatIcon__d-a61`,
	"minimizeButton": `ChatButton_minimizeButton__lIfLx`,
	"miniChatButton": `ChatButton_miniChatButton__Zd2CE`
};
export default ___CSS_LOADER_EXPORT___;
