import React, { useState, useEffect } from 'react'; // Import useState and useEffect
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import FacebookIcon from '@mui/icons-material/Facebook';
import useApi from '../Utils/useApi';
import { useNavigate } from 'react-router-dom';
import UserGreeting from '../components/UserGreeting';
import { AppBar, Toolbar, Button, Box } from '@mui/material';


const mainFeaturedPost = {
  title: 'ParentZone: Empowering Home Education',
  description:
    "Welcome to ParentZone, your comprehensive resource for home education. Our platform is dedicated to providing parents with expert guidance and practical advice.\n\nEnhance your children's learning journey at home with our resources. Whether you're exploring homeschooling options or seeking additional educational support, ParentZone is equipped with the tools and insights necessary for effective and engaging home learning experiences.\n\nJoin us in nurturing your child's potential to the fullest.",
  image: 'https://rl-blog.ams3.cdn.digitaloceanspaces.com/PzoneBannerShip.png',
};


export default function Blog() {
  const [featuredPosts, setFeaturedPosts] = useState([]); // State for featured posts
  const [posts, setPosts] = useState([]); // State for normal posts
  const api = useApi();
  const navigate = useNavigate();
  const handleContactClick = () => {
    navigate('/contactus');  // Assuming the route for Contact Us page is '/contact'
  };

  useEffect(() => {
    // Fetch featured posts
    const fetchFeaturedPosts = async () => {
      try {
        const response = await api.get('/blog/blog/posts/featured'); // Adjust this endpoint as needed
        const data = response.data;
        setFeaturedPosts(data);
      } catch (error) {
        console.log('Error fetching featured posts:', error);
        // Handle errors as appropriate
      }
    };

    // Fetch normal posts
    const fetchNormalPosts = async () => {
      try {
        const response = await api.get('/blog/blog/posts/normal'); // Adjust this endpoint as needed
        const data = response.data;
        setPosts(data);
      } catch (error) {
        console.log('Error fetching normal posts:', error);
        // Handle errors as appropriate
      }
    };

    fetchFeaturedPosts();
    fetchNormalPosts();
  }, []);

  const sidebar = {
    title: 'More About Rocket Learning', // Updated title
    description:
      'Rocket Learning is a dynamic educational platform designed for students engaged with the UK curriculum, offering a unique and enjoyable learning experience. Our platform caters to a wide range of academic needs, providing interactive and engaging content that makes learning both effective and fun. The Parent Zone is our dedicated space for parents, offering a wealth of resources and guidance on topics like extra tuition and homeschooling. We understand the challenges and rewards of educational journeys, and our goal is to empower parents with the tools and information they need to support their children’s educational development at home.',
      social: [
        { 
          name: 'Facebook', 
          icon: FacebookIcon,
          url: 'https://www.facebook.com/profile.php?id=61554816500914' // Replace with your Facebook page URL
        },
      ],
      // Add additional links here as needed
    };

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

  return (
    <ThemeProvider theme={defaultTheme}>
    {/* AppBar */}
    <AppBar
    position="static"
    color="default"
    elevation={0}
    sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
  >
    <Toolbar sx={{ flexWrap: 'wrap' }}>
      <nav>
        {/* Your navigation items here */}
      </nav>
      <Button onClick={handleContactClick} variant="outlined" sx={{ my: 1, mx: 1.5 }}>
  Contact Us
</Button>
<Box flexGrow={1}></Box> {/* This Box pushes everything after it to the right */}
<UserGreeting />
</Toolbar>
</AppBar>
<CssBaseline />
<Container maxWidth="lg">
  <main>
    <MainFeaturedPost post={mainFeaturedPost} />
    <Grid container spacing={4}>
      {featuredPosts.map((post) => (
        <FeaturedPost key={post.title} post={post} />
      ))}
    </Grid>
    <Grid container spacing={5} sx={{ mt: 3 }}>
      <Main title="Older Posts" posts={posts} />
      <Sidebar
        title={sidebar.title}
        description={sidebar.description}
        social={sidebar.social}
        sx={{ mb: 8 }} // Add bottom margin
      />
    </Grid>
  </main>
</Container>

</ThemeProvider>
);

}