// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* src/Chat/ChatButton.module.css */\n.ChatButton_chatButton__YmyQK {\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n    padding: 0;\n  }\n  \n  .ChatButton_chatIcon__d-a61 {\n    width: 120px; /* Adjust the size as needed */\n    height: 110px;\n    /* No positioning properties applied */\n  }\n  \n  /* src/Chat/ChatButton.module.css */\n.ChatButton_chatButtonContainer__lbr-o {\n    position: fixed;\n    bottom: 25px; /* Distance from the bottom of the screen */\n    right: 20px; /* Distance from the right of the screen */\n    z-index: 1000; /* Ensure it appears above other elements */\n  }\n  ", "",{"version":3,"sources":["webpack://./src/Chat/ChatButton.module.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,UAAU;EACZ;;EAEA;IACE,YAAY,EAAE,8BAA8B;IAC5C,aAAa;IACb,sCAAsC;EACxC;;EAEA,mCAAmC;AACrC;IACI,eAAe;IACf,YAAY,EAAE,2CAA2C;IACzD,WAAW,EAAE,0CAA0C;IACvD,aAAa,EAAE,2CAA2C;EAC5D","sourcesContent":["/* src/Chat/ChatButton.module.css */\n.chatButton {\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n    padding: 0;\n  }\n  \n  .chatIcon {\n    width: 120px; /* Adjust the size as needed */\n    height: 110px;\n    /* No positioning properties applied */\n  }\n  \n  /* src/Chat/ChatButton.module.css */\n.chatButtonContainer {\n    position: fixed;\n    bottom: 25px; /* Distance from the bottom of the screen */\n    right: 20px; /* Distance from the right of the screen */\n    z-index: 1000; /* Ensure it appears above other elements */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatButton": "ChatButton_chatButton__YmyQK",
	"chatIcon": "ChatButton_chatIcon__d-a61",
	"chatButtonContainer": "ChatButton_chatButtonContainer__lbr-o"
};
export default ___CSS_LOADER_EXPORT___;
