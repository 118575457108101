import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  createTheme,
  ThemeProvider,
  TextField,
  Button,
  Typography,
  AppBar,
  Toolbar,
  CssBaseline,
  Container,
  Box,
  Link,
  Alert
} from '@mui/material';
import { Helmet } from 'react-helmet-async'; // Import Helmet
import useApi from '../Utils/useApi';

const defaultTheme = createTheme({
  typography: {
    h5: {
      fontSize: 'clamp(1rem, 4vw, 1.5rem)'
    }
  }
});

export default function ContactUs() {
  const [formStatus, setFormStatus] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const api = useApi();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setFormStatus(null); // Reset the form status
    setErrorMessage(""); // Reset any previous error messages

    const formData = {
        name: event.target.name.value,
        email: event.target.email.value,
        message: event.target.message.value
    };

    try {
        const response = await api.post('/user/send-contact-email', formData);

        // With axios, you directly get the response.data
        if (response.status === 200) {
            setFormStatus('success');
        } else {
            setFormStatus('error');
            setErrorMessage(response.data.msg || "An error occurred.");
        }
    } catch (error) {
        setFormStatus('error');
        // Access error response data
        const errorMsg = error.response && error.response.data && error.response.data.msg;
        setErrorMessage(errorMsg || "There was an error sending the email.");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Helmet>
        <title>Contact Us | Rocket Learning</title>
        <meta name="description" content="Get in touch with Rocket Learning. We’re here to answer any questions you may have. Reach out via our contact form or email us directly at support@rocketlearning.uk." />
        <meta name="keywords" content="Contact, Rocket Learning, support, questions, inquiries, home education" />
        <meta property="og:title" content="Contact Us | Rocket Learning" />
        <meta property="og:description" content="Reach out to Rocket Learning with any questions or inquiries. We’re here to help with all your home education needs." />
        <meta property="og:url" content="https://rocketlearning.uk/contactus" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://websitecontent.ams3.cdn.digitaloceanspaces.com/ContactBanner.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us | Rocket Learning" />
        <meta name="twitter:description" content="Have questions or need support? Contact Rocket Learning for any inquiries related to home education and our platform." />
        <meta name="twitter:image" content="https://websitecontent.ams3.cdn.digitaloceanspaces.com/ContactBanner.png" />
      </Helmet>

      <CssBaseline />

      {/* AppBar */}
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <nav>
            {/* Add navigation links if needed */}
          </nav>
          <Button 
            component={RouterLink} 
            to="/aboutus" 
            variant="outlined" 
            sx={{ my: 1, mx: 1.5 }}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>

      {/* Main content */}
      <Container maxWidth="md" component="main" sx={{ mt: 8, mb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Contact Us
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" sx={{ mb: 4 }}>
          Have questions? We'd love to hear from you! Fill out the form below or email us directly at 
          <Link href="mailto:support@rocketlearning.uk"> support@rocketlearning.uk</Link>.
        </Typography>

        {formStatus === 'success' && (
          <Alert severity="success" sx={{ mb: 3 }}>Email sent successfully!</Alert>
        )}
        {formStatus === 'error' && (
          <Alert severity="error" sx={{ mb: 3 }}>{errorMessage}</Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Your Name"
            name="name"
            autoComplete="name"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            multiline
            rows={4}
            id="message"
            label="Message"
            name="message"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Send Message
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
