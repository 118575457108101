import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
} from '@mui/material';
import useApi from '../Utils/useApi';

const AssessmentQuestions = () => {
  const { subjectId } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const api = useApi();

  useEffect(() => {
    // Fetch questions from the new endpoint
    api
      .get(`/assess/generateAssessmentnew?subject_id=${subjectId}`)
      .then((response) => {
        // The API returns an object with "questions" key
        const data = response.data.questions;
        setQuestions(data);
      })
      .catch((error) => {
        console.error('Error fetching questions:', error);
      });
  }, [subjectId]);

  const handleAnswerSelect = (questionId, answer) => {
    setAnswers({ ...answers, [questionId]: answer });
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      // Move to the next question
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // If it's the last question, submit the assessment
      handleSubmit();
    }
  };

// Inside AssessmentQuestions.js
const handleSubmit = async () => {
  try {
    const userId = localStorage.getItem('user_id');

    const payload = {
      user_id: userId,
      subject_id: subjectId,
      answers: answers, // { question_id: selected_option }
    };

    // Submit answers to the new endpoint
    const response = await api.post('/assess/submitAssessment', payload);

    const suggestedYearLevel = response.data.suggested_year_level;

    // Navigate to the assessment result page with the suggested year level
    navigate('/assessment-result', { state: { suggestedYearLevel } });
  } catch (error) {
    console.error('Error submitting assessment:', error);
    // Handle error (e.g., show a message to the user)
  }
};


  if (questions.length === 0) {
    return (
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        Loading questions...
      </Typography>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];
  const selectedAnswer = answers[currentQuestion.id];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        padding: '0 20px',
      }}
    >
      {/* Brief Explainer */}
      <div style={{ maxWidth: '800px', width: '100%', marginBottom: '20px' }}>
        <Typography variant="body1" color="textSecondary">
          It's okay if you don't know some (or any!) of the answers. This assessment helps us understand where you're at to provide the best learning experience for you.
        </Typography>
      </div>

      <div style={{ maxWidth: '800px', width: '100%' }}>
        <Typography variant="h5" gutterBottom>
          Question {currentQuestionIndex + 1} of {questions.length}
        </Typography>

        <Card variant="outlined" sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {currentQuestion.question_text}
            </Typography>

            <Grid container spacing={2}>
              {currentQuestion.options.map((option, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card
                    variant="outlined"
                    style={{
                      borderColor:
                        selectedAnswer === option ? '#1976d2' : 'rgba(0, 0, 0, 0.12)',
                      backgroundColor:
                        selectedAnswer === option ? '#e3f2fd' : 'inherit',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleAnswerSelect(currentQuestion.id, option)
                    }
                  >
                    <CardContent>
                      <Typography variant="body1">{option}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>

            {/* "I don't know" Button */}
            <div style={{ marginTop: '20px' }}>
              <Button
                variant="outlined"
                onClick={() => handleAnswerSelect(currentQuestion.id, "I don't know")}
                style={{
                  textTransform: 'none',
                }}
              >
                I don't know
              </Button>
            </div>

            <div style={{ marginTop: '20px', textAlign: 'right' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextQuestion}
                disabled={!selectedAnswer}
              >
                {currentQuestionIndex < questions.length - 1
                  ? 'Next Question'
                  : 'Submit Assessment'}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AssessmentQuestions;
