import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Button,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi';
import LoadingSpinner from '../Utils/LoadingSpinner';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const SUBJECT_ID = 7;
const defaultTheme = createTheme();

function BusinessStudies() {
  const navigate = useNavigate();
  const api = useApi();
  const coreApi = useCoreApi();

  // 1) AUTH & INITIAL SETUP
  const access_token = localStorage.getItem('accessToken');
  const student_id = localStorage.getItem('user_id');

  // For Business Studies, valid years are 7-11.
  const parsedYear = parseInt(localStorage.getItem('student_year'), 10);
  const initialYear = [7, 8, 9, 10, 11].includes(parsedYear) ? parsedYear : 7;

  // 2) STATE
  const [selectedYear, setSelectedYear] = useState(initialYear);
  const [yearInitialized, setYearInitialized] = useState(false);
  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  const [completedLessons, setCompletedLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userHasChosenYear, setUserHasChosenYear] = useState(false);

  // Subtopics array for Business Studies (update with your actual data)
  const subTopics = useMemo(() => [
    { id: 215, title: "Introduction to Business: Exploring the Purpose and Types of Businesses", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/215.jpg" },
    { id: 216, title: "Business and Society: How Businesses Impact Our Daily Lives", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/216.jpg" },
    { id: 217, title: "Entrepreneurship Basics: What It Means to Be an Entrepreneur", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/217.jpg" },
    { id: 218, title: "Simple Business Terms and Concepts: Understanding the Basics", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/218.jpg" },
    { id: 219, title: "The Role of Technology in Business: An Overview for Beginners", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/219.jpg" },
    { id: 220, title: "Basic Business Operations: Understanding How Businesses Work", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/220.jpg" },
    { id: 221, title: "Introduction to Business Ethics and Sustainability: Making Good Choices", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/221.jpg" },
    { id: 222, title: "Basics of Money Management: Learning to Save and Budget", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/222.jpg" },
    { id: 223, title: "Introduction to Personal Saving Goals: Why Saving Money Matters", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/223.jpg" },
    { id: 224, title: "Understanding Simple Interest: How Saving Money Can Grow Over Time", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/224.jpg" },
    { id: 225, title: "Business Objectives and Aims: Setting Goals in Business", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/225.jpg" },
    { id: 226, title: "Introduction to Marketing: How Businesses Reach Their Customers", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/226.jpg" },
    { id: 227, title: "The World of Finance: Revenue, Costs, and Profit Basics", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/227.jpg" },
    { id: 228, title: "Business Operations and Production: How Goods and Services are Made", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/228.jpg" },
    { id: 229, title: "Understanding Business Stakeholders: Who Has an Interest in Businesses?", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/229.jpg" },
    { id: 230, title: "The Importance of Business Planning: Creating a Simple Business Plan", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/230.jpg" },
    { id: 231, title: "Exploring Different Types of Business Ownership: Sole Traders to Corporations", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/231.jpg" },
    { id: 232, title: "Introduction to Investing: Simple Concepts of Making Money Work", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/232.jpg" },
    { id: 233, title: "Planning for a Financial Goal: Saving for Something Big", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/233.jpg" },
    { id: 234, title: "Basic Concepts of Borrowing: What Does It Mean to Borrow Money?", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/234.jpg" },
    { id: 235, title: "External Influences on Business: Technology, Economy, and Legislation", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/235.jpg" },
    { id: 236, title: "Marketing Mix and Strategies: Dive Deeper into Product, Price, Promotion, and Place", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/236.jpg" },
    { id: 237, title: "Human Resources Management: The Basics of Hiring and Motivating Employees", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/237.jpg" },
    { id: 238, title: "Advanced Business Finance: Introduction to Financial Ratios and Cash-Flow Forecasting", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/238.jpg" },
    { id: 239, title: "The Competitive Environment: Understanding Risk and Reward in Business", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/239.jpg" },
    { id: 240, title: "Business Ethics and Responsibility: A Closer Look at Ethical Business Practices", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/240.jpg" },
    { id: 241, title: "Business Growth Strategies: Exploring How Businesses Expand", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/241.jpg" },
    { id: 242, title: "The Concept of Credit: Understanding Credit Cards and Loans", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/242.jpg" },
    { id: 243, title: "Introduction to Mortgages: How People Buy Homes", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/243.jpg" },
    { id: 244, title: "Investing Basics for Teenagers: Stocks, Bonds, and Savings Accounts", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/244.jpg" },
    { id: 245, title: "Globalisation and International Business: How Businesses Operate Worldwide", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/245.jpg" },
    { id: 246, title: "In-depth Study of Business Operations: From Procurement to Quality Control", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/246.jpg" },
    { id: 247, title: "The Role of Digital Marketing in Modern Businesses", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/247.jpg" },
    { id: 248, title: "Analyzing Business Performance: Using Financial Information for Decision Making", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/248.jpg" },
    { id: 249, title: "Understanding Market Research: Qualitative vs Quantitative Approaches", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/249.jpg" },
    { id: 250, title: "Organisational Structures and Communication in Businesses", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/250.jpg" },
    { id: 251, title: "Problem Solving in Business: Making Informed Decisions with Quantitative Data", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/251.jpg" },
    { id: 252, title: "Personal Budgeting and Finance Management: Tools and Techniques", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/252.jpg" },
    { id: 253, title: "Understanding Taxes and Payslips: Where Does the Money Go?", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/253.jpg" },
    { id: 254, title: "Principles of Smart Investing: Diversification and Risk Management", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/254.jpg" },
    { id: 255, title: "Preparing for the Business World: Transitioning from GCSE to A-Level or Career", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/255.jpg" },
    { id: 256, title: "Strategic Business Decision Making: Applying Knowledge to Real-World Scenarios", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/256.jpg" },
    { id: 257, title: "E-commerce and Digital Businesses: The Future of Business", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/257.jpg" },
    { id: 258, title: "Sustainability and Global Challenges for Businesses", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/258.jpg" },
    { id: 259, title: "Advanced Marketing Strategies: Segmenting, Targeting, and Positioning", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/259.jpg" },
    { id: 260, title: "Leadership and Management in Business: Developing Effective Business Leaders", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/260.jpg" },
    { id: 261, title: "Evaluating Business Finance: Advanced Calculations and Interpretations for Decision Making", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/261.jpg" },
    { id: 262, title: "Financial Planning for the Future: Pensions and Long-Term Saving Strategies", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/262.jpg" },
    { id: 263, title: "Getting Ready for the Real World: Managing Finances After School", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/263.jpg" },
    { id: 264, title: "Entrepreneurship and Personal Finance: Starting a Business with Minimal Resources", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/264.jpg" },

  ], []);

  // 3) AXIOS INTERCEPTOR FOR 401
  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response && err.response.status === 401) {
        navigate('/StudentLogin');
      }
      return Promise.reject(err);
    }
  );

  // 4) REDIRECT IF NO TOKEN
  useEffect(() => {
    if (!access_token) {
      console.warn('Missing access token. Redirecting to login.');
      navigate('/StudentLogin');
    }
  }, [access_token, navigate]);

  // 5) DATA FETCHING FUNCTIONS

  // 5A) Fetch the last completed year for Business Studies
  const fetchLastCompletedYear = async () => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/last_completed_year_for_subject/${SUBJECT_ID}`,
        { headers: { Authorization: `Bearer ${access_token}` }, timeout: 5000 }
      );
      if (response.status >= 200 && response.status < 300 && response.data?.year_id) {
        return response.data.year_id;
      }
    } catch (err) {
      console.error('Error fetching last completed year:', err);
    }
    return null;
  };

  // 5B) Fetch completed lessons for one subtopic
  const fetchCompletedLessons = async (subTopicId, year) => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/completed_lessons/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data.map((lesson) => lesson.lesson_id);
      }
    } catch (err) {
      console.error(`Error fetching completed lessons for subtopic ${subTopicId}:`, err);
    }
    return [];
  };

  // 5C) Fetch lessons for one subtopic
  const fetchLessonsBySubtopic = async (subTopicId, year) => {
    try {
      const response = await coreApi.get(
        `/lessons/lessons_by_subject/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      console.error(`Error fetching lessons for subtopic ${subTopicId}:`, err);
    }
    return [];
  };

  // 5D) Master fetch function using parallel requests
  const fetchAllDataForYear = async (year) => {
    setIsLoading(true);
    setError(null);
    try {
      // Filter subtopics for the selected year
      const relevantSubTopics = subTopics.filter((st) => st.year === Number(year));

      // 1) Fetch all completed lessons concurrently
      const completedPromises = relevantSubTopics.map((st) =>
        fetchCompletedLessons(st.id, year)
      );
      const completedArrays = await Promise.all(completedPromises);
      const allCompletedIds = completedArrays.flat();
      setCompletedLessons(allCompletedIds);

      // 2) Fetch all lessons concurrently
      const lessonPromises = relevantSubTopics.map(async (st) => {
        const lessons = await fetchLessonsBySubtopic(st.id, year);
        return { subTopicId: st.id, lessons };
      });
      const lessonsArray = await Promise.all(lessonPromises);

      // 3) Combine the lesson data with completed status
      const newLessonsBySubtopic = {};
      lessonsArray.forEach((item) => {
        newLessonsBySubtopic[item.subTopicId] = item.lessons.map((lesson) => ({
          ...lesson,
          is_completed: allCompletedIds.includes(lesson.id),
        }));
      });
      setLessonsBySubtopic(newLessonsBySubtopic);
    } catch (err) {
      console.error("Error fetching all data for year:", err);
      setError("Error fetching lessons. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Retry function for when data fetch fails or returns empty
  const retryFetch = () => {
    fetchAllDataForYear(selectedYear);
  };

  // 6) EFFECTS

  // Effect A: On initial mount, fetch last completed year if the user hasn't manually chosen one.
  useEffect(() => {
    if (!access_token) return;
    if (userHasChosenYear) {
      setYearInitialized(true);
      return;
    }
    let canceled = false;
    (async () => {
      const lastYear = await fetchLastCompletedYear();
      if (!canceled) {
        if (lastYear && lastYear !== selectedYear) {
          setSelectedYear(lastYear);
        }
        setYearInitialized(true);
      }
    })();
    return () => {
      canceled = true;
    };
  }, [access_token, userHasChosenYear, selectedYear]);

  // Effect B: Once the year is initialized, fetch the lesson data.
  useEffect(() => {
    if (!access_token || !yearInitialized) return;
    fetchAllDataForYear(selectedYear);
  }, [access_token, selectedYear, yearInitialized]);

  // 7) RENDERING HELPERS

  const CustomMenuItem = ({ completed, children, ...props }) => (
    <MenuItem {...props}>
      {completed ? (
        <ListItemText
          primary={children}
          primaryTypographyProps={{ style: { textDecoration: 'line-through', color: 'green' } }}
        />
      ) : (
        <ListItemText primary={children} />
      )}
    </MenuItem>
  );

  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
        value={selectedYear}
        onChange={(e) => {
          setUserHasChosenYear(true);
          setSelectedYear(Number(e.target.value));
        }}
      >
        {[7, 8, 9, 10, 11].map((yr) => (
          <MenuItem key={yr} value={yr}>
            Year {yr}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderSubtopicAccordions = () => {
    const relevantSubTopics = subTopics.filter((st) => st.year === Number(selectedYear));
  
    return (
      <Box>
        {relevantSubTopics.map((subTopic) => {
          const subLessons = lessonsBySubtopic[subTopic.id] || [];
          const noLessonsFound = subLessons.length === 0;
  
          return (
            <Accordion key={`${subTopic.id}-${subTopic.year}`} sx={{ mb: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center">
                  <CardMedia
                    component="img"
                    image={subTopic.image}
                    alt="Subtopic thumbnail"
                    sx={{ width: 60, height: 60, borderRadius: 1, mr: 2 }}
                  />
                  <Typography variant="subtitle1" fontWeight="bold">
                    {subTopic.title}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {subTopic.isStaticLink ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(subTopic.externalLink, '_blank')}
                  >
                    Go to resource
                  </Button>
                ) : (
                  <Box>
                    {noLessonsFound && (
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        Oops, no lessons found.{' '}
                        <Button
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            retryFetch();
                          }}
                        >
                          Retry?
                        </Button>
                      </Typography>
                    )}
  
                    {(() => {
                      const seriesMap = {};
                      const standalone = [];
  
                      for (let lesson of subLessons) {
                        if (lesson.series_id) {
                          if (!seriesMap[lesson.series_id]) {
                            seriesMap[lesson.series_id] = [];
                          }
                          seriesMap[lesson.series_id].push(lesson);
                        } else {
                          standalone.push(lesson);
                        }
                      }
  
                      const rendered = [];
  
                      Object.values(seriesMap).forEach((seriesLessons) => {
                        const sorted = [...seriesLessons].sort(
                          (a, b) => a.series_order - b.series_order
                        );
  
                        rendered.push(
                          <Box
                            key={`series-${sorted[0].id}`}
                            sx={{
                              border: '2px dashed #888',
                              borderRadius: 2,
                              p: 1,
                              mb: 1,
                              backgroundColor: '#f0f4ff',
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ mb: 1, color: '#333' }}
                            >
                              📚 Lesson Series: {sorted[0].title.split(' – ')[0]} Series
                            </Typography>
  
                            {sorted.map((lesson) => (
                              <Button
                                key={lesson.id}
                                fullWidth
                                variant="outlined"
                                sx={{
                                  justifyContent: 'flex-start',
                                  mb: 1,
                                  textDecoration: lesson.is_completed ? 'line-through' : 'none',
                                  color: lesson.is_completed ? 'green' : 'inherit',
                                  textTransform: 'none',
                                }}
                                onClick={() => navigate(`/Classroom/${lesson.id}`)}
                              >
                                {lesson.title} (Part {lesson.series_order})
                              </Button>
                            ))}
                          </Box>
                        );
                      });
  
                      standalone.forEach((lesson) => {
                        rendered.push(
                          <Button
                            key={lesson.id}
                            fullWidth
                            variant="outlined"
                            sx={{
                              justifyContent: 'flex-start',
                              mb: 1,
                              textDecoration: lesson.is_completed ? 'line-through' : 'none',
                              color: lesson.is_completed ? 'green' : 'inherit',
                              textTransform: 'none',
                            }}
                            onClick={() => navigate(`/Classroom/${lesson.id}`)}
                          >
                            {lesson.title}
                          </Button>
                        );
                      });
  
                      return rendered;
                    })()}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    );
  };
  

  // 8) FINAL RENDER

  if (!access_token) {
    return null;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate('/LessonSelect')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Business Studies
          </Typography>
        </Toolbar>
      </AppBar>
     <Box sx={{ bgcolor: '#f9fafb', pt: 8, pb: 6, minHeight: '100vh' }}>

        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a year first.
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Don't be scared to drop down or move up years—there's value in practicing the basics or taking on a challenge!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <b>Year Selector</b>
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
          (Note: This will default to the year of your last Business Studies lesson, but feel free to change it whenever!)
        </Typography>
        <Container maxWidth="lg">
  <Box sx={{ backgroundColor: 'white', p: 4, borderRadius: 3, boxShadow: 3 }}>
    {renderYearDropdown()}
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" align="center" color="text.secondary" paragraph>
        <b>Lesson Selector</b>
      </Typography>
      {renderSubtopicAccordions()}
    </Box>
  </Box>
</Container>
      </Box>
    </ThemeProvider>
  );
}

export default BusinessStudies;
