import React, { useEffect, useState } from 'react';
import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi';
import {
  AppBar, Button, Toolbar, Typography, Container, Box,
  IconButton, Grid, Paper, Accordion, AccordionSummary, AccordionDetails, Modal, Backdrop, Fade
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import useCountdownToSundayMidnight from '../Utils/CountdownToSundayMidnight';
import NotificationIcon from './NotificationIcon';
import UserGreeting from './UserGreeting';
import AvatarDisplayDash from '../Avatar/AvatarDisplayDash';
import AvatarIcon from '../Avatar/AvatarIcon';
import AvatarIconLB from '../Avatar/AvatarIconLB.js'; // Ensure this path is correctconst safeApiCall = async (apiCall) => {
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { setCache, getCache } from '../Utils/cacheUtils';
import AvatarIconLBFast from '../Avatar/AvatarIconLBFast';
import { CircularProgress } from '@mui/material';
import ProfileView from './ProfileView'; // Import ProfileView


const defaultTheme = createTheme();

// Add modal styles
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw", // 80% of the viewport width
  maxWidth: "800px", // Maximum width
  height: "80vh", // 80% of the viewport height
  maxHeight: "90vh", // Maximum height
  backgroundColor: "#fff",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  padding: "16px",
  overflowY: "auto", // Add scroll for content overflow
};


const StudentDashboard = () => {
  const navigate = useNavigate();
  const [studentProgress, setStudentProgress] = useState({});
  const [lessonsProgress, setLessonsProgress] = useState([]);
  const student_id = localStorage.getItem('user_id');
  const access_token = localStorage.getItem('accessToken');
  const [currentLeague, setCurrentLeague] = useState("");
  const [leagueBadgeURL, setLeagueBadgeURL] = useState('');
  const [leaderboard, setLeaderboard] = useState([]);
  const [earnedBadges, setEarnedBadges] = useState([]);
  const [availableBadges, setAvailableBadges] = useState([]);
  const [leagueTiers, setLeagueTiers] = useState([]);
  const countdown = useCountdownToSundayMidnight();
  const api = useApi();
  const coreApi = useCoreApi();
  const [avatars, setAvatars] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);


    // Open modal and set user ID
    const handleUsernameClick = (userId) => {
      setSelectedUserId(userId);
      setIsModalOpen(true);
    };
  
    // Close modal
    const handleCloseModal = () => {
      setIsModalOpen(false);
      setSelectedUserId(null);
    };

  const getCurrentZone = (rocketfuel, thresholds) => {
    if (rocketfuel < thresholds.demotion) return 'Demotion Zone';
    if (rocketfuel > thresholds.promotion) return 'Promotion Zone';
    return 'Neutral Zone';
  };

  const thresholds = {
    promotion: 1000,
    demotion: 100
  };

  const currentStudentEntry = leaderboard.find(entry => entry.user_id.toString() === student_id);
  const currentRocketfuel = currentStudentEntry ? currentStudentEntry.rocketfuel : 0;
  const pointsToPromotion = thresholds.promotion - currentRocketfuel;
  const pointsToStay = thresholds.demotion - currentRocketfuel;
  const [name, setName] = useState('');

  const AvatarPlaceholder = () => (
    <Box sx={{ width: '50px', height: '50px', backgroundColor: '#f0f0f0', borderRadius: '50%' }} />
  );
  
  const BadgePlaceholder = () => (
    <Box sx={{ width: '150px', height: '150px', backgroundColor: '#f0f0f0', marginBottom: '10px' }} />
  );
  


  useEffect(() => {
    const user_id = localStorage.getItem('user_id');
    const access_token = localStorage.getItem('accessToken');
    if (user_id && access_token) {
      api.get(`/user/getname/${user_id}`, {
        headers: { 'Authorization': `Bearer ${access_token}` }
      })
      .then(response => {
        if (response.data.firstname) {
          setName(response.data.firstname);
        }
      })
      .catch(error => console.log(error));
    }
  }, []);

  const handleRowClick = (row) => {
    navigate(`/Classroom/${row.lesson_id}`);
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
  
      const safeApiCall = async (apiCall) => {
        try {
          return await apiCall;
        } catch (error) {
          console.error('API Error:', error);
          return null;
        }
      };
  
      try {
        // Step 1: Check and load cached data
        const cachedBadges = getCache('badges');
        const cachedAvailableBadges = getCache('availableBadges');
        const cachedTiers = getCache('leagueTiers');
        const cachedLeaderboard = getCache('leaderboard');
  
        if (cachedBadges) setEarnedBadges(cachedBadges);
        if (cachedAvailableBadges) setAvailableBadges(cachedAvailableBadges);
        if (cachedTiers) setLeagueTiers(cachedTiers);
        if (cachedLeaderboard) {
          setLeaderboard(cachedLeaderboard.filter(entry => entry.rocketfuel > 0));
        }
  
        // Step 2: Fetch leaderboard if not cached
        let filteredLeaderboard = cachedLeaderboard?.filter(entry => entry.rocketfuel > 0) || [];
        if (!cachedLeaderboard) {
          const leaderboardResponse = await safeApiCall(
            api.get(`/rfleague/get-hierarchical-leaderboard`, {
              headers: { Authorization: `Bearer ${access_token}` },
            })
          );
          filteredLeaderboard =
            leaderboardResponse?.data?.leaderboard?.filter(
              (entry) => entry.rocketfuel > 0
            ) || [];
          setLeaderboard(filteredLeaderboard);
          setCache('leaderboard', filteredLeaderboard, 10);
        }
  
        // Step 3: Fetch avatars now that leaderboard is ready
        const leaderboardUserIds = filteredLeaderboard.map((entry) => entry.user_id);
        const avatarResponse = await safeApiCall(
          coreApi.post(`/avatar/users`, { user_ids: leaderboardUserIds })
        );
        if (avatarResponse?.data) {
          const avatarMap = avatarResponse.data.reduce((acc, avatarData) => {
            acc[avatarData.user_id] = avatarData.components.sort(
              (a, b) => a.layer - b.layer
            );
            return acc;
          }, {});
          setAvatars(avatarMap);
        }
  
        // Step 4: Fetch remaining data in parallel
        const [
          progressResponse,
          studentProgressResponse,
          leagueResponse,
          badgesResponse,
          availableBadgesResponse,
          tiersResponse,
        ] = await Promise.all([
          safeApiCall(
            api.get(`/progress/student/${student_id}/last_completed_lessons`, {
              headers: { Authorization: `Bearer ${access_token}` },
            })
          ),
          safeApiCall(
            api.get(`/progress/student-progress/${student_id}`, {
              headers: { Authorization: `Bearer ${access_token}` },
            })
          ),
          safeApiCall(
            api.get(`/rfleague/get-current-league`, {
              headers: { Authorization: `Bearer ${access_token}` },
            })
          ),
          !cachedBadges &&
            safeApiCall(api.get(`/rfleague/get-badges`)),
          !cachedAvailableBadges &&
            safeApiCall(api.get(`/rfleague/get-available-badges`)),
          !cachedTiers &&
            safeApiCall(api.get(`/rfleague/get-league-tiers`)),
        ]);
  
        // Step 5: Update state with fetched data
        setLessonsProgress(progressResponse?.data || []);
        setStudentProgress(studentProgressResponse?.data || {});
        if (leagueResponse?.data) {
          setCurrentLeague(leagueResponse.data.league_name);
          setLeagueBadgeURL(leagueResponse.data.imgURL);
        }
  
        if (!cachedBadges) {
          setCache('badges', badgesResponse?.data?.badges || [], 10);
          setEarnedBadges(badgesResponse?.data?.badges || []);
        }
  
        if (!cachedAvailableBadges) {
          setCache('availableBadges', availableBadgesResponse?.data?.badges || [], 10);
          setAvailableBadges(availableBadgesResponse?.data?.badges || []);
        }
  
        if (!cachedTiers) {
          setCache('leagueTiers', tiersResponse?.data?.league_tiers || [], 10);
          setLeagueTiers(tiersResponse?.data?.league_tiers || []);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchDashboardData();
  }, [student_id, access_token]);
  
  

  const handleLogin = () => {
    window.location.href = "/StudentLogin";
  };

  const anonymizeUsername = (username, userId) => {
    // If the userId matches student_id, return the username as is
    if (userId.toString() === student_id) return username;
  
    // If there's no username, return an empty string
    if (!username) return '';
  
    // Otherwise, return the full username without anonymizing
    return username;
  };

  const columns = [
    {
      field: 'avatar',
      headerName: '',
      width: 70,
      renderCell: (params) => {
        const userId = params.row.user_id;
        return avatars[userId] ? (
          <AvatarIconLBFast userIds={[userId]} avatars={avatars} />
        ) : (
          <AvatarPlaceholder />
        );
      },
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'username',
      headerName: 'Student',
      flex: 1,
      cellClassName: (params) =>
        params.row.isStudent ? 'highlighted-student' : '',
      renderCell: (params) => (
        <span
          onClick={() => handleUsernameClick(params.row.user_id)}
          style={{ cursor: 'pointer', color: '#1976d2', textDecoration: 'underline' }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: 'rocketfuel',
      headerName: 'Rocketfuel',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'leagueBadge',
      headerName: 'League Badge',
      width: 100,
      renderCell: (params) => (
        <LazyLoadImage
          src={params.row.imgURL || "https://fallback-url.com/placeholder.png"} // Use league badge URL
          alt="League Badge"
          effect="blur"
          style={{ width: '50px', height: '50px' }}
        />
      ),
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
  ];
  

  // Group and sort leaderboard by leagues
  const groupedLeaderboard = leaderboard.reduce((acc, entry) => {
    if (!acc[entry.league_name]) {
      acc[entry.league_name] = [];
    }
    acc[entry.league_name].push(entry);
    return acc;
  }, {});

  const sortedLeagues = leagueTiers
    .map(tier => tier.name)
    .filter(leagueName => groupedLeaderboard[leagueName] && groupedLeaderboard[leagueName].length > 0);

  if (!access_token) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate('/LessonSelect')}>
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              Student Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
          <Container maxWidth="sm">
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Please log in to see your dashboard
            </Typography>
            <Button variant="contained" onClick={handleLogin}>Log in</Button>
          </Container>
        </Box>
      </ThemeProvider>
    );
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  
  

  return (
    <ThemeProvider theme={defaultTheme}>
       {/* Global background style */}
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      background: 'linear-gradient(to bottom, #f0f0f0, #d9d9d9)',
      zIndex: -1,
    }}
  ></Box>
      <AppBar position="static" color="default" elevation={0} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate('/RLBlog')}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            {name}'s Dashboard
          </Typography>
          
          <Box flexGrow={1} /> {/* This pushes everything after it to the right */}
          <Box sx={{ mx: 2 }}>
            <Button variant="contained" color="success" onClick={() => navigate('/Assessments')}>
              Take an Assessment
            </Button>
          </Box>
          <UserGreeting />
          <IconButton onClick={() => navigate('/Avatar')} sx={{ ml: 2 }}>
            <AvatarIcon accessToken={access_token} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>

            {/* Grid for Your Progress and Your Earned Badges */}
            <Grid item xs={12} md={7}>
              <Typography variant="h5" align="center" color="text.secondary" paragraph>
                Your Progress
              </Typography>
              {lessonsProgress.map((progress) => (
                <Paper key={progress.subject_id} style={{ marginBottom: '20px', padding: '16px' }}>
                  <Typography variant="h6">{progress.subject_name}</Typography>
                  <Typography variant="body1">Last Completed: {progress.last_completed_lesson_title}</Typography>
                  {progress.next_lesson_id && (
                    <>
                      <Typography variant="body1" style={{ marginTop: '10px' }}>Next Lesson: {progress.next_lesson_title}</Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: '10px' }}
                        onClick={() => navigate(`/Classroom/${progress.next_lesson_id}`)}
                      >
                        Go to Next Lesson
                      </Button>
                    </>
                  )}
                </Paper>
              ))}
{/* Standard Badges Accordion */}
<Accordion>
  <AccordionSummary expandIcon={<ArrowBackIosIcon />} aria-controls="standard-badges-content" id="standard-badges-header">
    <Typography>Earned Badges</Typography>
  </AccordionSummary>
  <AccordionDetails>
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
      {availableBadges.length > 0 ? (
        availableBadges
          .filter(badge => badge.prestige_level === 0) // Filter standard badges
          .map((badge) => {
            const earned = earnedBadges.some(eBadge => eBadge.league_name === badge.league_name);
            const badgeURL = earned ? badge.imgURL : "https://badges.ams3.cdn.digitaloceanspaces.com/nobadge.png";
            return (
              <div key={badge.league_name} style={{ margin: '15px 10px', textAlign: 'center' }}>
                <LazyLoadImage
                  src={badgeURL}
                  alt={badge.league_name}
                  effect="blur"
                  style={{ width: '150px', height: '150px', marginBottom: '10px' }}
                />
                <Typography variant="body2">{badge.league_name}</Typography>
              </div>
            );
          })
      ) : (
        Array.from({ length: 5 }).map((_, idx) => (
          <BadgePlaceholder key={idx} />
        ))
      )}
    </div>
  </AccordionDetails>
</Accordion>
{/* Prestige Levels Accordion */}
<Accordion>
  <AccordionSummary expandIcon={<ArrowBackIosIcon />} aria-controls="prestige-content" id="prestige-header">
    <Typography>Earned Prestige Badges</Typography>
  </AccordionSummary>
  <AccordionDetails>
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
      {availableBadges
        .filter(badge => badge.prestige_level > 0) // Filter only prestige badges
        .map((badge) => {
          const earned = earnedBadges.some(eBadge => eBadge.league_name === badge.league_name);
          const badgeURL = earned
            ? badge.imgURL
            : "https://badges.ams3.cdn.digitaloceanspaces.com/nobadge.png";
          return (
            <div key={badge.league_name} style={{ margin: '15px 10px', textAlign: 'center' }}>
              <LazyLoadImage
                src={badgeURL}
                alt={badge.league_name}
                effect="blur"
                style={{ width: '150px', height: '150px', marginBottom: '10px' }}
              />
              <Typography variant="body2">{badge.league_name}</Typography>
            </div>
          );
        })}
    </div>
  </AccordionDetails>
</Accordion>
            </Grid>
            {/* Grid item for Rocketfuel and Leaderboard */}
            <Grid item xs={12} md={5}>
              <Paper elevation={3} style={{ padding: '16px' }}>
                <Typography variant="h5" align="center" color="text.secondary" paragraph>
                  {currentLeague}
                </Typography>
                <div style={{ textAlign: 'center' }}>
                <LazyLoadImage
  src={leagueBadgeURL}
  alt="League Badge"
  effect="blur" // Add a blur effect while loading
  style={{ width: '300px', height: '300px' }}
/>
                </div>
                <AvatarDisplayDash userId={student_id} accessToken={access_token} style={{ width: '300px', height: '300px' }} />
                <Typography variant="h7" align="center" color="text.secondary" paragraph>
                  Make sure you've got all your rocketfuel stored before the clock strikes Midnight on Sunday!
                </Typography>
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                  League Update Countdown: <br /> {countdown}
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                  This Week's Rocketfuel: <strong>{currentRocketfuel}</strong>
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                  You are in the: <strong>{getCurrentZone(currentRocketfuel, thresholds)}</strong>
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                  Points to stay in current league: <strong>{pointsToStay}</strong>
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" paragraph>
                  Points for next promotion: <strong>{pointsToPromotion}</strong>
                </Typography>
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                  Leaderboard
                </Typography>
                <Typography variant="h7" align="center" color="text.secondary" paragraph>
                  Earned RocketFuel updates every 5 minutes. Don't worry if you can't see it right away!
                </Typography>
                {sortedLeagues.map(leagueName => (
                  <React.Fragment key={leagueName}>
                    <Typography variant="h6" align="center" color="text.secondary" paragraph>
                      {leagueName} League
                    </Typography>
                    <DataGrid
                      autoHeight
                      autoWidth
                      pageSize={25}
                      rowsPerPageOptions={[10, 20, 50]}
                      rows={groupedLeaderboard[leagueName]?.map(entry => ({
                        ...entry,
                        id: entry.user_id,
                        username: anonymizeUsername(entry.username, entry.user_id),
                        isStudent: entry.user_id.toString() === student_id,
                        imgURL: entry.imgURL, // Include league badge URL
                      })) || []}
                      
                      columns={columns}
                    />
                  </React.Fragment>
                ))}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    {/* Modal for ProfileView */}
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <div style={modalStyle}>
          {selectedUserId && <ProfileView userId={selectedUserId} />}
        </div>
      </Fade>
    </Modal>
  </ThemeProvider>
  );
};

export default StudentDashboard;
