import React, { useState, useEffect, useRef, Suspense } from 'react';
import io from 'socket.io-client';
import styles from './ChatButton.module.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ChatApp from './ChatApp';

const ChatButton = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isChatEnabled, setIsChatEnabled] = useState(false);
  const [hasNewMessages, setHasNewMessages] = useState(false);
  const isChatOpenRef = useRef(isChatOpen);
  const socketRef = useRef(null);

  // Update the ref with the latest isChatOpen value, and clear notifications when opening chat
  useEffect(() => {
    isChatOpenRef.current = isChatOpen;
    if (isChatOpen) {
      setHasNewMessages(false);
    }
  }, [isChatOpen]);

  // Check if chat is enabled based on token, chat_enabled flag, and user type
  const checkChatEnabled = () => {
    const token = localStorage.getItem('accessToken');
    const chatEnabled = localStorage.getItem('chat_enabled') === 'true';
    const userType = localStorage.getItem('usertype');
    return token && chatEnabled && userType === 'student';
  };

  // Update chat-enabled state on mount and on login state changes
  useEffect(() => {
    setIsChatEnabled(checkChatEnabled());

    const handleLoginStateChange = () => {
      setIsChatEnabled(checkChatEnabled());
    };

    window.addEventListener('loginStateChange', handleLoginStateChange);

    return () => {
      window.removeEventListener('loginStateChange', handleLoginStateChange);
    };
  }, []);

  // Create a lightweight socket connection to listen for new messages when chat is closed.
  // This hook is always called, but will do nothing if chat isn't enabled.
  useEffect(() => {
    if (!isChatEnabled) return;

    const token = localStorage.getItem('accessToken');
    const userType = localStorage.getItem('usertype');

    if (token && localStorage.getItem('chat_enabled') === 'true' && userType === 'student') {
      const socket = io('https://whale-app-6qyfq.ondigitalocean.app', {
        auth: { token: `Bearer ${token}` },
      });
      socketRef.current = socket;

      socket.on('receive-message', (msg) => {
        // If chat isn't open, flag that there are new messages
        if (!isChatOpenRef.current) {
          setHasNewMessages(true);
        }
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [isChatEnabled]);

  // Only render the chat button if the user is a logged in student
  if (!isChatEnabled) {
    return null;
  }

  // Toggle chat open/close and clear notifications when opened
  const toggleChat = () => {
    setIsChatOpen((prevState) => {
      const newState = !prevState;
      if (newState === true) {
        setHasNewMessages(false);
      }
      return newState;
    });
  };

  const toggleMinimize = () => {
    setIsMinimized((prev) => !prev);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      {!isMinimized ? (
        !isChatOpen && (
          <div className={styles.chatButtonContainer}>
            <button
              onClick={toggleChat}
              className={styles.chatButton}
              aria-label="Open Chat"
              title="Chat"
            >
              <img
                src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/RocketChatIcon.png"
                alt="Chat Icon"
                className={styles.chatIcon}
              />
              {hasNewMessages && (
                <span className={styles.notificationBadge}></span>
              )}
            </button>
            <button className={styles.minimizeButton} onClick={toggleMinimize} title="Minimize Chat">
              ❌
            </button>
          </div>
        )
      ) : (
        <div className={styles.miniChatButton} onClick={toggleMinimize} title="Show Chat">
          💬
        </div>
      )}

      {isChatOpen && (
        <Suspense fallback={<div>Loading Chat...</div>}>
          <ChatApp closeChat={() => setIsChatOpen(false)} />
        </Suspense>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ChatButton;
