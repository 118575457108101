import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi';
import LoadingSpinner from '../Utils/LoadingSpinner';  // Import your spinner

import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Button,
  IconButton,
  Card,
  CardContent,
  Grid,
  CardMedia,
  Select,
  MenuItem,
  FormControl,
  ListItemText,
} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const SUBJECT_ID = 5;
const defaultTheme = createTheme();

function English() {
  const navigate = useNavigate();
  const api = useApi();
  const coreApi = useCoreApi();

  // ----------------------------------------------------------------
  // AUTH & INITIAL SETUP
  // ----------------------------------------------------------------
  const access_token = localStorage.getItem('accessToken');
  const student_id = localStorage.getItem('user_id');

  // Safely parse "student_year" from localStorage or default to 1
  const parsedYear = parseInt(localStorage.getItem('student_year'), 10);
  const initialYear = Number.isNaN(parsedYear) ? 1 : parsedYear;

  // ----------------------------------------------------------------
  // STATE
  // ----------------------------------------------------------------
  const [selectedYear, setSelectedYear] = useState(initialYear);
  const [subTopics, setSubTopics] = useState([
    { id: 10, title: "Reading", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-reading.avif" },
    { id: 11, title: "Writing", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-writing.avif" },
    { id: 12, title: "Grammar and vocabulary", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-grammar.avif" },
    { id: 30, title: "Spelling", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-spelling.avif" },
    { id: 10, title: "Reading", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-reading.avif" },
    { id: 11, title: "Writing", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-writing.avif" },
    { id: 12, title: "Grammar and vocabulary", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-grammar.avif" },
    { id: 30, title: "Spelling", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-spelling.avif" },
    { id: 10, title: "Reading", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-reading.avif" },
    { id: 11, title: "Writing", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-writing.avif" },
    { id: 12, title: "Grammar and vocabulary", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-grammar.avif" },
    { id: 30, title: "Spelling", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-spelling.avif" },
    { id: 10, title: "Reading", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-reading.avif" },
    { id: 11, title: "Writing", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-writing.avif" },
    { id: 12, title: "Grammar and vocabulary", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-grammar.avif" },
    { id: 30, title: "Spelling", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-spelling.avif" },
    { id: 10, title: "Reading", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-reading.avif" },
    { id: 11, title: "Writing", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-writing.avif" },
    { id: 12, title: "Grammar and vocabulary", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-grammar.avif" },
    { id: 30, title: "Spelling", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-spelling.avif" },
    { id: 10, title: "Reading", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-reading.avif" },
    { id: 11, title: "Writing", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-writing.avif" },
    { id: 12, title: "Grammar and vocabulary", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-grammar.avif" },
    { id: 30, title: "Spelling", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-spelling.avif" },
    { id: 10, title: "Reading", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-reading.avif" },
    { id: 11, title: "Writing", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-writing.avif" },
    { id: 12, title: "Grammar and vocabulary", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-grammar.avif" },
    { id: 13, title: "Spoken English", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-speaking.avif" },
    { id: 10, title: "Reading", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-reading.avif" },
    { id: 11, title: "Writing", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-writing.avif" },
    { id: 12, title: "Grammar and vocabulary", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-grammar.avif" },
    { id: 13, title: "Spoken English", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-speaking.avif" },
    { id: 10, title: "Reading", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-reading.avif" },
    { id: 11, title: "Writing", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-writing.avif" },
    { id: 12, title: "Grammar and vocabulary", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-grammar.avif" },
    { id: 13, title: "Spoken English", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-speaking.avif" },
    { id: 10, title: "Reading", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-reading.avif" },
    { id: 11, title: "Writing", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-writing.avif" },
    { id: 12, title: "Grammar and vocabulary", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-grammar.avif" },
    { id: 10, title: "Reading", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-reading.avif" },
    { id: 11, title: "Writing", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-writing.avif" },
    { id: 12, title: "Grammar and vocabulary", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-grammar.avif" },
    { id: 13, title: "Spoken English", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-speaking.avif" },
    { id: 69, title: "English Literature - Shakespeare - A Midsummer Night's Dream", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-midsummer.avif" },
    { id: 'shakespeare-plays', title: "Download Shakespeare Plays", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-shakespeare.avif", isStaticLink: true, externalLink: 'https://www.folger.edu/explore/shakespeares-works/download/' },
    { id: 71, title: "English Literature - Shakespeare - The Merchant of Venice", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-merchant.avif" },
    { id: 'shakespeare-plays', title: "Download Shakespeare Plays", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-shakespeare.avif", isStaticLink: true, externalLink: 'https://www.folger.edu/explore/shakespeares-works/download/' },
    { id: 72, title: "English Literature - Shakespeare - Romeo and Juliet", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-romeojuliet.avif" },
    { id: 'shakespeare-plays', title: "Download Shakespeare Plays", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-shakespeare.avif", isStaticLink: true, externalLink: 'https://www.folger.edu/explore/shakespeares-works/download/' },
    { id: 73, title: "English Literature - Shakespeare - The Tempest", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-thetempest.avif" },
    { id: 'shakespeare-plays', title: "Download Shakespeare Plays", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-shakespeare.avif", isStaticLink: true, externalLink: 'https://www.folger.edu/explore/shakespeares-works/download/' },
    { id: 74, title: "English Literature - Shakespeare - Othello", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-othello.avif" },
    { id: 'shakespeare-plays', title: "Download Shakespeare Plays", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/english-shakespeare.avif", isStaticLink: true, externalLink: 'https://www.folger.edu/explore/shakespeares-works/download/' },

  ]);

  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  const [completedLessons, setCompletedLessons] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userHasChosenYear, setUserHasChosenYear] = useState(false);

  // ----------------------------------------------------------------
  // REDIRECT IF NO TOKEN (without calling hooks conditionally)
  // ----------------------------------------------------------------
  // 1) Use an effect to navigate if no token:
  useEffect(() => {
    if (!access_token) {
      console.warn('Missing access token. Redirecting to login.');
      navigate('/StudentLogin');
    }
  }, [access_token, navigate]);

  // ----------------------------------------------------------------
  // DATA FETCHING FUNCTIONS
  // ----------------------------------------------------------------
  const fetchLastCompletedYear = async () => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/last_completed_year_for_subject/${SUBJECT_ID}`,
        { headers: { Authorization: `Bearer ${access_token}` }, timeout: 5000 }
      );
      if (
        response.status >= 200 && 
        response.status < 300 && 
        response.data?.year_id
      ) {
        // Only setSelectedYear if userHasChosenYear is still false
        // meaning the user hasn't manually changed it yet
        if (!userHasChosenYear) {
          setSelectedYear(response.data.year_id);
        }
      }
    } catch (err) {
      console.error('Error fetching last completed year:', err);
    }
  };
  

  const fetchAllCompletedLessons = async (year) => {
    const uniqueSubTopicIds = [
      ...new Set(subTopics.filter((st) => st.year === year).map((st) => st.id)),
    ];

    let allCompleted = [];
    for (let subTopicId of uniqueSubTopicIds) {
      const completedForSubtopic = await fetchCompletedLessons(subTopicId, year);
      allCompleted = [...allCompleted, ...completedForSubtopic];
    }
    setCompletedLessons(allCompleted);
    console.log('All Completed Lessons:', allCompleted);
  };

  const fetchCompletedLessons = async (subTopicId, year) => {
    if (typeof subTopicId !== 'number') return []; // skip 'shakespeare-plays'
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/completed_lessons/5/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data.map((lesson) => lesson.lesson_id);
      }
      return [];
    } catch (err) {
      console.error('Error fetching completed lessons:', err);
      return [];
    }
  };

  const fetchLessonsForYear = async (year) => {
    setLessonsBySubtopic({});

    const uniqueSubTopicIds = [
      ...new Set(subTopics.filter((st) => st.year === year).map((st) => st.id)),
    ];

    for (let subTopicId of uniqueSubTopicIds) {
      const lessons = await fetchLessonsBySubtopic(subTopicId, year);
      const completedIds = completedLessons;

      const updatedLessons = lessons.map((lesson) => ({
        ...lesson,
        is_completed: completedIds.includes(lesson.id),
      }));

      setLessonsBySubtopic((prev) => ({
        ...prev,
        [subTopicId]: updatedLessons,
      }));
    }
  };

  const fetchLessonsBySubtopic = async (subTopicId, year) => {
    if (typeof subTopicId !== 'number') return [];
    try {
      const response = await coreApi.get(
        `/lessons/lessons_by_subject/5/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data;
      }
      return [];
    } catch (err) {
      console.error('Error fetching lessons:', err);
      return [];
    }
  };

  const fetchDataForYear = async (year) => {
    try {
      setIsLoading(true);

      // Optionally fetch or skip this if you only want to do it once on mount
      // await fetchLastCompletedYear();

      await fetchAllCompletedLessons(year);
      await fetchLessonsForYear(year);

      setIsLoading(false);
      setError(null);
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
      console.error('fetchDataForYear error:', err);
    }
  };

  // ----------------------------------------------------------------
  // EFFECTS
  // ----------------------------------------------------------------
  useEffect(() => {
    (async () => {
      const year = await fetchLastCompletedYear();
      await fetchDataForYear(year);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If user changes year, refetch
    fetchDataForYear(selectedYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  // ----------------------------------------------------------------
  // RENDER HELPERS
  // ----------------------------------------------------------------
  const CustomMenuItem = ({ completed, children, ...other }) => {
    return (
      <MenuItem {...other}>
        {completed ? (
          <ListItemText
            primary={children}
            primaryTypographyProps={{
              style: { textDecoration: 'line-through', color: 'green' },
            }}
          />
        ) : (
          <ListItemText primary={children} />
        )}
      </MenuItem>
    );
  };

  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
   value={selectedYear}
   // once the user picks a new year, mark userHasChosenYear = true
   onChange={(e) => {
     setUserHasChosenYear(true);
     setSelectedYear(Number(e.target.value));
   }}
      >
        {[...Array(11).keys()].map((_, idx) => {
          const yr = idx + 1;
          return (
            <MenuItem key={yr} value={yr}>
              Year {yr}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const renderSubtopicCards = () => {
    const relevantSubTopics = subTopics.filter(
      (st) => st.year === Number(selectedYear)
    );

    return (
      <Grid container spacing={4}>
        {relevantSubTopics.map((subTopic, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia component="img" height="140" image={subTopic.image} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {subTopic.title}
                </Typography>

                {subTopic.isStaticLink ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(subTopic.externalLink, '_blank')}
                  >
                    Download Plays
                  </Button>
                ) : (
                  <FormControl fullWidth>
                    <Select
                      defaultValue=""
                      onChange={(event) => {
                        navigate(`/Classroom/${event.target.value}`);
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select a lesson
                      </MenuItem>

                      {isLoading && <MenuItem disabled>Loading...</MenuItem>}

                      {error && !lessonsBySubtopic[subTopic.id]?.length && (
                        <MenuItem disabled>Error: {error}</MenuItem>
                      )}

                      {lessonsBySubtopic[subTopic.id]?.map((lesson) => {
                        const isCompleted = completedLessons.includes(lesson.id);
                        return (
                          <CustomMenuItem
                            key={lesson.id}
                            value={lesson.id}
                            completed={isCompleted}
                          >
                            {lesson.title}
                          </CustomMenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  // ----------------------------------------------------------------
  // FINAL RENDER
  // ----------------------------------------------------------------

  // 2) Short-circuit: if no token, show nothing (we already triggered navigate in useEffect)
  if (!access_token) {
    return null;
  }

  // 3) Show a loading spinner if needed
  if (isLoading) {
    return <LoadingSpinner />;
  }

  // Otherwise, render the normal UI
  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate('/LessonSelect')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            English
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a year first.
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Don't be scared to drop down or move up years, there's value in
          practicing the basics, or going for a challenge!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <b>Year Selector</b>
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
          (Note: This will default to the year of the last English lesson you
          completed, but feel free to change it whenever!)
        </Typography>

        <Container maxWidth="lg">
          {renderYearDropdown()}
          <Box sx={{ mt: 3 }}>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              <b>Lesson Selector</b>
            </Typography>
            {renderSubtopicCards()}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default English;
