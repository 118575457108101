import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  CircularProgress,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import useApi from '../Utils/useApi';

const LeaderboardContainer = styled(Box)({
  backgroundColor: '#ffffff',          // Clean white background
  color: '#333333',                    // Dark text for readability
  padding: '20px',
  textAlign: 'left',                   // Left justified
  borderRadius: '10px',
  border: '2px solid #4caf50',         // Modern green border
  maxHeight: '70vh',
  overflowY: 'auto',
});

const LeaderboardEntry = styled(Typography)({
  fontSize: '1.2rem',
  margin: '10px 0',
  fontFamily: 'Roboto, sans-serif',    // Modern, kid-friendly font
  textAlign: 'left',
});

export default function GeoChaseLeaderboard({ open, onClose }) {
  const api = useApi();
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true);

  // Colours for 1st, 2nd, 3rd places
  const placementColors = ['#FFD700', '#C0C0C0', '#CD7F32']; // gold, silver, bronze

  useEffect(() => {
    if (open) {
      api.get('https://whale-app-6qyfq.ondigitalocean.app/api/geochase/geochase_leaderboard')
        .then(response => {
          if (Array.isArray(response.data)) {
            setLeaderboard(response.data);
          } else {
            setLeaderboard([]);
          }
        })
        .catch(error => {
          console.error('Error fetching GeoChase leaderboard:', error);
          setLeaderboard([]);
        })
        .finally(() => setLoading(false));
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          color: '#4caf50',
          textAlign: 'center',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 'bold',
        }}
      >
        GeoChase Leaderboard
      </DialogTitle>
      <DialogContent>
        <LeaderboardContainer>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : leaderboard.length === 0 ? (
            <Typography style={{ color: '#333333' }}>No scores available.</Typography>
          ) : (
            leaderboard.map((entry, index) => {
              // Determine placement color for the top three entries
              const placementColor = index < 3 ? placementColors[index] : '#333333';
              return (
                <LeaderboardEntry key={index}>
                  <span style={{ color: placementColor, fontWeight: 'bold' }}>
                    {index + 1}.
                  </span>{' '}
                  <strong>{entry.name}</strong>:{' '}
                  <span style={{ color: 'green' }}>{entry.wins} wins</span>,{' '}
                  <span style={{ color: 'red' }}>{entry.losses} losses</span>
                </LeaderboardEntry>
              );
            })
          )}
        </LeaderboardContainer>
        <Box textAlign="center" mt={2}>
          <Button onClick={onClose} variant="contained" style={{ backgroundColor: '#4caf50', color: '#ffffff' }}>
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
