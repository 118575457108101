import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi';
import LoadingSpinner from '../Utils/LoadingSpinner'; // <--- Ensure this path is correct
const SUBJECT_ID = 4;
const defaultTheme = createTheme();

function History() {
  const navigate = useNavigate();
  const api = useApi();
  const coreApi = useCoreApi();

  // ----------------------------------------------------------------
  // 1) AUTH & INITIAL SETUP
  // ----------------------------------------------------------------
  const access_token = localStorage.getItem('accessToken');
  const student_id = localStorage.getItem('user_id');

  // Parse year from localStorage or default to 1
  const parsedYear = parseInt(localStorage.getItem('student_year'), 10);
  const initialYear = Number.isNaN(parsedYear) ? 1 : parsedYear;

  // ----------------------------------------------------------------
  // 2) STATE
  // ----------------------------------------------------------------
  const [selectedYear, setSelectedYear] = useState(initialYear);

  const [subTopics, setSubTopics] = useState([
    // ... The entire subTopics array for History ...

    { id: 75, title: "A Journey Through Time", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-timetravel.avif" },
    { id: 76, title: "Daily Life - Then and Now", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-dailylife.avif" },
    { id: 77, title: "Introduction to Historical Figures", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-figures.avif" },
    { id: 78, title: "Festivals Through Time", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-festivals.avif" },
    { id: 79, title: "The Tale of the First Aeroplane Flight", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-firstflight.avif" },
    { id: 80, title: "The Great Fire of London", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-greatfire.avif" },
    { id: 81, title: "From Quills to Keyboards", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-quilltokeyboard.avif" },
    { id: 82, title: "Spotlight on Exploration", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-exploration.avif" },
    { id: 83, title: "Empowering Women in History", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-empoweringwomen.avif" },
    { id: 84, title: "Art Through the Ages", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-art.avif" },
    { id: 85, title: "Nurses who Made a Difference", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-nurses.avif" },
    { id: 213, title: "The Egyptians", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-theegyptians.avif" },

    { id: 86, title: "The World of Stone Age Britons", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-stoneage.avif" },
    { id: 87, title: "Travelling through the Bronze Age", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-bronzeage.avif" },
    { id: 88, title: "The Mighty Iron Age", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ironage.avif" },
    { id: 89, title: "Step into Roman Britain", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-romanempire.avif" },
    { id: 90, title: "Local Legends", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-locallegends.avif" },
    { id: 214, title: "William the Conqueror", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-williamtheconq.avif" },

    
    { id: 91, title: "Rome's Influence on Britain", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-romanempire.avif" },
    { id: 92, title: "Life as an Anglo-Saxon", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-anglosaxon.avif" },
    { id: 93, title: "Invasions and Battles", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-battles.avif" },
    { id: 94, title: "Local History Mysteries", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-locallegends.avif" },
    { id: 95, title: "Beyond 1066", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-beyond1066.avif" },
    { id: 131, title: "Titanic: The Unsinkable Ship and Its Fateful Journey", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-titanic.avif" },
    
    { id: 96, title: "Monarchs and Their Power", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-monarchy.avif" },
    { id: 97, title: "From Crime to Leisure", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-crime.avif" },
    { id: 98, title: "Gifts from Greece & Rome", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-rome.avif" },
    { id: 99, title: "Changing Times in British History", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-britain.avif" },
    { id: 100, title: "Civilizations of the Past", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ancient.avif" },
    { id: 132, title: "World War I: Origins, Battles, and Aftermath", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ww1.avif" },
    
    { id: 101, title: "Glimpses of Ancient Greece", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-rome.avif" },
    { id: 102, title: "World Beyond Britain", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-theegyptians.avif" },
    { id: 103, title: "Mysteries of the Mayan Civilization", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-mayans.avif" },
    { id: 104, title: "Benin's Rich Heritage", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-benin.avif" },
    { id: 105, title: "Local History Chronicles", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-britain.avif" },
    { id: 133, title: "World War II: Rise of Dictators and Global Conflict", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ww2.avif" },
  
    { id: 113, title: "The Norman Impact", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-normanconquest.avif" },
    { id: 114, title: "Religious Britain", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-religeousbritain.avif" },
    { id: 115, title: "Royal Power Plays", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-royalpower.avif" },
    { id: 116, title: "Medieval Society and Economy", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-medieval.avif" },
    { id: 117, title: "Crises and Responses", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-crisis.avif" },
    { id: 118, title: "Wars and Territories", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-wars.avif" },
    { id: 134, title: "World War I Deep Dive: Trench Warfare, Technologies, and Treaties", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ww1trench.avif" },
    
    { id: 119, title: "Renaissance and Reformations", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-renaissance.avif" },
    { id: 120, title: "Elizabethan England", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-elizabethan.avif" },
    { id: 121, title: "Civil Wars and Interregnum", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-civilwar.avif" },
    { id: 122, title: "Britain’s Expansion", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-britain.avif" },
    { id: 123, title: "Changing Power and Governance", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-power.avif" },
    { id: 124, title: "Daily Life in Early Modern Britain", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-modernbritain.avif" },
    { id: 135, title: "World War II In-depth: Strategy, Home Front, and Global Impact", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ww22.avif" },
    
    { id: 125, title: "Enlightened Thinking", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-EnlightenedThinking.avif" },
    { id: 126, title: "Colonialism and Slavery", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ColonialismandSlavery.avif" },
    { id: 127, title: "Wars for Dominance", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-WarsforDominance.avif" },
    { id: 128, title: "Industrial and Social Revolution", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-Industrial%20and%20Social%20Revolution.avif" },
    { id: 129, title: "Empire's Reach", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-EmpiresReach.avif" },
    { id: 130, title: "Victorian Britain", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-VictorianBritain.avif" },
   
    { id: 109, title: "Renaissance, Reformation, and the Elizabethan Era (1509-1745)", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-renaissance.avif" },   
    { id: 136, title: "The Tudors: Power, Politics, and Reformation", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-tudors.avif" },
    { id: 137, title: "Industrial Britain: Technological Revolution and Social Change (1750-1900)", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-Industrial%20and%20Social%20Revolution.avif" },
    { id: 138, title: "The French Revolution: Causes, Courses, and Consequences", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-frenchrevolution.avif" },
    { id: 139, title: "A Thematic Study: Religion and Society in Britain (from Roman Britain to the present day)", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-religeousbritain.avif" },
    { id: 140, title: "Historic Environment: Exploring Londons East End during the Victorian Era", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-britain.avif" },
    
    { id: 141, title: "The Cold War Era: Tensions, Confrontations, and Resolutions", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-coldwar.avif" },
    { id: 142, title: "Decolonisation and the End of Empires: 20th Century World", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-EmpiresReach.avif" },
    { id: 143, title: "Britains Role in the Slave Trade and Abolition Movements", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-slave.avif" },
    { id: 144, title: "A Thematic Study: Medical Advances Through the Ages", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-medicaladvances.avif" },
    { id: 145, title: "Historic Environment: The Battlefields of World War I", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ww1.avif" },
    { id: 110, title: "Revolution and Reform: Britain (1745-1901)", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-royalpower.avif" },
    { id: 111, title: "World War, Welfare, and Britain's Modern Identity (1901-Present)", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-ww1trench.avif" },
    { id: 112, title: "Empires and Global Interconnections: The Rise and Fall of Qing Dynasty & Russian Empires", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/history-qing.avif" },

  ]);

  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  const [completedLessons, setCompletedLessons] = useState([]);

  // Single loading & error states
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  //has the user selected a year
   const [userHasChosenYear, setUserHasChosenYear] = useState(false);

  // ----------------------------------------------------------------
  // 3) AXIOS INTERCEPTOR FOR 401
  // ----------------------------------------------------------------
  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response && err.response.status === 401) {
        navigate('/StudentLogin');
      }
      return Promise.reject(err);
    }
  );

  // ----------------------------------------------------------------
  // 4) REDIRECT IF NO TOKEN (USE EFFECT)
  // ----------------------------------------------------------------
  useEffect(() => {
    if (!access_token) {
      console.warn('Missing access token. Redirecting to login.');
      navigate('/StudentLogin');
    }
  }, [access_token, navigate]);

  // ----------------------------------------------------------------
  // 5) DATA FETCHING (subjectID = 4 for History)
  // ----------------------------------------------------------------

  // 5A) Fetch the last completed year for History (4)
  const fetchLastCompletedYear = async () => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/last_completed_year_for_subject/${SUBJECT_ID}`,
        { headers: { Authorization: `Bearer ${access_token}` }, timeout: 5000 }
      );
      if (
        response.status >= 200 && 
        response.status < 300 && 
        response.data?.year_id
      ) {
        // Only setSelectedYear if userHasChosenYear is still false
        // meaning the user hasn't manually changed it yet
        if (!userHasChosenYear) {
          setSelectedYear(response.data.year_id);
        }
      }
    } catch (err) {
      console.error('Error fetching last completed year:', err);
    }
  };
  

  // 5B) Fetch “completed lessons” for subtopics in the chosen year
  const fetchAllCompletedLessons = async (year) => {
    const uniqueSubTopics = [
      ...new Set(subTopics.filter((st) => st.year === Number(year)).map((st) => st.id)),
    ];

    let allCompleted = [];
    for (let subTopicId of uniqueSubTopics) {
      const theseCompleted = await fetchCompletedLessons(subTopicId, year);
      allCompleted = [...allCompleted, ...theseCompleted];
    }
    setCompletedLessons(allCompleted);
    console.log('All Completed Lessons:', allCompleted);
  };

  const fetchCompletedLessons = async (subTopicId, year) => {
    if (typeof subTopicId !== 'number') return []; // Skip non-numeric IDs
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/completed_lessons/4/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data.map((lesson) => lesson.lesson_id);
      }
      return [];
    } catch (err) {
      console.error('Error fetching completed lessons:', err);
      return [];
    }
  };

  // 5C) Fetch lessons for each subtopic in the chosen year
  const fetchLessonsForYear = async (year) => {
    setLessonsBySubtopic({});

    const uniqueSubTopics = [
      ...new Set(subTopics.filter((st) => st.year === Number(year)).map((st) => st.id)),
    ];

    for (let subTopicId of uniqueSubTopics) {
      const lessons = await fetchLessonsBySubtopic(subTopicId, year);
      const completedIds = completedLessons;
      const updatedLessons = lessons.map((lesson) => ({
        ...lesson,
        is_completed: completedIds.includes(lesson.id),
      }));
      setLessonsBySubtopic((prev) => ({ ...prev, [subTopicId]: updatedLessons }));
    }
  };

  const fetchLessonsBySubtopic = async (subTopicId, year) => {
    if (typeof subTopicId !== 'number') return []; // skip if not numeric
    try {
      const response = await coreApi.get(
        `/lessons/lessons_by_subject/4/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        // Originally you were filtering only is_completed === true
        // If you want that logic, do:
        //   return response.data.filter((l) => l.is_completed);
        // Otherwise, return all lessons:
        return response.data;
      }
      return [];
    } catch (err) {
      console.error('Error fetching lessons:', err);
      return [];
    }
  };

  // 5D) Master fetch function
  const fetchAllData = async (year) => {
    setIsLoading(true);
    setError(null);
    try {
      // 1) Possibly fetch last completed year
      await fetchLastCompletedYear();

      // 2) Then fetch all completed lessons
      await fetchAllCompletedLessons(year);

      // 3) Then fetch lessons
      await fetchLessonsForYear(year);
    } catch (err) {
      console.error('fetchAllData error:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------------------------------------------------
  // 6) EFFECTS
  // ----------------------------------------------------------------
  useEffect(() => {
    // On mount, fetch everything for the initial year
    (async () => {
      await fetchAllData(selectedYear);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If user changes the year, refetch
    fetchAllData(selectedYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  // ----------------------------------------------------------------
  // 7) RENDER HELPERS
  // ----------------------------------------------------------------
  const CustomMenuItem = ({ completed, children, ...other }) => (
    <MenuItem {...other}>
      {completed ? (
        <ListItemText
          primary={children}
          primaryTypographyProps={{
            style: { textDecoration: 'line-through', color: 'green' },
          }}
        />
      ) : (
        <ListItemText primary={children} />
      )}
    </MenuItem>
  );

  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
              value={selectedYear}
              // once the user picks a new year, mark userHasChosenYear = true
              onChange={(e) => {
                setUserHasChosenYear(true);
                setSelectedYear(Number(e.target.value));
              }}
      >
        {[...Array(11).keys()].map((_, idx) => {
          const yearVal = idx + 1;
          return (
            <MenuItem key={yearVal} value={yearVal}>
              Year {yearVal}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const renderSubtopicCards = () => {
    const relevantSubTopics = subTopics.filter(
      (st) => st.year === Number(selectedYear)
    );

    return (
      <Grid container spacing={4}>
        {relevantSubTopics.map((subTopic, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia component="img" height="140" image={subTopic.image} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {subTopic.title}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    defaultValue=""
                    onChange={(event) => navigate(`/Classroom/${event.target.value}`)}
                  >
                    <MenuItem value="" disabled>
                      Select a lesson
                    </MenuItem>

                    {isLoading && <MenuItem disabled>Loading...</MenuItem>}
                    {error && !lessonsBySubtopic[subTopic.id]?.length && (
                      <MenuItem disabled>Error: {error}</MenuItem>
                    )}

                    {lessonsBySubtopic[subTopic.id]?.map((lesson) => {
                      const isCompleted = completedLessons.includes(lesson.id);
                      return (
                        <CustomMenuItem
                          key={lesson.id}
                          value={lesson.id}
                          completed={isCompleted}
                        >
                          {lesson.title}
                        </CustomMenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  // ----------------------------------------------------------------
  // 8) FINAL RENDER
  // ----------------------------------------------------------------

  // If no token, short-circuit (useEffect above tries to redirect)
  if (!access_token) {
    return null;
  }

  // If loading, show spinner
  if (isLoading) {
    return <LoadingSpinner />;
  }

  // Otherwise, normal UI
  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate('/LessonSelect')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            History
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a year first.
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Don't be scared to drop down or move up years, there's value in
          practicing the basics, or going for a challenge!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <b>Year Selector</b>
        </Typography>

        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
          (Note: This will default to the year of the last History lesson you
          completed, but feel free to change it whenever!)
        </Typography>

        <Container maxWidth="lg">
          {renderYearDropdown()}
          <Box sx={{ mt: 3 }}>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              <b>Lesson Selector</b>
            </Typography>
            {renderSubtopicCards()}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default History;
