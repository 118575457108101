import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function ProtectedRoute({ children }) {
  const location = useLocation();
  const [isValidToken, setIsValidToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectReason, setRedirectReason] = useState('');


  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    if (token) {
 // Make a request to the backend to validate the token
 axios.get('https://rl-core-app-kpx3b.ondigitalocean.app/user/student_validate_token', {
  headers: {
    Authorization: `Bearer ${token}`,
  },
})
.then(response => {
  if (response.data.msg !== "Token is valid") {
    setRedirectReason('Invalid token.');
    setIsValidToken(false);
  } else if (!response.data.hasActiveSubscription) {
    setRedirectReason('You need an active subscription to access this content.');
    setIsValidToken(false);
  } else {
    setIsValidToken(true);
  }
  setIsLoading(false);
})
.catch(error => {
  // If there was an error (like a 401 response), the token is invalid
  setRedirectReason('Please log in to continue.');
  setIsValidToken(false);
  setIsLoading(false);
});
} else {
setRedirectReason('Please log in to continue.');
setIsValidToken(false);
setIsLoading(false);
    }
}, []);


  if (isLoading) {
    return null; // Or a loading spinner, etc.
  } else {
    return isValidToken ? children : <Navigate to="/StudentLogin" state={{ from: location, reason: redirectReason }} />;

  }
}

export default ProtectedRoute;
