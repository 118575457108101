import React, { useState, useEffect, Suspense } from 'react';
import styles from './ChatButton.module.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ChatApp from './ChatApp';

const ChatButton = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false); // Tracks if the button is minimized
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isChatEnabled, setIsChatEnabled] = useState(false);

  // Check if chat is enabled
  const checkChatEnabled = () => {
    const token = localStorage.getItem('accessToken');
    const chatEnabled = localStorage.getItem('chat_enabled') === 'true';
    return token && chatEnabled;
  };

  useEffect(() => {
    setIsChatEnabled(checkChatEnabled());

    const handleLoginStateChange = () => {
      setIsChatEnabled(checkChatEnabled());
    };

    window.addEventListener('loginStateChange', handleLoginStateChange);

    return () => {
      window.removeEventListener('loginStateChange', handleLoginStateChange);
    };
  }, []);

  // Function to handle closing the snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  // Toggle the chat modal or show a warning if chat is disabled
  const toggleChat = () => {
    if (isChatEnabled) {
      setIsChatOpen((prevState) => !prevState);
    } else {
      setSnackbar({
        open: true,
        message: 'Login Required, or Chat disabled in Parent Dashboard.',
        severity: 'warning',
      });
    }
  };

  // Minimize button behavior
  const toggleMinimize = () => {
    setIsMinimized((prev) => !prev);
  };

  return (
    <>
      {!isMinimized ? (
        !isChatOpen && (
          <div className={styles.chatButtonContainer}>
            <button
              onClick={toggleChat}
              className={styles.chatButton}
              aria-label={isChatEnabled ? 'Open Chat' : 'Chat Disabled'}
              title={isChatEnabled ? 'Chat' : 'Login Required, or Chat disabled in Parent Dashboard.'}
            >
              <img
                src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/RocketChatIcon.png"
                alt="Chat Icon"
                className={styles.chatIcon}
              />
            </button>
            <button className={styles.minimizeButton} onClick={toggleMinimize} title="Minimize Chat">
              ❌
            </button>
          </div>
        )
      ) : (
        <div className={styles.miniChatButton} onClick={toggleMinimize} title="Show Chat">
          💬
        </div>
      )}

      {isChatOpen && (
        <Suspense fallback={<div>Loading Chat...</div>}>
          <ChatApp closeChat={() => setIsChatOpen(false)} />
        </Suspense>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ChatButton;
