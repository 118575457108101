import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useApi from './useApi';

const FlaggedQuestions = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    const fetchFlaggedQuestions = async () => {
      try {
        const response = await api.get('/quiz/flagged_questions');
        setQuestions(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching flagged questions:', error);
        setLoading(false);
      }
    };

    fetchFlaggedQuestions();
  }, []);

  if (loading) {
    return <div>Loading flagged questions...</div>;
  }

  return (
    <div>
      <h1>Flagged Questions</h1>
      {questions.map((question) => (
        <div key={question.question_id} className="flagged-question">
          <h3>{question.question_text}</h3>
          <p><strong>Answer:</strong> {question.answer}</p>
          {question.options && (
            <div>
              <strong>Options:</strong>
              <ul>
                {question.options.map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
            </div>
          )}
          <p><strong>Verification Result:</strong> {question.verification_result}</p>
          <p><strong>Explanation:</strong> {question.explanation}</p>
          {/* Link to QuestionAdmin.js for editing */}
          <Link to={`/admin/question/${question.question_id}`}>
            Edit Question
          </Link>
        </div>
      ))}
    </div>
  );
};

export default FlaggedQuestions;
