import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../images/Logo.png';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Box,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  // Single breakpoint at 900px => anything <= 900px gets the hamburger menu
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery('(max-width: 900px)');

  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    setLoggedIn(!!userId);
  }, [loggedIn]);

  const handleLogout = () => {
    localStorage.removeItem('user_id');
    localStorage.removeItem('accessToken');
    setLoggedIn(false);
  };

  // Menu states
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);

  const [loginAnchorEl, setLoginAnchorEl] = useState(null);
  const loginOpen = Boolean(loginAnchorEl);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleLoginClick = (event) => {
    setLoginAnchorEl(event.currentTarget);
  };
  const handleLoginClose = () => {
    setLoginAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#4a90e2' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        
        {/* Logo */}
        <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
          <Link to="/">
            <img
              src={Logo}
              alt="Logo"
              style={{
                height: isMobileOrTablet ? '60px' : '80px',
                width: 'auto',
                maxWidth: '100%',
                transition: 'height 0.3s ease-in-out',
              }}
            />
          </Link>
        </Box>

        {isMobileOrTablet ? (
          /* ----------- MOBILE/TABLET NAV (<= 900px) ----------- */
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </IconButton>

            {/* Slide-out Menu */}
            <Menu
              id="mobile-menu"
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'mobile-menu',
              }}
            >
              {/* All nav links in the mobile menu */}
              <MenuItem component={Link} to="/" onClick={handleMenuClose}>
                Home
              </MenuItem>
              <MenuItem component={Link} to="/Register" onClick={handleMenuClose}>
                Free Sign-Up
              </MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/ParentDashboard">
                Parent Dashboard
              </MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/StudentDashboard">
                Student Dashboard
              </MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/ParentLogin">
                Parent Login
              </MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/StudentLogin">
                Student Login
              </MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/LessonSelect">
                To the Classroom!
              </MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/rocket-arcade">
                Rocket Arcade
              </MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/Assessments">
                Assessments
              </MenuItem>
            </Menu>
          </>
        ) : (
          /* ----------- DESKTOP NAV (> 900px) ----------- */
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // Removed gap, so they stay more compact
              flexGrow: 1,
              justifyContent: 'flex-end',
              // If you want a very small gap, you can do: columnGap: '8px',
            }}
          >
            {/* Free Sign-Up */}
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/Register"
              sx={{
                whiteSpace: 'nowrap',
                // Slightly smaller clamp range, less horizontal padding
                fontSize: 'clamp(0.7rem, 1.2vw, 0.95rem)',
                fontWeight: 'bold',
                borderRadius: '20px',
                px: 2, // narrower padding
                py: 1,
                textTransform: 'none',
                boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
                mr: 1, // small right margin
              }}
            >
              Free Sign-Up
            </Button>

            {/* Home */}
            <Button
              color="inherit"
              component={Link}
              to="/"
              sx={{
                whiteSpace: 'nowrap',
                fontSize: 'clamp(0.7rem, 1.2vw, 0.95rem)',
                mr: 1,
              }}
            >
              Home
            </Button>

            {/* Parent Forum */}
            <Button
              color="inherit"
              component={Link}
              to="/forum"
              sx={{
                whiteSpace: 'nowrap',
                fontSize: 'clamp(0.7rem, 1.2vw, 0.95rem)',
                mr: 1,
              }}
            >
              Parent Forum
            </Button>

            {/* Student Assessments */}
            <Button
              color="inherit"
              component={Link}
              to="/Assessments"
              sx={{
                whiteSpace: 'nowrap',
                fontSize: 'clamp(0.7rem, 1.2vw, 0.95rem)',
                mr: 1,
              }}
            >
              Assessments
            </Button>

            {/* Dashboards Menu */}
            <Button
              id="dashboard-menu"
              aria-controls="dashboard-menu"
              aria-haspopup="true"
              aria-expanded={menuOpen ? 'true' : undefined}
              onClick={handleMenuClick}
              color="inherit"
              sx={{
                whiteSpace: 'nowrap',
                fontSize: 'clamp(0.7rem, 1.2vw, 0.95rem)',
                mr: 1,
              }}
            >
              Dashboards
            </Button>
            <Menu
              id="dashboard-menu"
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'dashboard-menu',
              }}
            >
              <MenuItem onClick={handleMenuClose} component={Link} to="/ParentDashboard">
                Parent Dashboard
              </MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/StudentDashboard">
                Student Dashboard
              </MenuItem>
            </Menu>

            {/* Login Menu */}
            <Button
              id="login-menu"
              aria-controls="login-menu"
              aria-haspopup="true"
              aria-expanded={loginOpen ? 'true' : undefined}
              onClick={handleLoginClick}
              color="inherit"
              sx={{
                whiteSpace: 'nowrap',
                fontSize: 'clamp(0.7rem, 1.2vw, 0.95rem)',
                mr: 1,
              }}
            >
              Login
            </Button>
            <Menu
              id="login-menu"
              anchorEl={loginAnchorEl}
              open={loginOpen}
              onClose={handleLoginClose}
              MenuListProps={{
                'aria-labelledby': 'login-menu',
              }}
            >
              <MenuItem onClick={handleLoginClose} component={Link} to="/ParentLogin">
                Parent Login
              </MenuItem>
              <MenuItem onClick={handleLoginClose} component={Link} to="/StudentLogin">
                Student Login
              </MenuItem>
            </Menu>

            {/* Classroom (shorter label) */}
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/LessonSelect"
              sx={{
                whiteSpace: 'nowrap',
                fontSize: 'clamp(0.7rem, 1.2vw, 0.95rem)',
                mr: 1,
              }}
            >
              Classroom
            </Button>

            {/* Arcade (red) */}
            <Button
              variant="contained"
              onClick={() => navigate('/rocket-arcade')}
              sx={{
                whiteSpace: 'nowrap',
                fontSize: 'clamp(0.7rem, 1.2vw, 0.95rem)',
                backgroundColor: 'red',
                color: '#fff',
                '&:hover': {
                  backgroundColor: 'darkred',
                },
              }}
            >
              Arcade
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
