import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import './ChatApp.css'; // Add custom styles for the chat UI

const ChatApp = ({ closeChat }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const chatWindowRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const socket = io('https://whale-app-6qyfq.ondigitalocean.app', {
      auth: {
        token: `Bearer ${token}`,
      },
    });

    // Fetch recent chat history on component mount
    const fetchChatHistory = async () => {
      try {
        const response = await fetch('https://whale-app-6qyfq.ondigitalocean.app/get-recent-messages', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setMessages(data);
        scrollToBottom(); // Initial scroll to the bottom on load
      } catch (error) {
        console.error('Error loading chat history:', error);
      }
    };

    fetchChatHistory(); // Call the function to load chat history

    // Listen for incoming messages
    socket.on('receive-message', (msg) => {
      setMessages((prevMessages) => {
        const shouldScroll =
          chatWindowRef.current.scrollHeight - chatWindowRef.current.scrollTop ===
          chatWindowRef.current.clientHeight;
        
        const newMessages = [...prevMessages, msg];

        if (shouldScroll) {
          // Scroll only if user was already at the bottom
          setTimeout(scrollToBottom, 100);
        }

        return newMessages;
      });
    });

    // Clean up the socket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  const sendMessage = () => {
    if (input.trim()) {
      const token = localStorage.getItem('accessToken');
      const msg = {
        message: input,
        userId: localStorage.getItem('user_id'),
        timestamp: new Date().toISOString(),
      };
      const socket = io('https://whale-app-6qyfq.ondigitalocean.app/', {
        auth: {
          token: `Bearer ${token}`,
        },
      });
      socket.emit('send-message', msg);
      setInput('');
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <button onClick={closeChat} className="chat-close-button">X</button>
        <h4>Chat</h4>
      </div>
      <div className="chat-window" ref={chatWindowRef}>
        {messages.map((msg, index) => (
          <div key={index} className="chat-message">
            <strong>{msg.username}:</strong> {msg.message}
            <div className="chat-timestamp">{new Date(msg.timestamp).toLocaleTimeString()}</div>
          </div>
        ))}
      </div>
      <div className="chat-input-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
          className="chat-input"
        />
        <button onClick={sendMessage} className="chat-send-button">Send</button>
      </div>
    </div>
  );
};

export default ChatApp;
