import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Button,
} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi';
import LoadingSpinner from '../Utils/LoadingSpinner';

// The subject ID for Science might be 2 (based on your notes).
const SUBJECT_ID = 2;

const defaultTheme = createTheme();

function Science() {
  const navigate = useNavigate();
  const api = useApi();
  const coreApi = useCoreApi();

  // ----------------------------------------------------------------
  // 1) AUTH & INITIAL SETUP
  // ----------------------------------------------------------------
  const access_token = localStorage.getItem('accessToken');
  const student_id = localStorage.getItem('user_id');

  // Parse the student's year from localStorage or default to 1 if invalid
  const parsedYear = parseInt(localStorage.getItem('student_year'), 10);
  const initialYear = Number.isNaN(parsedYear) ? 1 : parsedYear;

  // ----------------------------------------------------------------
  // 2) STATE
  // ----------------------------------------------------------------
  const [selectedYear, setSelectedYear] = useState(initialYear);

  // Hard-coded subtopics for Science:
  const [subTopics, setSubTopics] = useState([
    // Update with your actual science subtopics if you have them
    // Example:
    { id: 31, title: "Plants", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-plants.avif" },
    { id: 32, title: "Animals, including humans", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-animals.avif" },
    { id: 33, title: "Everyday materials", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-materials.avif" },
    { id: 34, title: "Seasonal changes", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-seasonal.avif" },
    { id: 35, title: "Living things and their habitats", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-livingthings.avif" },
    { id: 36, title: "Plants", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-plants.avif" },
    { id: 37, title: "Animals, including humans", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-animals.avif" },
    { id: 38, title: "Uses of everyday materials", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-materials.avif" },
    { id: 39, title: "Plants", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-plants.avif" },
    { id: 40, title: "Animals, including humans", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-animals.avif" },
    { id: 41, title: "Rocks", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-rocks.avif" },
    { id: 42, title: "Light", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-light.avif" },
    { id: 43, title: "Forces and Magnets", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-magnets.avif" },
    { id: 44, title: "Living things and their habitats", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-livingthings.avif" },
    { id: 45, title: "Animals, including humans", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-animals.avif" },
    { id: 46, title: "States of matter", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-statesofmatter.avif" },
    { id: 47, title: "Sound", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-sound.avif" },
    { id: 48, title: "Electricity", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-electricity.avif" },
    { id: 49, title: "Living things and their habitats", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-livingthings.avif" },
    { id: 50, title: "Animals, including humans", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-animals.avif" },
    { id: 51, title: "Properties and changes of materials", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-materials.avif" },
    { id: 52, title: "Earth and space", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-earthandspace.avif" },
    { id: 53, title: "Forces", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-magnets.avif" },
    { id: 54, title: "Living things and their habitats", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-livingthings.avif" },
    { id: 55, title: "Animals including humans", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-animals.avif" },
    { id: 56, title: "Evolution and inheritance", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-evolution.avif" },
    { id: 57, title: "Light", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-light.avif" },
    { id: 58, title: "Electricity", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-electricity.avif" },
    { id: 7, title: "Biology", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-biology.avif" },
    { id: 8, title: "Chemistry", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-chemistry.avif" },
    { id: 9, title: "Physics", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-physics.avif" },
    { id: 7, title: "Biology", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-biology.avif" },
    { id: 8, title: "Chemistry", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-chemistry.avif" },
    { id: 9, title: "Physics", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-physics.avif" },
    { id: 7, title: "Biology", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-biology.avif" },
    { id: 8, title: "Chemistry", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-chemistry.avif" },
    { id: 9, title: "Physics", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-physics.avif" },
    { id: 7, title: "Biology", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-biology.avif" },
    { id: 8, title: "Chemistry", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-chemistry.avif" },
    { id: 9, title: "Physics", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-physics.avif" },
    { id: 7, title: "Biology", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-biology.avif" },
    { id: 8, title: "Chemistry", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-chemistry.avif" },
    { id: 9, title: "Physics", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/science-physics.avif" },

    // ... more objects ...
  ]);

  // lessonsBySubtopic => { subTopicId: [ {id, title, ...} ] }
  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  // array of completed lesson IDs
  const [completedLessons, setCompletedLessons] = useState([]);
  // single isLoading & error states
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // track if user has manually changed the year
  const [userHasChosenYear, setUserHasChosenYear] = useState(false);

  // ----------------------------------------------------------------
  // 3) AXIOS INTERCEPTOR FOR 401
  // ----------------------------------------------------------------
  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response && err.response.status === 401) {
        navigate('/StudentLogin');
      }
      return Promise.reject(err);
    }
  );

  // ----------------------------------------------------------------
  // 4) REDIRECT IF NO TOKEN (USE EFFECT)
  // ----------------------------------------------------------------
  useEffect(() => {
    if (!access_token) {
      console.warn('Missing access token. Redirecting to login.');
      navigate('/StudentLogin');
    }
  }, [access_token, navigate]);

  // ----------------------------------------------------------------
  // 5) DATA FETCHING (subjectID = 2 for Science)
  // ----------------------------------------------------------------

  // 5A) Fetch "last completed year" for Science
  const fetchLastCompletedYear = async () => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/last_completed_year_for_subject/${SUBJECT_ID}`,
        { headers: { Authorization: `Bearer ${access_token}` }, timeout: 5000 }
      );
      if (response.status >= 200 && response.status < 300 && response.data?.year_id) {
        // Only set if the user hasn't already manually changed the year
        if (!userHasChosenYear) {
          setSelectedYear(response.data.year_id);
        }
      }
    } catch (err) {
      console.error('Error fetching last completed year:', err);
      // Not fatal; keep the local year if no year is found
    }
  };

  // 5B) Fetch completed lessons for subtopics in the chosen year
  const fetchAllCompletedLessons = async (year) => {
    const relevantSubTopics = subTopics
      .filter((st) => st.year === Number(year))
      .map((st) => st.id);

    let allCompleted = [];
    for (let subTopicId of new Set(relevantSubTopics)) {
      const theseCompleted = await fetchCompletedLessons(subTopicId, year);
      allCompleted = [...allCompleted, ...theseCompleted];
    }
    setCompletedLessons(allCompleted);
    console.log('All Completed Lessons:', allCompleted);
  };

  // Helper: fetch completed lessons for one subtopic
  const fetchCompletedLessons = async (subTopicId, year) => {
    // If subTopicId is not numeric, skip
    if (typeof subTopicId !== 'number') return [];
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/completed_lessons/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data.map((lesson) => lesson.lesson_id);
      }
    } catch (err) {
      console.error('Error fetching completed lessons:', err);
    }
    return [];
  };

  // 5C) Fetch lessons for each subtopic in the chosen year
  const fetchLessonsForYear = async (year) => {
    setLessonsBySubtopic({});
    const relevantSubTopics = subTopics
      .filter((st) => st.year === Number(year))
      .map((st) => st.id);

    for (let subTopicId of new Set(relevantSubTopics)) {
      const lessons = await fetchLessonsBySubtopic(subTopicId, year);
      const updatedLessons = lessons.map((lesson) => ({
        ...lesson,
        is_completed: completedLessons.includes(lesson.id),
      }));
      setLessonsBySubtopic((prev) => ({ ...prev, [subTopicId]: updatedLessons }));
    }
  };

  // Helper: fetch lessons by subtopic
  const fetchLessonsBySubtopic = async (subTopicId, year) => {
    if (typeof subTopicId !== 'number') return [];
    try {
      const response = await coreApi.get(
        `/lessons/lessons_by_subject/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        // Return all lessons. If you only want is_completed, do: .filter(…)
        return response.data;
      }
    } catch (err) {
      console.error('Error fetching lessons:', err);
    }
    return [];
  };

  // 5D) Master fetch function
  const fetchAllData = async (year) => {
    setIsLoading(true);
    setError(null);

    try {
      // 1) Possibly fetch last completed year
      await fetchLastCompletedYear();

      // 2) Then fetch completed lessons
      await fetchAllCompletedLessons(year);

      // 3) Finally fetch actual lesson data
      await fetchLessonsForYear(year);
    } catch (err) {
      console.error('fetchAllData error:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------------------------------------------------
  // 6) EFFECTS
  // ----------------------------------------------------------------

  // On mount, fetch data for the selectedYear
  useEffect(() => {
    (async () => {
      if (access_token) {
        await fetchAllData(selectedYear);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When user changes the year
  useEffect(() => {
    if (access_token) {
      fetchAllData(selectedYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  // ----------------------------------------------------------------
  // 7) RENDERING HELPERS
  // ----------------------------------------------------------------

  // If no token, short-circuit
  if (!access_token) {
    return null;
  }

  // If loading, show spinner
  if (isLoading) {
    return <LoadingSpinner />;
  }

  // A custom menu item for lessons
  const CustomMenuItem = ({ completed, children, ...props }) => (
    <MenuItem {...props}>
      {completed ? (
        <ListItemText
          primary={children}
          primaryTypographyProps={{
            style: { textDecoration: 'line-through', color: 'green' },
          }}
        />
      ) : (
        <ListItemText primary={children} />
      )}
    </MenuItem>
  );

  // Year dropdown
  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
        value={selectedYear}
        onChange={(e) => {
          // user picks a new year => override server
          setUserHasChosenYear(true);
          setSelectedYear(Number(e.target.value));
        }}
      >
        {[...Array(11).keys()].map((_, idx) => {
          const yr = idx + 1;
          return (
            <MenuItem key={yr} value={yr}>
              Year {yr}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  // Subtopic cards
  const renderSubtopicCards = () => {
    const relevantSubTopics = subTopics.filter(
      (st) => st.year === Number(selectedYear)
    );

    return (
      <Grid container spacing={4}>
        {relevantSubTopics.map((subTopic, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia component="img" height="140" image={subTopic.image} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {subTopic.title}
                </Typography>

                {/* If it's a special link, show a button or do something else */}
                {subTopic.isStaticLink ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/TimesTableQuiz')}
                  >
                    Go to Quiz
                  </Button>
                ) : (
                  <FormControl fullWidth>
                    <Select
                      defaultValue=""
                      onChange={(e) => navigate(`/Classroom/${e.target.value}`)}
                    >
                      <MenuItem value="" disabled>
                        Select a lesson
                      </MenuItem>

                      {error && !lessonsBySubtopic[subTopic.id]?.length && (
                        <MenuItem disabled>Error: {error}</MenuItem>
                      )}

                      {lessonsBySubtopic[subTopic.id]?.map((lesson) => {
                        const isCompleted = completedLessons.includes(lesson.id);
                        return (
                          <CustomMenuItem
                            key={lesson.id}
                            value={lesson.id}
                            completed={isCompleted}
                          >
                            {lesson.title}
                          </CustomMenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  // ----------------------------------------------------------------
  // 8) FINAL RENDER
  // ----------------------------------------------------------------
  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate('/LessonSelect')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Science
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a year first.
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Don't be scared to drop down or move up years, there's value in
          practicing the basics, or going for a challenge!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <b>Year Selector</b>
        </Typography>

        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
          (Note: This will default to the year of the last Science lesson you
          completed, but feel free to change it whenever!)
        </Typography>

        <Container maxWidth="lg">
          {renderYearDropdown()}
          <Box sx={{ mt: 3 }}>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              <b>Lesson Selector</b>
            </Typography>
            {renderSubtopicCards()}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Science;
