import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Button,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi';
import LoadingSpinner from '../Utils/LoadingSpinner';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SUBJECT_ID = 6;
const defaultTheme = createTheme();

function ICT() {
  const navigate = useNavigate();
  const api = useApi();
  const coreApi = useCoreApi();

  // ----------------------------------------------------------------
  // 1) AUTH & INITIAL SETUP
  // ----------------------------------------------------------------
  const access_token = localStorage.getItem('accessToken');
  const student_id = localStorage.getItem('user_id');

  // Attempt to parse a year from localStorage
  const parsedYear = parseInt(localStorage.getItem('student_year'), 10);
  const initialYear = Number.isNaN(parsedYear) ? 1 : parsedYear;

  // ----------------------------------------------------------------
  // 2) STATE
  // ----------------------------------------------------------------
  const [selectedYear, setSelectedYear] = useState(initialYear);

  // This will be false until we’ve definitively resolved what year the student should be on
  const [yearInitialized, setYearInitialized] = useState(false);

  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  const [completedLessons, setCompletedLessons] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Tracks if the user has explicitly chosen a year in the dropdown
  const [userHasChosenYear, setUserHasChosenYear] = useState(false);

  // Hard-coded subTopics (memoized)
  const subTopics = useMemo(
    () => [
      {id: 146, title: "Digital Discovery: Exploring Computers and Devices", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
      {id: 147, title: "My First Steps in Programming: Understanding Algorithms and Making Simple Programs", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
      {id: 148, title: "Magic of Logic: Predicting What Programs Will Do", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 149, title: "Digital Art and Storytelling: Creating and Organising Our Ideas", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
      {id: 150, title: "Safe Surfers: Using Technology Respectfully and Safely", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
      {id: 151, title: "Tech Detectives: Discovering More About Computers Around Us", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
      {id: 152, title: "Coding Quests: Taking Our Programs to the Next Level", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
      {id: 153, title: "Logic Labyrinths: Solving Puzzles and Challenges with Predictions", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 154, title: "Digital Storymasters: Advanced Digital Creation and Expression", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
      {id: 155, title: "Cyber Protectors: Becoming Digital Citizens and Advocates", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
      {id: 156, title: "Programming Pioneers: Designing and Debugging Our Own Programs", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
      {id: 157, title: "Code Crafters: Sequences, Choices, and Loops in Programs", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
      {id: 158, title: "Algorithm Architects: Understanding and Fixing Algorithms", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 159, title: "Net Navigators: Exploring the World of Computer Networks and the Internet", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
      {id: 160, title: "Search Savvy: Mastering Digital Searches and Evaluating Content", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalFootprint.avif" },
      {id: 161, title: "Digital Designers: Crafting Projects with Varied Software and Devices", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
      {id: 162, title: "Online Guardians: Safe and Responsible Tech Use", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
      {id: 163, title: "Advanced Algorithms: Diving Deeper into Program Design", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 164, title: "Coding Champions: Advanced Structures and Data Handling in Programs", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
      {id: 165, title: "Network Nomads: The Wider World of Networks and Their Uses", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
      {id: 166, title: "Digital Detectives: Advanced Search Techniques and Digital Evaluation", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalFootprint.avif" },
      {id: 167, title: "Creative Coders: Merging Art and Technology in Digital Projects", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
      {id: 168, title: "Data Dynamics: Exploring the Power of Data in the Digital World", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 169, title: "Cyber Stewards: Evolving Digital Safety and Online Etiquette", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
      {id: 170, title: "Mastering Modules: Developing Modular Programs and Software Components", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
      {id: 171, title: "Binary Boffins: Introduction to Binary and Data Representation", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Binary.avif" },
      {id: 172, title: "Web Wizards: Building Basic Web Pages and Understanding the Internets Structure", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
      {id: 173, title: "Algorithm Analysis: Evaluating Efficiency and Effectiveness", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 174, title: "Dynamic Designers: Advanced Multimedia Projects and Interactive Designs", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
      {id: 175, title: "Cloud Climbers: Introduction to Cloud Computing and its Advantages", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
      {id: 176, title: "Digital Citizens: The Broader Impacts of Technology on Society", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalFootprint.avif" },
      {id: 177, title: "Complex Coders: Exploring Advanced Programming Techniques", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
      {id: 178, title: "Database Dynamos: Introduction to Relational Databases and SQL", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
      {id: 179, title: "Network Navigators: Delving Deeper into Network Protocols and Services", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
      {id: 180, title: "Game Design Gurus: Foundations of Game Development and Logic", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 181, title: "Digital Ethics Explorers: Addressing the Ethical Implications of Technology", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalFootprint.avif" },
      {id: 182, title: "Cybersecurity Captains: Basic Principles of Digital Security and Threats", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
      {id: 183, title: "Future Innovators: Exploring Emerging Technologies and Trends", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalFootprint.avif" },
      {id: 184, title: "Computational Models: Designing and Evaluating Models of Real-world Problems", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 185, title: "Algorithm Architects: Understanding Key Algorithms and Logical Reasoning", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 186, title: "Polyglot Programmers: Exploring Multiple Programming Languages and Modular Design", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
      {id: 187, title: "Binary Basics and Boolean Logic: Foundations of Digital Number Systems and Logic Circuits", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Binary.avif" },
      {id: 188, title: "Inside the Machine: A Glimpse into Computer Hardware and Software", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
      {id: 189, title: "Digital Artistry: Creating and Repurposing Digital Artefacts", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
      {id: 190, title: "Cybersecurity Cadets: Responsible and Safe Use of Technology", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
      {id: 191, title: "Advanced Computational Models: Refining and Optimising Models for Complex Systems", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 192, title: "Algorithmic Masterminds: Delving Deeper into Algorithms and Enhanced Logical Reasoning", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 193, title: "Next-Level Programming: Advanced Language Features and Data Structures", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
      {id: 194, title: "Beyond Binary: Advanced Operations and Boolean Logic in Action", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Binary.avif" },
      {id: 195, title: "System Synergies: Exploring Advanced Computer Interactions", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
      {id: 196, title: "Digital Creations: Advanced Projects for Specific Audiences and Purposes", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
      {id: 197, title: "Cybersecurity Champions: Deepening Understanding of Online Security and Ethics", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
      {id: 198, title: "Complex Systems and Abstractions: Advanced Modeling and Real-World Applications", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 199, title: "Algorithmic Excellence: Mastery of Complex Algorithms and Evaluative Techniques", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 200, title: "Code Craftsmanship: Mastery in Programming and Advanced Data Management", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
      {id: 201, title: "Digital Number Wizardry: Dive into Advanced Binary Operations and Logic Circuits", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Binary.avif" },
      {id: 202, title: "Systems Unleashed: Delving into Computer Architectures and Advanced Processing Techniques", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
      {id: 203, title: "Creative Digital Mastery: Designing Sophisticated Digital Projects with a User-Centric Focus", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
      {id: 204, title: "Guardians of the Web: Advanced Cybersecurity Techniques and Digital Etiquette", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
      {id: 205, title: "GCSE Foundations: Revisiting Core Concepts in Computer Science and Digital Media", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
      {id: 206, title: "Problem-Solving Powerhouses: Advanced Analytic and Computational Techniques", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 207, title: "Digital Future Preparedness: Adapting to Technological Changes and Predicting Trends", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
      {id: 208, title: "Online Shield: Advanced Cybersecurity Protocols and Digital Etiquette", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
      {id: 209, title: "GCSE Mastery: Comprehensive Revision and Exam Techniques for Success", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
      {id: 210, title: "Beyond the Code: Advanced Projects in Computer Science and Digital Media", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
      {id: 211, title: "Think Like a Computer Scientist: Advanced Computational Thinking Challenges", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
      {id: 212, title: "The Digital Footprint: Advanced Digital Safety, Privacy, and Ethical Considerations", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalFootprint.avif" }
  
    ],
    []
  );

  // ----------------------------------------------------------------
  // 3) AXIOS INTERCEPTOR FOR 401
  // ----------------------------------------------------------------
  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response && err.response.status === 401) {
        navigate('/StudentLogin');
      }
      return Promise.reject(err);
    }
  );

  // ----------------------------------------------------------------
  // 4) REDIRECT IF NO TOKEN
  // ----------------------------------------------------------------
  useEffect(() => {
    if (!access_token) {
      console.warn('Missing access token. Redirecting to login.');
      navigate('/StudentLogin');
    }
  }, [access_token, navigate]);

  // ----------------------------------------------------------------
  // 5) DATA FETCHING (UTILITY FUNCTIONS)
  // ----------------------------------------------------------------

  // 5A) Fetch last completed year
  const fetchLastCompletedYear = async () => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/last_completed_year_for_subject/${SUBJECT_ID}`,
        { headers: { Authorization: `Bearer ${access_token}` }, timeout: 5000 }
      );

      if (response.status >= 200 && response.status < 300 && response.data?.year_id) {
        return response.data.year_id;
      }
    } catch (err) {
      console.error('Error fetching last completed year:', err);
    }
    return null;
  };

  // 5B) Fetch completed lessons for one subtopic
  const fetchCompletedLessons = async (subTopicId, year) => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/completed_lessons/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data.map((l) => l.lesson_id);
      }
    } catch (err) {
      console.error(`Error fetching completed lessons for ${subTopicId}:`, err);
    }
    return [];
  };

  // 5C) Fetch lessons for one subtopic
  const fetchLessonsBySubtopic = async (subTopicId, year) => {
    try {
      const response = await coreApi.get(
        `/lessons/lessons_by_subject/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      console.error(`Error fetching lessons for subtopic ${subTopicId}:`, err);
    }
    return [];
  };

  // ----------------------------------------------------------------
  // 6) MASTER FETCH (LESSONS & COMPLETED)
  // ----------------------------------------------------------------
  const fetchAllDataForYear = async (year) => {
    setIsLoading(true);
    setError(null);

    try {
      // Get relevant subtopics
      const relevantSubTopics = subTopics.filter((st) => st.year === year);

      // 1) Fetch all completed lesson IDs in parallel
      const completedPromises = relevantSubTopics.map((st) =>
        fetchCompletedLessons(st.id, year)
      );
      const completedArrays = await Promise.all(completedPromises);
      const allCompletedIds = completedArrays.flat();
      setCompletedLessons(allCompletedIds);

      // 2) Fetch all lesson data in parallel
      const lessonPromises = relevantSubTopics.map(async (st) => {
        const lessons = await fetchLessonsBySubtopic(st.id, year);
        return { subTopicId: st.id, lessons };
      });
      const lessonsArray = await Promise.all(lessonPromises);

      // 3) Combine into a map
      const newLessonsBySubtopic = {};
      for (let item of lessonsArray) {
        // Mark as completed if in the completed list
        const updated = item.lessons.map((lesson) => ({
          ...lesson,
          is_completed: allCompletedIds.includes(lesson.id),
        }));
        newLessonsBySubtopic[item.subTopicId] = updated;
      }

      setLessonsBySubtopic(newLessonsBySubtopic);
    } catch (err) {
      console.error('fetchAllDataForYear error:', err);
      setError('Error fetching lessons. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Simple function to retry fetch
  const retryFetch = () => {
    fetchAllDataForYear(selectedYear);
  };

  // ----------------------------------------------------------------
  // 7) EFFECTS
  // ----------------------------------------------------------------

  /**
   * Effect A: On initial mount, attempt to fetch last completed year (unless the user
   * has already chosen a year). Once done, we set yearInitialized = true.
   */
  useEffect(() => {
    if (!access_token) return;

    if (userHasChosenYear) {
      // The user has explicitly chosen a year; skip server check
      setYearInitialized(true);
      return;
    }

    let canceled = false;
    (async () => {
      const lastYear = await fetchLastCompletedYear();
      if (!canceled) {
        if (lastYear && lastYear !== selectedYear) {
          setSelectedYear(lastYear);
        }
        setYearInitialized(true);
      }
    })();

    return () => {
      canceled = true;
    };
  }, [access_token, userHasChosenYear, selectedYear]);

  /**
   * Effect B: Once the year is initialized and we have a final selectedYear,
   * fetch the data for that year.
   */
  useEffect(() => {
    if (!access_token || !yearInitialized) return;
    fetchAllDataForYear(selectedYear);
  }, [access_token, selectedYear, yearInitialized]);

  // ----------------------------------------------------------------
  // 8) RENDERING
  // ----------------------------------------------------------------

  // If no token, short-circuit
  if (!access_token) {
    return null;
  }

  // If we're still fetching, show spinner
  if (isLoading) {
    return <LoadingSpinner />;
  }

  // A custom menu item to show completed lessons (strikethrough)
  const CustomMenuItem = ({ completed, children, ...props }) => (
    <MenuItem {...props}>
      {completed ? (
        <ListItemText
          primary={children}
          primaryTypographyProps={{
            style: { textDecoration: 'line-through', color: 'green' },
          }}
        />
      ) : (
        <ListItemText primary={children} />
      )}
    </MenuItem>
  );

  // The dropdown to choose a year
  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
        value={selectedYear}
        onChange={(e) => {
          setUserHasChosenYear(true);
          setSelectedYear(Number(e.target.value));
        }}
      >
        {[...Array(11).keys()].map((_, idx) => {
          const yr = idx + 1;
          return (
            <MenuItem key={yr} value={yr}>
              Year {yr}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

// The main grid of subtopics
const renderSubtopicAccordions = () => {
  const relevantSubTopics = subTopics.filter((st) => st.year === Number(selectedYear));

  return (
    <Box>
      {relevantSubTopics.map((subTopic) => {
        const subLessons = lessonsBySubtopic[subTopic.id] || [];
        const noLessonsFound = subLessons.length === 0;

        return (
          <Accordion key={`${subTopic.id}-${subTopic.year}`} sx={{ mb: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <CardMedia
                  component="img"
                  image={subTopic.image}
                  alt="Subtopic thumbnail"
                  sx={{ width: 60, height: 60, borderRadius: 1, mr: 2 }}
                />
                <Typography variant="subtitle1" fontWeight="bold">
                  {subTopic.title}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {subTopic.isStaticLink ? (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => window.open(subTopic.externalLink, '_blank')}
                >
                  Go to resource
                </Button>
              ) : (
                <Box>
                  {noLessonsFound && (
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                      Oops, no lessons found.{' '}
                      <Button
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          retryFetch();
                        }}
                      >
                        Retry?
                      </Button>
                    </Typography>
                  )}

                  {(() => {
                    const seriesMap = {};
                    const standalone = [];

                    for (let lesson of subLessons) {
                      if (lesson.series_id) {
                        if (!seriesMap[lesson.series_id]) {
                          seriesMap[lesson.series_id] = [];
                        }
                        seriesMap[lesson.series_id].push(lesson);
                      } else {
                        standalone.push(lesson);
                      }
                    }

                    const rendered = [];

                    Object.values(seriesMap).forEach((seriesLessons) => {
                      const sorted = [...seriesLessons].sort(
                        (a, b) => a.series_order - b.series_order
                      );

                      rendered.push(
                        <Box
                          key={`series-${sorted[0].id}`}
                          sx={{
                            border: '2px dashed #888',
                            borderRadius: 2,
                            p: 1,
                            mb: 1,
                            backgroundColor: '#f0f4ff',
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{ mb: 1, color: '#333' }}
                          >
                            📚 Lesson Series: {sorted[0].title.split(' – ')[0]} Series
                          </Typography>

                          {sorted.map((lesson) => (
                            <Button
                              key={lesson.id}
                              fullWidth
                              variant="outlined"
                              sx={{
                                justifyContent: 'flex-start',
                                mb: 1,
                                textDecoration: lesson.is_completed ? 'line-through' : 'none',
                                color: lesson.is_completed ? 'green' : 'inherit',
                                textTransform: 'none',
                              }}
                              onClick={() => navigate(`/Classroom/${lesson.id}`)}
                            >
                              {lesson.title} (Part {lesson.series_order})
                            </Button>
                          ))}
                        </Box>
                      );
                    });

                    standalone.forEach((lesson) => {
                      rendered.push(
                        <Button
                          key={lesson.id}
                          fullWidth
                          variant="outlined"
                          sx={{
                            justifyContent: 'flex-start',
                            mb: 1,
                            textDecoration: lesson.is_completed ? 'line-through' : 'none',
                            color: lesson.is_completed ? 'green' : 'inherit',
                            textTransform: 'none',
                          }}
                          onClick={() => navigate(`/Classroom/${lesson.id}`)}
                        >
                          {lesson.title}
                        </Button>
                      );
                    });

                    return rendered;
                  })()}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};


  
  // Final return section
  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate('/LessonSelect')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            ICT
          </Typography>
        </Toolbar>
      </AppBar>
  
      <Box sx={{ bgcolor: '#f9fafb', pt: 8, pb: 6, minHeight: '100vh' }}>

        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a year first.
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Don't be scared to drop down or move up years, there's value in
          practicing the basics, or going for a challenge!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <b>Year Selector</b>
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
          (Note: This will default to the year of the last ICT lesson you completed,
          but feel free to change it whenever!)
        </Typography>
  
        <Container maxWidth="lg">
  <Box sx={{ backgroundColor: 'white', p: 4, borderRadius: 3, boxShadow: 3 }}>
    {renderYearDropdown()}
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" align="center" color="text.secondary" paragraph>
        <b>Lesson Selector</b>
      </Typography>
      {renderSubtopicAccordions()}
    </Box>
  </Box>
</Container>
      </Box>
    </ThemeProvider>
  );
}

export default ICT;
