// AssessmentResult.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Button } from '@mui/material';

const AssessmentResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { suggestedYearLevel } = location.state || {};

  if (!suggestedYearLevel) {
    // If no suggestedYearLevel is available, redirect or inform the user
    return (
      <div style={{ marginTop: '40px', textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>
          Sorry, we couldn't retrieve your assessment result.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/assessments')}
        >
          Take Assessment Again
        </Button>
      </div>
    );
  }

  const handleContinue = () => {
    // Navigate to the Classroom page
    navigate('/LessonSelect');
  };
  return (
    <div style={{ marginTop: '40px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Your Suggested Starting Point
      </Typography>
      <Typography variant="h6" gutterBottom>
        We recommend starting with Year {suggestedYearLevel}.
      </Typography>
      <Typography variant="body1" gutterBottom>
        This will help you build a strong foundation before moving on to more advanced topics.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleContinue}
        style={{ marginTop: '20px' }}
      >
        Continue to Lessons
      </Button>
    </div>
  );
};

export default AssessmentResult;
