import Phaser from 'phaser';

// Function to generate a random math problem and its answer
export function generateMathProblem(difficulty, operators = ['+', '-', '*', '/']) {
    let num1, num2, operator, question, answer;

    switch (difficulty) {
        case 'easy':
            num1 = Phaser.Math.Between(1, 10);
            num2 = Phaser.Math.Between(1, 10);
            break;
        case 'medium':
            num1 = Phaser.Math.Between(10, 50);
            num2 = Phaser.Math.Between(10, 50);
            break;
        case 'hard':
            num1 = Phaser.Math.Between(50, 100);
            num2 = Phaser.Math.Between(50, 100);
            break;
        default:
            num1 = Phaser.Math.Between(1, 10);
            num2 = Phaser.Math.Between(1, 10);
            break;
    }

    // Choose a random operator from the selected operators
    if (!operators || operators.length === 0) {
        operators = ['+', '-', '*', '/'];
    }
    operator = operators[Phaser.Math.Between(0, operators.length - 1)];

    // Adjust numbers for subtraction and division
    if (operator === '-') {
        // Ensure num1 >= num2 to avoid negative results
        if (num1 < num2) {
            [num1, num2] = [num2, num1];  // Swap num1 and num2
        }
    } else if (operator === '/') {
        // Ensure no division by zero and integer division
        if (num2 === 0) num2 = 1;  // Avoid division by zero
        num1 = num1 * num2; // Make num1 a multiple of num2
    }

    // Calculate the answer
    switch (operator) {
        case '+':
            answer = num1 + num2;
            break;
        case '-':
            answer = num1 - num2;
            break;
        case '*':
            answer = num1 * num2;
            break;
        case '/':
            answer = num1 / num2;
            break;
    }

    question = `${num1} ${operator === '*' ? 'x' : operator} ${num2}`;

    // Ensure the answer is a valid number
    if (isNaN(answer)) {
        console.error('Invalid answer generated:', num1, operator, num2, '=', answer);
        return generateMathProblem(difficulty, operators);  // Retry generating a valid problem
    }

    return { question, answer };
}

// Function to generate random incorrect math problems
export function generateRandomQuestions(correctAnswer, difficulty, operators = ['+', '-', '*', '/']) {
    let randomQuestions = [];
    while (randomQuestions.length < 2) {
        let num1, num2, operator, question, answer;

        switch (difficulty) {
            case 'easy':
                num1 = Phaser.Math.Between(1, 10);
                num2 = Phaser.Math.Between(1, 10);
                break;
            case 'medium':
                num1 = Phaser.Math.Between(10, 50);
                num2 = Phaser.Math.Between(10, 50);
                break;
            case 'hard':
                num1 = Phaser.Math.Between(50, 100);
                num2 = Phaser.Math.Between(50, 100);
                break;
            default:
                num1 = Phaser.Math.Between(1, 10);
                num2 = Phaser.Math.Between(1, 10);
                break;
        }

        // Choose a random operator from the selected operators
        if (!operators || operators.length === 0) {
            operators = ['+', '-', '*', '/'];
        }
        operator = operators[Phaser.Math.Between(0, operators.length - 1)];

        // Adjust numbers for subtraction and division
        if (operator === '-') {
            // Ensure num1 >= num2 to avoid negative results
            if (num1 < num2) {
                [num1, num2] = [num2, num1];  // Swap num1 and num2
            }
        } else if (operator === '/') {
            // Ensure no division by zero and integer division
            if (num2 === 0) num2 = 1;  // Avoid division by zero
            num1 = num1 * num2; // Make num1 a multiple of num2
        }

        // Calculate the answer
        switch (operator) {
            case '+':
                answer = num1 + num2;
                break;
            case '-':
                answer = num1 - num2;
                break;
            case '*':
                answer = num1 * num2;
                break;
            case '/':
                answer = num1 / num2;
                break;
        }

        // Avoid generating the same question or invalid answers
        if (answer !== correctAnswer && !isNaN(answer)) {
            question = `${num1} ${operator === '*' ? 'x' : operator} ${num2}`;
            randomQuestions.push(question);
        }
    }
    return randomQuestions;
}
