import * as React from 'react'; 
import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import PricingInfo from './PricingInfo';

// SAMPLE TESTIMONIALS: adjust as needed
const testimonials = [
  {
    name: "Tracy, home educator",
    quote: "Love Rocket learning, a brilliant platform and a great selection of subjects and topics. Engaging, varied and I love the way you can track progress as a parent. 5 star perfect for home education 😀",
  },
  {
    name: "Shelly, home educator",
    quote: "Really happy with this so far. A few questions at first and amazing customer support from Leon and the team to answer and offer solutions. The kids love it and are eager to do the lessons. 5 star ⭐",
  },
  {
    name: "Kaila, Age 12",
    quote: "I love the Rocket Arcade! And I've made some friends in the chat room",
  },
];

const theme = createTheme({
  typography: {
    htmlFontSize: 20,
    h1: { 
      fontSize: 'clamp(2rem, 5vw, 3rem)', 
      fontWeight: 'bold',
    },
    h5: { 
      fontSize: 'clamp(1rem, 2.5vw, 1.5rem)',
    },
    h3: { 
      fontSize: 'clamp(2rem, 2.5vw, 1.5rem)',
    },
    body1: {
      fontSize: '1.2rem', // Increased body text size
    },
  },
});

export default function HomePage() {
  const navigate = useNavigate();
  const [userType, setUserType] = useState(localStorage.getItem('usertype'));

  useEffect(() => {
    const handleStorageChange = () => {
      setUserType(localStorage.getItem('usertype'));
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleButtonClick = () => {
    if (userType === 'parent') {
      navigate('/ParentDashboard');
    } else if (userType === 'student') {
      navigate('/StudentDashboard');
    } else {
      navigate('/Register');
    }
  };

  // Contact button handler
  const handleContactUsClick = () => {
    navigate('/contactus');
  };

  // Main features
  const features = [
    {
      title: 'Engaging Lessons',
      description: 'Interactive modules that keep students excited and focused on learning.',
      image: 'https://websitecontent.ams3.cdn.digitaloceanspaces.com/EngagingLessons2.gif',
    },
    {
      title: 'Curriculum-Aligned',
      description: 'Content built to match the UK National Curriculum (covering Key Stages 1 to 4) from primary to GCSEs.',
      image: 'https://websitecontent.ams3.cdn.digitaloceanspaces.com/NationalCurriculum.png',
    },
    {
      title: '7 Core Subjects',
      description: 'A diverse range of essential subjects, carefully designed to support learners aged 5 to 16.',
      image: 'https://websitecontent.ams3.cdn.digitaloceanspaces.com/Chalkboard.png',
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Rocket Learning | Home Education Made Engaging</title>
        <meta 
          name="description" 
          content="The ultimate online home-educating platform, aligned with the UK National Curriculum. Interactive lessons, games, and progress tracking for your child." 
        />
      </Helmet>

      <CssBaseline />

      {/* Global Background Wrapper */}
      <Box sx={{ background: 'linear-gradient(135deg, #4a91e3, #4a91e3)', minHeight: '100vh' }}>
        
        {/* Thin Grey Bar with Contact Button */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgcolor: '#f2f2f2',
            py: 1,
            px: 3, 
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              textTransform: 'none',
              backgroundColor: '#007BFF',
              px: 3,
              py: 1, 
              borderRadius: 1, 
              '&:hover': { backgroundColor: '#0056b3' },
            }}
            onClick={handleContactUsClick}
          >
            Contact Us
          </Button>
        </Box>

        {/* Banner Section */}
        <Box
          component="img"
          src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/GeoChaseBannerRL.png"
          alt="GeoChase Banner"
          sx={{
            width: '100%',
            display: 'block',
            objectFit: 'cover',
          }}
        />

        {/* Hero Section */}
        <Box
          sx={{
            position: 'relative',
            height: '60vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            color: 'white',
            p: 4,
            background: "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('https://websitecontent.ams3.cdn.digitaloceanspaces.com/RLHeroImage.png') center/cover no-repeat",
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <Typography 
              variant="h1"
              sx={{ 
                textShadow: '-2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff',
              }}
            >
              Welcome to <span style={{ color: "#f2182c" }}>Rocket Learning</span>
            </Typography>
            <Typography 
              variant="h3" 
              sx={{ 
                mt: 2, 
                textShadow: '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff',
              }}
            >
              Empowering home-educating families.
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ mt: 4 }}
              onClick={handleButtonClick}
            >
              Start Your Free Trial
            </Button>
          </motion.div>
        </Box>

        {/* Down Scroll Indicator */}
        <motion.div
          animate={{ y: [0, 10, 0] }}
          transition={{ repeat: Infinity, duration: 1.5 }}
          style={{ textAlign: 'center', marginTop: -80 }}
        >
          <Typography variant="h5" sx={{ color: 'white' }}>
            Scroll to Learn More
          </Typography>
        </motion.div>

        {/* Title Section */}
        <Container maxWidth="lg" sx={{ textAlign: 'center', py: 8 }}>
          <Typography variant="h2" sx={{ color: 'white' }}>
            Why Home-Educating Families Love Rocket Learning
          </Typography>
        </Container>

        {/* Engaging Lessons Section */}
        <Container 
          maxWidth="lg" 
          sx={{ 
            py: 6, 
            my: 2, 
            background: 'linear-gradient(180deg, #619de2, #4a90e2)', 
            borderRadius: 2 
          }}
        >
          <Typography variant="h3" align="center" gutterBottom sx={{ color: 'white' }}>
            Engaging Lessons
          </Typography>
          <Typography variant="h5" align="center" paragraph sx={{ color: 'white' }}>
            Explore thousands of short, interactive lessons featuring quizzes and real-world connections across Maths, English, Science, Geography, History, ICT, and Business Studies. We're adding new content every day!
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4, alignItems: 'stretch' }}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2, duration: 0.6 }}
                  viewport={{ once: true }}
                  style={{ height: '100%' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      backgroundColor: 'rgba(255,255,255,0.8)',
                      borderRadius: 2,
                      overflow: 'hidden',
                      boxShadow: 3,
                    }}
                  >
                    <Box 
                      component="img" 
                      src={feature.image} 
                      alt={feature.title} 
                      sx={{ width: '100%', aspectRatio: '1 / 1', objectFit: 'cover' }} 
                    />
                    <Box sx={{ p: 3, flexGrow: 1 }}>
                      <Typography variant="h4" gutterBottom sx={{ color: 'black' }}>
                        {feature.title}
                      </Typography>
                      <Typography variant="body1" sx={{ color: 'black' }}>
                        {feature.description}
                      </Typography>
                    </Box>
                  </Box>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* Free Trial Section */}
        <Container 
          maxWidth="lg" 
          sx={{ 
            py: 8, 
            my: 6, 
            background: 'linear-gradient(180deg, #619de2, #4a90e2)', 
            borderRadius: 2 
          }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="h4" align="left" sx={{ color: 'white' }}>
                  Free Trial 
                </Typography>
                <Typography variant="body1" align="left" sx={{ color: 'white', mt: 2 }}>
                  We believe in letting families experience Rocket Learning before making any commitments—that's why we offer a completely free two-week trial.
                  <br/><br/>
                  Dive in, explore our moderated chat room, and enjoy exclusive educational games in the Rocket Arcade to see just how engaging home education can be. No payment details required, no strings attached—just two weeks to see if we're the right fit for your family.
                  <br/><br/>
                  Give Rocket Learning a try and discover why hundreds of home-educated children are already enjoying their learning journey with us.
                </Typography>
                
                <Box mt={3}>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    href="/Register" 
                    sx={{ 
                      backgroundColor: '#ffcc00', 
                      color: '#000', 
                      fontWeight: 'bold', 
                      textTransform: 'none', 
                      px: 4, 
                      py: 1.5, 
                      borderRadius: 2,
                      '&:hover': { backgroundColor: '#ffb700' }
                    }}
                  >
                    Start Your Free Trial
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/FreeTrial.png"
                alt="Free Trial"
                sx={{ display: 'block', width: '100%' }}
              />
            </Grid>
          </Grid>
        </Container>

        {/* Social Learning Section */}
        <Container 
          maxWidth="lg" 
          sx={{ 
            py: 8, 
            my: 6, 
            background: 'linear-gradient(180deg, #619de2, #4a90e2)', 
            borderRadius: 2 
          }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/SocialLearning.png"
                alt="Social Learning"
                sx={{ display: 'block', width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="h4" align="left" sx={{ color: 'white' }}>
                  Social Learning, Safely Done
                </Typography>
                <Typography variant="body1" align="left" sx={{ color: 'white', mt: 2 }}>
                  We believe home-educated children deserve a fun and safe way to socialise with peers. That's why we've created an optional, moderated chat room—a friendly space where students can connect, collaborate, and make new friends.
                  <br /><br />
                  Plus, with customisable avatars, your child can express their personality, earn badges, and proudly showcase their achievements. At Rocket Learning, community matters—making learning interactive, personal, and social!
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>

        {/* Rocket Arcade Section */}
        <Container 
          maxWidth="lg" 
          sx={{ 
            py: 8, 
            my: 6, 
            background: 'linear-gradient(180deg, #619de2, #4a90e2)', 
            borderRadius: 2 
          }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="h4" align="left" sx={{ color: 'white' }}>
                  Rocket Arcade
                </Typography>
                <Typography variant="body1" align="left" sx={{ color: 'white', mt: 2 }}>
                  We know that answering endless questions can sometimes feel dull, so our developers have channelled their passion for gaming into an exciting collection of exclusive, in-house educational games. The Rocket Arcade transforms lessons into adventures—featuring games like Mathsteroids, GeoChase, and more, each carefully crafted to make learning fun, challenging, and rewarding.
                  <br/><br/>
                  Level up your skills, earn RocketFuel, and dive into games you won't find anywhere else. With multiplayer battles, badges, and leaderboards, we make sure learning is never boring!
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/characters.png"
                alt="Rocket Arcade Characters"
                sx={{ display: 'block', width: '100%' }}
              />
            </Grid>
          </Grid>
        </Container>

        {/* Feedback & Reports Section */}
        <Container 
          maxWidth="lg" 
          sx={{ 
            py: 8, 
            my: 6, 
            background: 'linear-gradient(180deg, #619de2, #4a90e2)', 
            borderRadius: 2 
          }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="https://websitecontent.ams3.cdn.digitaloceanspaces.com/Reports.png"
                alt="Feedback & Reports"
                sx={{ display: 'block', width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="h4" align="left" sx={{ color: 'white' }}>
                  Track Progress with Weekly Reports
                </Typography>
                <Typography variant="body1" align="left" sx={{ color: 'white', mt: 2 }}>
                  Every lesson completed and every achievement earned is recorded. With Rocket Learning, parents receive <b>weekly progress reports</b>, offering insights into their child's learning journey.
                  <br /><br />
                  These reports provide a clear breakdown of progress and achievements, making it easy to <b>evidence learning where necessary</b>. Whether for personal records or official requirements, you'll always have a structured overview of your child's education.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>

        {/* Rocket Learning at a Glance Section */}
        <Container 
          maxWidth="md" 
          sx={{ 
            py: 8, 
            my: 6, 
            textAlign: 'left', 
            background: 'linear-gradient(180deg, #619de2, #4a90e2)', 
            borderRadius: 2 
          }}
        >
          <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold', mb: 3 }}>
            Rocket Learning at a Glance
          </Typography>
          <Typography variant="h6" sx={{ color: 'white', fontWeight: 'medium', lineHeight: 1.6 }}>
            <strong>14-Day Free Trial</strong> – No payment details required, just explore and learn! <br />
            <strong>Curriculum-Aligned</strong> – Covers the UK National Curriculum (Key Stages 1–4) through to GCSEs. <br />
            <strong>Engaging Lessons</strong> – Interactive modules designed to keep students motivated. <br />
            <strong>7 Core Subjects</strong> – Maths, English, Science, Geography, History, ICT, and Business Studies. <br />
            <strong>Social Learning, Safely Done</strong> – A moderated chat room for collaboration and friendship. <br />
            <strong>Custom Avatars & Badges</strong> – Personalise your child's learning experience and celebrate achievements. <br />
            <strong>Educational Arcade</strong> – Exclusive in-house games for a more exciting study experience. <br />
            <strong>Progress Tracking</strong> – Monitor accomplishments and milestones every step of the way.  
          </Typography>

          <Box mt={4}>
            <Button 
              variant="contained" 
              color="primary" 
              href="/Register" 
              sx={{ 
                backgroundColor: '#ffcc00', 
                color: '#000', 
                fontWeight: 'bold', 
                textTransform: 'none', 
                px: 6, 
                py: 1.75, 
                borderRadius: 2,
                fontSize: '1.1rem',
                '&:hover': { backgroundColor: '#ffb700' }
              }}
            >
              Start Learning Today
            </Button>
          </Box>
        </Container>

        {/* Pricing Section */}
        <Container 
          maxWidth="lg" 
          sx={{ 
            textAlign: 'center', 
            py: 8, 
            my: 6, 
            background: 'linear-gradient(180deg, #619de2, #4a90e2)', 
            borderRadius: 2 
          }}
        >
          <Typography variant="h3" align="center" gutterBottom sx={{ color: 'white' }}>
            Our Prices
          </Typography>
          <PricingInfo />
        </Container>

        {/* FINAL CTA Section */}
        <Container 
          maxWidth="lg"
          sx={{
            textAlign: 'center',
            py: 6,
            mt: 6,
            background: 'linear-gradient(180deg, #619de2, #4a90e2)',
            borderRadius: 2,
          }}
        >
          <Typography 
            variant="h4" 
            sx={{ 
              color: 'white', 
              fontWeight: 'bold', 
              mb: 3 
            }}
          >
            Ready to give your child an engaging home education?
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: 'white', 
              mb: 4,
              fontSize: '1.2rem',
            }}
          >
            Experience Rocket Learning free for 14 days – no payment details required.
          </Typography>
          <Button 
            variant="contained"
            href="/Register" 
            sx={{ 
              backgroundColor: '#ffcc00', 
              color: '#000', 
              fontWeight: 'bold', 
              textTransform: 'none', 
              px: 6, 
              py: 1.75, 
              borderRadius: 2,
              fontSize: '1.1rem',
              '&:hover': { backgroundColor: '#ffb700' }
            }}
          >
            Start Your Free Trial
          </Button>
        </Container>

        {/* TESTIMONIALS Section (Very Bottom) */}
        <Container 
          maxWidth="md"
          sx={{
            py: 8,
            mt: 6,
            background: '#f2f2f2',
            borderRadius: 2,
          }}
        >
          <Typography 
            variant="h4" 
            align="center"
            sx={{ fontWeight: 'bold', mb: 4 }}
          >
            What Parents and Students Are Saying
          </Typography>

          <Grid container spacing={4}>
            {testimonials.map((t, idx) => (
              <Grid item xs={12} md={4} key={idx}>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: 'white',
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 3
                  }}
                >
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    “{t.quote}”
                  </Typography>
                  <Typography 
                    variant="subtitle1" 
                    sx={{ fontWeight: 'bold', mt: 'auto', textAlign: 'right' }}
                  >
                    — {t.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>

      </Box>
    </ThemeProvider>
  );
}
