import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Button,
} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi';
import LoadingSpinner from '../Utils/LoadingSpinner'; // or wherever your spinner is

// The subject ID for Maths is 1.
const SUBJECT_ID = 1;
const defaultTheme = createTheme();

function Maths() {
  const navigate = useNavigate();
  const api = useApi();
  const coreApi = useCoreApi();

  // ----------------------------------------------------------------
  // 1) AUTH & INITIAL SETUP
  // ----------------------------------------------------------------
  const access_token = localStorage.getItem('accessToken');
  const student_id = localStorage.getItem('user_id');

  // parse the student's year from localStorage (default to 1 if invalid)
  const parsedYear = parseInt(localStorage.getItem('student_year'), 10);
  const initialYear = Number.isNaN(parsedYear) ? 1 : parsedYear;

  // ----------------------------------------------------------------
  // 2) STATE
  // ----------------------------------------------------------------
  const [selectedYear, setSelectedYear] = useState(initialYear);

  // Hard-coded subTopics for Maths
  const [subTopics, setSubTopics] = useState([
    // Your entire subtopic array for Maths
    { id: 1, title: "Number", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 4, title: "Geometry & Measures", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 1, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },
    
    { id: 1, title: "Number", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 4, title: "Geometry & Measures", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 6, title: "Statistics", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 2, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 4, title: "Geometry & Measures", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 6, title: "Statistics", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 3, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 4, title: "Geometry & Measures", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 6, title: "Statistics", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 4, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 4, title: "Geometry & Measures", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 6, title: "Statistics", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 5, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 2, title: "Algebra", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-algebra.avif" },
    { id: 3, title: "Ratio, proportion & rates of change", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-ratio.avif" },
    { id: 4, title: "Geometry & Measures", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 6, title: "Statistics", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 6, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 2, title: "Algebra", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-algebra.avif" },
    { id: 3, title: "Ratio, proportion & rates of change", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-ratio.avif" },
    { id: 4, title: "Geometry & Measures", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 5, title: "Probability", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-probability.avif" },
    { id: 6, title: "Statistics", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 7, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 2, title: "Algebra", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-algebra.avif" },
    { id: 3, title: "Ratio, proportion & rates of change", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-ratio.avif" },
    { id: 4, title: "Geometry & Measures", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 5, title: "Probability", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-probability.avif" },
    { id: 6, title: "Statistics", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 8, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 2, title: "Algebra", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-algebra.avif" },
    { id: 3, title: "Ratio, proportion & rates of change", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-ratio.avif" },
    { id: 4, title: "Geometry & Measures", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 5, title: "Probability", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-probability.avif" },
    { id: 6, title: "Statistics", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 9, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 2, title: "Algebra", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-algebra.avif" },
    { id: 3, title: "Ratio, proportion & rates of change", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-ratio.avif" },
    { id: 4, title: "Geometry & Measures", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 5, title: "Probability", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-probability.avif" },
    { id: 6, title: "Statistics", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 10, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

    { id: 1, title: "Number", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-number.avif" },
    { id: 2, title: "Algebra", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-algebra.avif" },
    { id: 3, title: "Ratio, proportion & rates of change", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-ratio.avif" },
    { id: 4, title: "Geometry & Measures", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-geometry.avif" },
    { id: 5, title: "Probability", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-probability.avif" },
    { id: 6, title: "Statistics", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/maths-statistics.avif" },
    { id: 'times-table-quiz', title: "Times Table Quiz", year: 11, image: "https://rl-timestableimages.ams3.cdn.digitaloceanspaces.com/TTRS.png", isStaticLink: true },

  ]);

  // lessonsBySubtopic => { subTopicId: [ {id, title, ...} ] }
  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  // array of completed lesson IDs
  const [completedLessons, setCompletedLessons] = useState([]);

  // single isLoading & error states
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  //has user selected a year
  const [userHasChosenYear, setUserHasChosenYear] = useState(false);

  // ----------------------------------------------------------------
  // 3) AXIOS INTERCEPTOR FOR 401
  // ----------------------------------------------------------------
  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response && err.response.status === 401) {
        navigate('/StudentLogin');
      }
      return Promise.reject(err);
    }
  );

  // ----------------------------------------------------------------
  // 4) REDIRECT IF NO TOKEN (USE EFFECT)
  // ----------------------------------------------------------------
  useEffect(() => {
    if (!access_token) {
      console.warn('Missing access token. Redirecting to login.');
      navigate('/StudentLogin');
    }
  }, [access_token, navigate]);

  // ----------------------------------------------------------------
  // 5) DATA FETCHING (subjectID = 1 for Maths)
  // ----------------------------------------------------------------

  // 5A) Fetch "last completed year" for Maths (subject=1)
  const fetchLastCompletedYear = async () => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/last_completed_year_for_subject/${SUBJECT_ID}`,
        { headers: { Authorization: `Bearer ${access_token}` }, timeout: 5000 }
      );
      if (
        response.status >= 200 && 
        response.status < 300 && 
        response.data?.year_id
      ) {
        // Only setSelectedYear if userHasChosenYear is still false
        // meaning the user hasn't manually changed it yet
        if (!userHasChosenYear) {
          setSelectedYear(response.data.year_id);
        }
      }
    } catch (err) {
      console.error('Error fetching last completed year:', err);
    }
  };
  

  // 5B) Fetch completed lessons for the subtopics in the chosen year
  const fetchAllCompletedLessons = async (year) => {
    const relevantSubTopics = subTopics
      .filter((st) => st.year === Number(year))
      .map((st) => st.id);

    let allCompleted = [];
    for (let subTopicId of new Set(relevantSubTopics)) {
      // skip 'times-table-quiz' or other non-numeric IDs
      const completedForThis = await fetchCompletedLessons(subTopicId, year);
      allCompleted = [...allCompleted, ...completedForThis];
    }
    setCompletedLessons(allCompleted);
    console.log('All Completed Lessons:', allCompleted);
  };

  // Helper: fetch completed lessons for one subtopic
  const fetchCompletedLessons = async (subTopicId, year) => {
    if (typeof subTopicId !== 'number') return [];
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/completed_lessons/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data.map((lesson) => lesson.lesson_id);
      }
    } catch (err) {
      console.error('Error fetching completed lessons:', err);
    }
    return [];
  };

  // 5C) Fetch lessons for each subtopic in the chosen year
  const fetchLessonsForYear = async (year) => {
    setLessonsBySubtopic({});
    const relevantSubTopics = subTopics
      .filter((st) => st.year === Number(year))
      .map((st) => st.id);

    for (let subTopicId of new Set(relevantSubTopics)) {
      const lessons = await fetchLessonsBySubtopic(subTopicId, year);
      const updatedLessons = lessons.map((lesson) => ({
        ...lesson,
        is_completed: completedLessons.includes(lesson.id),
      }));
      setLessonsBySubtopic((prev) => ({ ...prev, [subTopicId]: updatedLessons }));
    }
  };

  // Helper: fetch lessons by subtopic
  const fetchLessonsBySubtopic = async (subTopicId, year) => {
    if (typeof subTopicId !== 'number') return [];
    try {
      const response = await coreApi.get(
        `/lessons/lessons_by_subject/${SUBJECT_ID}/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        // Return all lessons. If you only want is_completed, do .filter(...)
        return response.data;
      }
    } catch (err) {
      console.error('Error fetching lessons:', err);
    }
    return [];
  };

  // 5D) Master fetch function
  const fetchAllData = async (year) => {
    setIsLoading(true);
    setError(null);

    try {
      // 1) Possibly fetch last completed year
      await fetchLastCompletedYear();

      // 2) Then fetch completed lessons
      await fetchAllCompletedLessons(year);

      // 3) Finally fetch actual lesson data
      await fetchLessonsForYear(year);
    } catch (err) {
      console.error('fetchAllData error:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------------------------------------------------
  // 6) EFFECTS
  // ----------------------------------------------------------------

  // On mount, fetch data for the selectedYear
  useEffect(() => {
    (async () => {
      if (access_token) {
        await fetchAllData(selectedYear);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When user changes the year from dropdown
  useEffect(() => {
    if (access_token) {
      fetchAllData(selectedYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  // ----------------------------------------------------------------
  // 7) RENDERING HELPERS
  // ----------------------------------------------------------------

  // If no token, short-circuit
  if (!access_token) {
    return null;
  }

  // If loading, show spinner
  if (isLoading) {
    return <LoadingSpinner />;
  }

  // A custom menu item for lessons
  const CustomMenuItem = ({ completed, children, ...props }) => (
    <MenuItem {...props}>
      {completed ? (
        <ListItemText
          primary={children}
          primaryTypographyProps={{
            style: { textDecoration: 'line-through', color: 'green' },
          }}
        />
      ) : (
        <ListItemText primary={children} />
      )}
    </MenuItem>
  );

  // Year dropdown
  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
value={selectedYear}
// once the user picks a new year, mark userHasChosenYear = true
onChange={(e) => {
  setUserHasChosenYear(true);
  setSelectedYear(Number(e.target.value));
}}
      >
        {[...Array(11).keys()].map((_, idx) => {
          const yr = idx + 1;
          return (
            <MenuItem key={yr} value={yr}>
              Year {yr}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  // Subtopic cards
  const renderSubtopicCards = () => {
    const relevantSubTopics = subTopics.filter(
      (st) => st.year === Number(selectedYear)
    );

    return (
      <Grid container spacing={4}>
        {relevantSubTopics.map((subTopic, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia component="img" height="140" image={subTopic.image} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {subTopic.title}
                </Typography>

                {/* If it's a special link (Times Table Quiz), show a button */}
                {subTopic.isStaticLink ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/TimesTableQuiz')}
                  >
                    Go to Quiz
                  </Button>
                ) : (
                  <FormControl fullWidth>
                    <Select
                      defaultValue=""
                      onChange={(e) => navigate(`/Classroom/${e.target.value}`)}
                    >
                      <MenuItem value="" disabled>
                        Select a lesson
                      </MenuItem>

                      {error && !lessonsBySubtopic[subTopic.id]?.length && (
                        <MenuItem disabled>Error: {error}</MenuItem>
                      )}

                      {lessonsBySubtopic[subTopic.id]?.map((lesson) => {
                        const isCompleted = completedLessons.includes(lesson.id);
                        return (
                          <CustomMenuItem
                            key={lesson.id}
                            value={lesson.id}
                            completed={isCompleted}
                          >
                            {lesson.title}
                          </CustomMenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  // ----------------------------------------------------------------
  // 8) FINAL RENDER
  // ----------------------------------------------------------------
  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate('/LessonSelect')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Maths
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a year first.
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Don't be scared to drop down or move up years, there's value in
          practicing the basics, or going for a challenge!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <b>Year Selector</b>
        </Typography>

        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
          (Note: This will default to the year of the last Maths lesson you
          completed, but feel free to change it whenever!)
        </Typography>

        <Container maxWidth="lg">
          {renderYearDropdown()}
          <Box sx={{ mt: 3 }}>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              <b>Lesson Selector</b>
            </Typography>
            {renderSubtopicCards()}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Maths;
