import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useApi from '../Utils/useApi';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingSpinner from '../Utils/LoadingSpinner'; // Import your LoadingSpinner

const defaultTheme = createTheme();

const StudentLogin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState('');
  const [loading, setLoading] = React.useState(false); // Loading state
  const api = useApi();
  const location = useLocation();
  const from = location.state?.from || '/studentdashboard';
  const reason = location.state?.reason;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMsg(''); // Clear previous errors
    setLoading(true); // Start loading
    const url = `/user/login`;

    try {
      const response = await api.post(url, {
        login_value: username,
        password,
        userType: "student"
      });

      const { access_token, refresh_token, user_id, usertype, chat_enabled } = response.data;

      // Set tokens and user info in localStorage
      localStorage.setItem('accessToken', access_token);
      localStorage.setItem('refreshToken', refresh_token);
      localStorage.setItem('user_id', user_id);
      localStorage.setItem('usertype', usertype);
      localStorage.setItem('chat_enabled', chat_enabled);

      // Dispatch the custom event to notify components of login state change
      window.dispatchEvent(new Event('loginStateChange'));

      navigate(from); // Only navigate on successful login
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMsg('Incorrect username or password. Please try again.');
      } else {
        setErrorMsg('Error logging in. Please try again.');
      }
      console.log(`Error logging in as student:`, error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://websitecontent.ams3.cdn.digitaloceanspaces.com/CLoginBackground.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: '100% 100%',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Student Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {(errorMsg || reason) && (
                <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
                  {errorMsg || reason}
                </Typography>
              )}
              <Box sx={{ position: 'relative' }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading} // Disable button when loading
                >
                  Sign In
                </Button>
                {loading && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  >
                    <LoadingSpinner size={24} /> {/* Adjust size as needed */}
                  </Box>
                )}
              </Box>
              <Grid container>
                <Grid item xs>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={(e) => {
                      e.preventDefault();
                      alert('Please contact your parent to reset the password through the Parent Dashboard.');
                    }}
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default StudentLogin;
