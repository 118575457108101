import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
  Button,
  Card,
  CardContent,
  Grid,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi'; 
import LoadingSpinner from '../Utils/LoadingSpinner';  // Make sure this path is correct
const SUBJECT_ID = 7;
const defaultTheme = createTheme();

function BusinessStudies() {
  const navigate = useNavigate();
  const api = useApi();
  const coreApi = useCoreApi();


  // ----------------------------------------------------------------
  // 1) AUTH & INITIAL SETUP
  // ----------------------------------------------------------------
  const access_token = localStorage.getItem('accessToken');
  const student_id = localStorage.getItem('user_id');

  // Parse the year from local storage or default to 7 (since you only have 7-11)
  const parsedYear = parseInt(localStorage.getItem('student_year'), 10);
  let initialYear = [7, 8, 9, 10, 11].includes(parsedYear) ? parsedYear : 7;

  // ----------------------------------------------------------------
  // 2) HOOK STATES
  // ----------------------------------------------------------------
  const [selectedYear, setSelectedYear] = useState(initialYear);

  const [subTopics, setSubTopics] = useState([
    // (Your entire subtopic array for Business Studies)
    { id: 215, title: "Introduction to Business: Exploring the Purpose and Types of Businesses", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/215.jpg" },
    { id: 216, title: "Business and Society: How Businesses Impact Our Daily Lives", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/216.jpg" },
    { id: 217, title: "Entrepreneurship Basics: What It Means to Be an Entrepreneur", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/217.jpg" },
    { id: 218, title: "Simple Business Terms and Concepts: Understanding the Basics", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/218.jpg" },
    { id: 219, title: "The Role of Technology in Business: An Overview for Beginners", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/219.jpg" },
    { id: 220, title: "Basic Business Operations: Understanding How Businesses Work", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/220.jpg" },
    { id: 221, title: "Introduction to Business Ethics and Sustainability: Making Good Choices", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/221.jpg" },
    { id: 222, title: "Basics of Money Management: Learning to Save and Budget", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/222.jpg" },
    { id: 223, title: "Introduction to Personal Saving Goals: Why Saving Money Matters", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/223.jpg" },
    { id: 224, title: "Understanding Simple Interest: How Saving Money Can Grow Over Time", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/224.jpg" },
    { id: 225, title: "Business Objectives and Aims: Setting Goals in Business", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/225.jpg" },
    { id: 226, title: "Introduction to Marketing: How Businesses Reach Their Customers", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/226.jpg" },
    { id: 227, title: "The World of Finance: Revenue, Costs, and Profit Basics", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/227.jpg" },
    { id: 228, title: "Business Operations and Production: How Goods and Services are Made", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/228.jpg" },
    { id: 229, title: "Understanding Business Stakeholders: Who Has an Interest in Businesses?", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/229.jpg" },
    { id: 230, title: "The Importance of Business Planning: Creating a Simple Business Plan", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/230.jpg" },
    { id: 231, title: "Exploring Different Types of Business Ownership: Sole Traders to Corporations", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/231.jpg" },
    { id: 232, title: "Introduction to Investing: Simple Concepts of Making Money Work", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/232.jpg" },
    { id: 233, title: "Planning for a Financial Goal: Saving for Something Big", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/233.jpg" },
    { id: 234, title: "Basic Concepts of Borrowing: What Does It Mean to Borrow Money?", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/234.jpg" },
    { id: 235, title: "External Influences on Business: Technology, Economy, and Legislation", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/235.jpg" },
    { id: 236, title: "Marketing Mix and Strategies: Dive Deeper into Product, Price, Promotion, and Place", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/236.jpg" },
    { id: 237, title: "Human Resources Management: The Basics of Hiring and Motivating Employees", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/237.jpg" },
    { id: 238, title: "Advanced Business Finance: Introduction to Financial Ratios and Cash-Flow Forecasting", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/238.jpg" },
    { id: 239, title: "The Competitive Environment: Understanding Risk and Reward in Business", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/239.jpg" },
    { id: 240, title: "Business Ethics and Responsibility: A Closer Look at Ethical Business Practices", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/240.jpg" },
    { id: 241, title: "Business Growth Strategies: Exploring How Businesses Expand", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/241.jpg" },
    { id: 242, title: "The Concept of Credit: Understanding Credit Cards and Loans", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/242.jpg" },
    { id: 243, title: "Introduction to Mortgages: How People Buy Homes", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/243.jpg" },
    { id: 244, title: "Investing Basics for Teenagers: Stocks, Bonds, and Savings Accounts", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/244.jpg" },
    { id: 245, title: "Globalisation and International Business: How Businesses Operate Worldwide", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/245.jpg" },
    { id: 246, title: "In-depth Study of Business Operations: From Procurement to Quality Control", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/246.jpg" },
    { id: 247, title: "The Role of Digital Marketing in Modern Businesses", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/247.jpg" },
    { id: 248, title: "Analyzing Business Performance: Using Financial Information for Decision Making", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/248.jpg" },
    { id: 249, title: "Understanding Market Research: Qualitative vs Quantitative Approaches", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/249.jpg" },
    { id: 250, title: "Organisational Structures and Communication in Businesses", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/250.jpg" },
    { id: 251, title: "Problem Solving in Business: Making Informed Decisions with Quantitative Data", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/251.jpg" },
    { id: 252, title: "Personal Budgeting and Finance Management: Tools and Techniques", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/252.jpg" },
    { id: 253, title: "Understanding Taxes and Payslips: Where Does the Money Go?", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/253.jpg" },
    { id: 254, title: "Principles of Smart Investing: Diversification and Risk Management", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/254.jpg" },
    { id: 255, title: "Preparing for the Business World: Transitioning from GCSE to A-Level or Career", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/255.jpg" },
    { id: 256, title: "Strategic Business Decision Making: Applying Knowledge to Real-World Scenarios", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/256.jpg" },
    { id: 257, title: "E-commerce and Digital Businesses: The Future of Business", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/257.jpg" },
    { id: 258, title: "Sustainability and Global Challenges for Businesses", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/258.jpg" },
    { id: 259, title: "Advanced Marketing Strategies: Segmenting, Targeting, and Positioning", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/259.jpg" },
    { id: 260, title: "Leadership and Management in Business: Developing Effective Business Leaders", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/260.jpg" },
    { id: 261, title: "Evaluating Business Finance: Advanced Calculations and Interpretations for Decision Making", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/261.jpg" },
    { id: 262, title: "Financial Planning for the Future: Pensions and Long-Term Saving Strategies", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/262.jpg" },
    { id: 263, title: "Getting Ready for the Real World: Managing Finances After School", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/263.jpg" },
    { id: 264, title: "Entrepreneurship and Personal Finance: Starting a Business with Minimal Resources", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/264.jpg" },

  ]);

  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  const [completedLessons, setCompletedLessons] = useState([]);

  // Single loading and error states
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userHasChosenYear, setUserHasChosenYear] = useState(false);

  // ----------------------------------------------------------------
  // 3) AXIOS INTERCEPTORS
  //    Moved near the top so it’s not in a conditional area
  // ----------------------------------------------------------------
  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response && err.response.status === 401) {
        navigate('/StudentLogin');
      }
      return Promise.reject(err);
    }
  );

  // ----------------------------------------------------------------
  // 4) REDIRECT IF NO TOKEN (USE EFFECT)
  // ----------------------------------------------------------------
  useEffect(() => {
    if (!access_token) {
      console.warn('Missing access token. Redirecting to login.');
      navigate('/StudentLogin');
    }
  }, [access_token, navigate]);

  // ----------------------------------------------------------------
  // 5) DATA FETCHING FUNCTIONS
  // ----------------------------------------------------------------

  // 5A) Fetch the last completed year for Business Studies (subjectID 7)
  const fetchLastCompletedYear = async () => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/last_completed_year_for_subject/${SUBJECT_ID}`,
        { headers: { Authorization: `Bearer ${access_token}` }, timeout: 5000 }
      );
      if (
        response.status >= 200 && 
        response.status < 300 && 
        response.data?.year_id
      ) {
        // Only setSelectedYear if userHasChosenYear is still false
        // meaning the user hasn't manually changed it yet
        if (!userHasChosenYear) {
          setSelectedYear(response.data.year_id);
        }
      }
    } catch (err) {
      console.error('Error fetching last completed year:', err);
    }
  };
  

  // 5B) Fetch “completed lessons” for subtopics in the chosen year
  const fetchAllCompletedLessons = async (year) => {
    const uniqueSubTopics = [
      ...new Set(subTopics.filter((st) => st.year === Number(year)).map((st) => st.id)),
    ];

    let allCompleted = [];
    for (let subTopicId of uniqueSubTopics) {
      const theseCompleted = await fetchCompletedLessons(subTopicId, year);
      allCompleted = [...allCompleted, ...theseCompleted];
    }
    setCompletedLessons(allCompleted);
    console.log('All Completed Lessons:', allCompleted);
  };

  // Helper: fetch completed lessons for a single subtopic
  const fetchCompletedLessons = async (subTopicId, year) => {
    // For subTopics like “quiz links” that aren’t numeric, skip
    if (typeof subTopicId !== 'number') return [];
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/completed_lessons/7/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data.map((lesson) => lesson.lesson_id);
      }
      return [];
    } catch (err) {
      console.error('Error fetching completed lessons:', err);
      return [];
    }
  };

  // 5C) Fetch “lessons” for subtopics in the chosen year
  const fetchLessonsForYear = async (year) => {
    setLessonsBySubtopic({}); // reset

    const uniqueSubTopics = [
      ...new Set(subTopics.filter((st) => st.year === Number(year)).map((st) => st.id)),
    ];

    for (let subTopicId of uniqueSubTopics) {
      const lessons = await fetchLessonsBySubtopic(subTopicId, year);
      const completedIds = completedLessons;

      // Mark them as completed if in completedLessons
      const updated = lessons.map((lesson) => ({
        ...lesson,
        is_completed: completedIds.includes(lesson.id),
      }));

      setLessonsBySubtopic((prev) => ({ ...prev, [subTopicId]: updated }));
    }
  };

  // Helper: fetch lessons for a single subtopic
  const fetchLessonsBySubtopic = async (subTopicId, year) => {
    // skip if subTopicId is not numeric
    if (typeof subTopicId !== 'number') return [];
    try {
      const response = await coreApi.get(
        `/lessons/lessons_by_subject/7/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        // In your original code, you filtered out only is_completed==true
        // If you still want that logic, you can do:
        //   return response.data.filter(lesson => lesson.is_completed);
        // But if you want all lessons, do:
        return response.data;
      }
      return [];
    } catch (err) {
      console.error('Error fetching lessons:', err);
      return [];
    }
  };

  // 5D) Master fetch function: fetch last year, then completed lessons, then lessons
  const fetchAllData = async (year) => {
    setIsLoading(true);
    setError(null);
    try {
      // 1) Possibly fetch last completed year first:
      await fetchLastCompletedYear();

      // 2) Then fetch all completed lessons for that year
      await fetchAllCompletedLessons(year);

      // 3) Then fetch the actual lesson data
      await fetchLessonsForYear(year);
    } catch (err) {
      console.error('fetchAllData error:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------------------------------------------------
  // 6) EFFECTS
  // ----------------------------------------------------------------

  // On mount, fetch last completed year & relevant data
  useEffect(() => {
    (async () => {
      await fetchAllData(selectedYear);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When user changes selectedYear, refetch
  useEffect(() => {
    fetchAllData(selectedYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  // ----------------------------------------------------------------
  // 7) RENDER HELPERS
  // ----------------------------------------------------------------
  const CustomMenuItem = ({ completed, children, ...props }) => {
    return (
      <MenuItem {...props}>
        {completed ? (
          <ListItemText
            primary={children}
            primaryTypographyProps={{
              style: { textDecoration: 'line-through', color: 'green' },
            }}
          />
        ) : (
          <ListItemText primary={children} />
        )}
      </MenuItem>
    );
  };

  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
        value={selectedYear}
        // once the user picks a new year, mark userHasChosenYear = true
        onChange={(e) => {
          setUserHasChosenYear(true);
          setSelectedYear(Number(e.target.value));
        }}
      >
        {[7, 8, 9, 10, 11].map((year) => (
          <MenuItem value={year} key={year}>
            Year {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderSubtopicCards = () => {
    const relevantSubTopics = subTopics.filter(
      (subTopic) => subTopic.year === Number(selectedYear)
    );

    return (
      <Grid container spacing={4}>
        {relevantSubTopics.map((subTopic, idx) => (
          <Grid item key={idx} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia component="img" height="140" image={subTopic.image} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {subTopic.title}
                </Typography>
                {/* If it's a static link or special quiz link, handle that differently */}
                {subTopic.isStaticLink ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/TimesTableQuiz')}
                  >
                    Go to Quiz
                  </Button>
                ) : (
                  <FormControl fullWidth>
                    <Select
                      defaultValue=""
                      onChange={(event) => navigate(`/Classroom/${event.target.value}`)}
                    >
                      <MenuItem value="" disabled>
                        Select a lesson
                      </MenuItem>
                      {isLoading && <MenuItem disabled>Loading...</MenuItem>}
                      {error && !lessonsBySubtopic[subTopic.id]?.length && (
                        <MenuItem disabled>Error: {error}</MenuItem>
                      )}
                      {lessonsBySubtopic[subTopic.id]?.map((lesson) => {
                        const isCompleted = completedLessons.includes(lesson.id);
                        return (
                          <CustomMenuItem
                            key={lesson.id}
                            value={lesson.id}
                            completed={isCompleted}
                          >
                            {lesson.title}
                          </CustomMenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  // ----------------------------------------------------------------
  // 8) FINAL RENDER
  // ----------------------------------------------------------------

  // If no token, short-circuit (after useEffect tries to redirect)
  if (!access_token) {
    return null;
  }

  // Show spinner if loading
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate('/LessonSelect')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Business Studies
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a year first.
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Don't be scared to drop down or move up years, there's value in
          practicing the basics, or going for a challenge!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <b>Year Selector</b>
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
          (Note: This will default to the year of the last Business Studies lesson
          you completed, but feel free to change it whenever!)
        </Typography>

        <Container maxWidth="lg">
          {renderYearDropdown()}
          <Box sx={{ mt: 3 }}>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              <b>Lesson Selector</b>
            </Typography>
            {renderSubtopicCards()}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default BusinessStudies;
