import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Helmet } from "react-helmet";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useApi from '../Utils/useApi';
import { useNavigate } from 'react-router-dom';

const defaultTheme = createTheme();

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const api = useApi();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const url = `/user/request-password-reset`;

    try {
      const response = await api.post(url, { email });

      if (response.data.msg) {
        setMessage(response.data.msg);
      }

    } catch (error) {
      console.log(`Error requesting password reset:`, error);
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Helmet>
        <title>Forgot Your Password? Reset It Here - Rocket Learning</title>
        <meta name="description" content="Forgot your password? Enter your email to get a password reset link and access your Rocket Learning account." />
        <link rel="canonical" href="https://rocketlearning.uk/forgot-password" />
        <meta property="og:title" content="Forgot Your Password? Reset It Here - Rocket Learning" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://rocketlearning.uk/images/password-reset-image.png" />
        <meta property="og:url" content="https://rocketlearning.uk/forgot-password" />
        <meta property="og:description" content="Enter your email address to get a password reset link and regain access to your Rocket Learning account." />
      </Helmet>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://websitecontent.ams3.cdn.digitaloceanspaces.com/Wallpaper1.avif)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            {message ? (
              <Typography color="textSecondary">{message}</Typography>
            ) : (
              <>
                <Typography component="p" variant="body2">
                  If you've forgotten your password, don't worry! Just enter your email address below, and we'll send you a reset link. Make sure to check your spam or junk folders if you don't see the email.
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Send Reset Link
                  </Button>
                </Box>
              </>
            )}
            <Grid container>
              <Grid item xs>
                <Link href="/" variant="body2" style={{ marginTop: '10px', display: 'block' }}>
                  Return to Homepage
                </Link>
                <Link href="/Parentlogin" variant="body2">
                  Remembered? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default ForgotPassword;
