import React, { useEffect, useState } from 'react';
import useApi from './useApi';
import './UserAdmin.css'; // Add custom styles as needed

const UserAdmin = () => {
  const [data, setData] = useState([]); // Store data
  const [editingRowId, setEditingRowId] = useState(null); // Track which row is being edited
  const [formData, setFormData] = useState({}); // Track form input values
  const api = useApi(); // API hook

  // Fetch user and subscription data, but only once when the component mounts
  useEffect(() => {
    let isMounted = true; // Track if component is still mounted
    api.get('/useradmin/admin/users-and-subscriptions')
      .then(response => {
        if (isMounted) {
          setData(response.data); // Set data once on mount
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    return () => {
      isMounted = false; // Cleanup if component is unmounted
    };
  }, [api]); // Ensure it runs only once by passing an empty dependency array

  // Function to handle input changes in the table
  const handleInputChange = (e, rowId, field) => {
    setFormData({
      ...formData,
      [rowId]: {
        ...formData[rowId],
        [field]: e.target.value,
      }
    });
  };

  // Function to submit updated data
  const handleSave = (rowId) => {
    const rowData = formData[rowId];
    api.put('/useradmin/admin/update', rowData)
      .then(response => {
        alert('Data updated successfully');
        setEditingRowId(null); // Exit edit mode
      })
      .catch(error => {
        console.error('Error updating data:', error);
      });
  };

  return (
    <div>
      <h2>User and Subscription Admin</h2>
      <table className="admin-table">
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Date of Birth</th>
            <th>Chat Enabled</th>
            <th>Subscription Type</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>Recurring</th>
            <th>Transaction Reference</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>
                {editingRowId === index ? (
                  <input
                    type="text"
                    value={formData[index]?.username || row.username}
                    onChange={(e) => handleInputChange(e, index, 'username')}
                  />
                ) : (
                  row.username
                )}
              </td>
              <td>
                {editingRowId === index ? (
                  <input
                    type="text"
                    value={formData[index]?.email || row.email}
                    onChange={(e) => handleInputChange(e, index, 'email')}
                  />
                ) : (
                  row.email
                )}
              </td>
              <td>{/* Add similar inputs for other fields */}</td>
              <td>
                {editingRowId === index ? (
                  <button onClick={() => handleSave(index)}>Save</button>
                ) : (
                  <button onClick={() => setEditingRowId(index)}>Edit</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserAdmin;
