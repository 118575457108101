import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
  Button,
  Card,
  CardContent,
  Grid,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi';
import LoadingSpinner from '../Utils/LoadingSpinner';  // <-- Ensure this path is correct
const SUBJECT_ID = 3;
const defaultTheme = createTheme();

function Geography() {
  const navigate = useNavigate();
  const api = useApi();
  const coreApi = useCoreApi();

  // ---------------------------------------------------------------
  // 1) AUTH & INITIAL SETUP
  // ---------------------------------------------------------------
  const access_token = localStorage.getItem('accessToken');
  const student_id = localStorage.getItem('user_id');

  // Parse year from localStorage or default to 1
  const parsedYear = parseInt(localStorage.getItem('student_year'), 10);
  const initialYear = Number.isNaN(parsedYear) ? 1 : parsedYear;

  // ---------------------------------------------------------------
  // 2) HOOK STATES
  // ---------------------------------------------------------------
  const [selectedYear, setSelectedYear] = useState(initialYear);

  const [subTopics, setSubTopics] = useState([
    // --- Your entire subTopic array ---
    { id: 59, title: "Locational knowledge", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 59, title: "Locational knowledge", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-plants.avif" },
    { id: 60, title: "Place knowledge", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-animals.avif" },
    { id: 61, title: "Human and physical geography", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 62, title: "Geographical skills and fieldwork", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-seasons.avif" },
    { id: 63, title: "Locational knowledge", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-knowledge.avif" },
    { id: 64, title: "Maps, fieldwork and geographical skills", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-fieldwork.avif" },
    { id: 65, title: "Place: processes and relationships", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-places.avif" },
    { id: 66, title: "Physical geography: processes and change", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-physical.avif" },
    { id: 67, title: "People and environment: processes and interactions ", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-environment.avif" },
    { id: 68, title: "Human geography: processes and change", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },
    { id: 63, title: "Locational knowledge", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-knowledge.avif" },
    { id: 64, title: "Maps, fieldwork and geographical skills", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-fieldwork.avif" },
    { id: 65, title: "Place: processes and relationships", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-places.avif" },
    { id: 66, title: "Physical geography: processes and change", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-physical.avif" },
    { id: 67, title: "People and environment: processes and interactions ", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-environment.avif" },
    { id: 68, title: "Human geography: processes and change", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/geog-humab.avif" },

  ]);

  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  const [completedLessons, setCompletedLessons] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userHasChosenYear, setUserHasChosenYear] = useState(false);

  // ---------------------------------------------------------------
  // 3) AXIOS INTERCEPTOR FOR 401
  // ---------------------------------------------------------------
  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response && err.response.status === 401) {
        navigate('/StudentLogin');
      }
      return Promise.reject(err);
    }
  );

  // ---------------------------------------------------------------
  // 4) REDIRECT IF NO TOKEN (USE EFFECT)
  // ---------------------------------------------------------------
  useEffect(() => {
    if (!access_token) {
      console.warn('Missing access token. Redirecting to login.');
      navigate('/StudentLogin');
    }
  }, [access_token, navigate]);

  // ---------------------------------------------------------------
  // 5) DATA FETCHING FUNCTIONS
  // subject ID for Geography is 3
  // ---------------------------------------------------------------

  // 5A) Fetch last completed year (subject=3) from server
  const fetchLastCompletedYear = async () => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/last_completed_year_for_subject/${SUBJECT_ID}`,
        { headers: { Authorization: `Bearer ${access_token}` }, timeout: 5000 }
      );
      if (
        response.status >= 200 && 
        response.status < 300 && 
        response.data?.year_id
      ) {
        // Only setSelectedYear if userHasChosenYear is still false
        // meaning the user hasn't manually changed it yet
        if (!userHasChosenYear) {
          setSelectedYear(response.data.year_id);
        }
      }
    } catch (err) {
      console.error('Error fetching last completed year:', err);
    }
  };
  

  // 5B) Fetch completed lessons for all subtopics in chosen year
  const fetchAllCompletedLessons = async (year) => {
    const uniqueSubTopicIds = [
      ...new Set(
        subTopics.filter((st) => st.year === Number(year)).map((st) => st.id)
      ),
    ];

    let allCompleted = [];
    for (let subTopicId of uniqueSubTopicIds) {
      const theseCompleted = await fetchCompletedLessons(subTopicId, year);
      allCompleted = [...allCompleted, ...theseCompleted];
    }

    setCompletedLessons(allCompleted);
    console.log("All Completed Lessons:", allCompleted);
  };

  // Helper: fetch completed lessons for a single subtopic
  const fetchCompletedLessons = async (subTopicId, year) => {
    if (typeof subTopicId !== 'number') return []; // skip if not numeric ID
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/completed_lessons/3/${subTopicId}/${year}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      if (response.status === 200) {
        return response.data.map((lesson) => lesson.lesson_id);
      }
      return [];
    } catch (err) {
      console.error("Error fetching completed lessons:", err);
      return [];
    }
  };

  // 5C) Fetch lessons for all subtopics in chosen year
  const fetchLessonsForYear = async (year) => {
    // reset lessons
    setLessonsBySubtopic({});

    const uniqueSubTopicIds = [
      ...new Set(
        subTopics.filter((st) => st.year === Number(year)).map((st) => st.id)
      ),
    ];

    // For each subtopic ID, fetch lessons & mark them completed
    for (let subTopicId of uniqueSubTopicIds) {
      const lessons = await fetchLessonsBySubtopic(subTopicId, year);
      const completedIds = completedLessons; // already fetched above

      const updatedLessons = lessons.map((lesson) => ({
        ...lesson,
        is_completed: completedIds.includes(lesson.id),
      }));

      setLessonsBySubtopic((prev) => ({ ...prev, [subTopicId]: updatedLessons }));
    }
  };

  // Helper: fetch lessons for single subtopic
  const fetchLessonsBySubtopic = async (subTopicId, year) => {
    if (typeof subTopicId !== 'number') return [];
    try {
      const response = await coreApi.get(
        `/lessons/lessons_by_subject/3/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        // You had a filter originally: `lesson.is_completed === true`
        // If you still want that, do: return response.data.filter(lesson => lesson.is_completed)
        // Otherwise, this returns all lessons:
        return response.data;
      }
      return [];
    } catch (err) {
      console.error("Error fetching lessons:", err);
      return [];
    }
  };

  // 5D) Master fetch function
  const fetchAllData = async (year) => {
    setIsLoading(true);
    setError(null);
    try {
      // 1) Possibly fetch last completed year
      await fetchLastCompletedYear();

      // 2) Then fetch completed lessons
      await fetchAllCompletedLessons(year);

      // 3) Then fetch the lessons
      await fetchLessonsForYear(year);
    } catch (err) {
      console.error("fetchAllData error:", err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // ---------------------------------------------------------------
  // 6) EFFECTS
  // ---------------------------------------------------------------

  // On mount, fetch data for the initialYear
  useEffect(() => {
    (async () => {
      await fetchAllData(selectedYear);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If user changes year, refetch
  useEffect(() => {
    fetchAllData(selectedYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  // ---------------------------------------------------------------
  // 7) RENDER HELPERS
  // ---------------------------------------------------------------
  const CustomMenuItem = ({ completed, children, ...other }) => (
    <MenuItem {...other}>
      {completed ? (
        <ListItemText
          primary={children}
          primaryTypographyProps={{
            style: { textDecoration: 'line-through', color: 'green' },
          }}
        />
      ) : (
        <ListItemText primary={children} />
      )}
    </MenuItem>
  );

  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
 value={selectedYear}
 // once the user picks a new year, mark userHasChosenYear = true
 onChange={(e) => {
   setUserHasChosenYear(true);
   setSelectedYear(Number(e.target.value));
 }}
      >
        {[...Array(11).keys()].map((_, idx) => {
          const yearVal = idx + 1;
          return (
            <MenuItem key={yearVal} value={yearVal}>
              Year {yearVal}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const renderSubtopicCards = () => {
    const relevantSubTopics = subTopics.filter(
      (st) => st.year === Number(selectedYear)
    );

    return (
      <Grid container spacing={4}>
        {relevantSubTopics.map((subTopic, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia component="img" height="140" image={subTopic.image} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {subTopic.title}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    defaultValue=""
                    onChange={(event) =>
                      navigate(`/Classroom/${event.target.value}`)
                    }
                  >
                    <MenuItem value="" disabled>
                      Select a lesson
                    </MenuItem>

                    {isLoading && <MenuItem disabled>Loading...</MenuItem>}
                    {error && !lessonsBySubtopic[subTopic.id]?.length && (
                      <MenuItem disabled>Error: {error}</MenuItem>
                    )}

                    {lessonsBySubtopic[subTopic.id]?.map((lesson) => {
                      const isCompleted = completedLessons.includes(lesson.id);
                      return (
                        <CustomMenuItem
                          key={lesson.id}
                          value={lesson.id}
                          completed={isCompleted}
                        >
                          {lesson.title}
                        </CustomMenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  // ---------------------------------------------------------------
  // 8) FINAL RENDER
  // ---------------------------------------------------------------

  // If no token, short-circuit (useEffect above tries to redirect)
  if (!access_token) {
    return null;
  }

  // If loading, show spinner
  if (isLoading) {
    return <LoadingSpinner />;
  }

  // Otherwise, normal UI
  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate('/LessonSelect')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Geography
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a year first.
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Don't be scared to drop down or move up years, there's value in
          practicing the basics, or going for a challenge!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <b>Year Selector</b>
        </Typography>

        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
          (Note: This will default to the year of the last Geography lesson
          you completed, but feel free to change it whenever!)
        </Typography>

        <Container maxWidth="lg">
          {renderYearDropdown()}
          <Box sx={{ mt: 3 }}>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              <b>Lesson Selector</b>
            </Typography>
            {renderSubtopicCards()}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Geography;
