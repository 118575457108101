import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import useApi from '../Utils/useApi';

const AssessmentHistory = () => {
  const navigate = useNavigate();
  const { subjectId } = useParams(); // Get subjectId from URL parameters
  const api = useApi();
  const [assessmentHistory, setAssessmentHistory] = useState([]);

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    let endpoint = `/assess/getAssessmentHistory/${userId}`;
    if (subjectId) {
      endpoint += `?subject_id=${subjectId}`;
    }

    api.get(endpoint)
      .then((response) => {
        setAssessmentHistory(response.data);
      })
      .catch((error) => {
        console.error('Error fetching assessment history:', error);
      });
  }, [subjectId]);

  const handleRetakeAssessment = (subjectId) => {
    navigate(`/assessment-questions/${subjectId}`);
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        {subjectId ? `Assessment History for ${getSubjectName(subjectId)}` : 'Assessment History'}
      </Typography>
      
      {assessmentHistory.length === 0 ? (
        <>
          <Typography variant="body1">You have not taken any assessments {subjectId ? 'for this subject' : ''} yet.</Typography>
          <Button variant="contained" color="primary" onClick={handleGoBack} style={{ marginTop: '20px' }}>
            Back
          </Button>
        </>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="assessment history table">
              <TableHead>
                <TableRow>
                  {!subjectId && <TableCell>Subject</TableCell>}
                  <TableCell align="right">Score</TableCell>
                  <TableCell align="right">Suggested Year Level</TableCell>
                  <TableCell align="right">Date Taken</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assessmentHistory.map((assessment) => (
                  <TableRow key={assessment.id}>
                    {!subjectId && (
                      <TableCell component="th" scope="row">
                        {assessment.subject_name}
                      </TableCell>
                    )}
                    <TableCell align="right">{assessment.score}</TableCell>
                    <TableCell align="right">{assessment.suggested_year_level}</TableCell>
                    <TableCell align="right">{assessment.date_taken}</TableCell>
                    <TableCell align="right">
                      <Button variant="outlined" onClick={() => handleRetakeAssessment(assessment.subject_id)}>
                        Retake
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button variant="contained" color="primary" onClick={handleGoBack} style={{ marginTop: '20px' }}>
            Back
          </Button>
        </>
      )}
    </Container>
  );
};

// Helper function to get subject name based on subjectId
const getSubjectName = (subjectId) => {
  const subject = SUBJECTS.find((subj) => subj.id === parseInt(subjectId));
  return subject ? subject.name : 'Unknown Subject';
};

// Include the SUBJECTS array or import it from a constants file
const SUBJECTS = [
  { id: 1, name: 'Maths' },
  { id: 2, name: 'Science' },
  { id: 3, name: 'Geography' },
  { id: 4, name: 'History' },
  { id: 5, name: 'English' },
  { id: 6, name: 'ICT' },
  { id: 7, name: 'Business Studies' },
  // ... other subjects
];

export default AssessmentHistory;
