import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import useCoreApi from '../Utils/useCoreApi';

// Create a styled container that forces a blue background.
const BackgroundBox = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #4a91e3, #4a91e3) !important',
  minHeight: '100vh !important',
  paddingTop: '32px !important',
  paddingBottom: '32px !important'
}));

// New HeroBanner component at the top of the forum
const HeroBanner = styled(Box)(({ theme }) => ({
  background: 'url(https://websitecontent.ams3.cdn.digitaloceanspaces.com/ParentForumHero.png) center/cover no-repeat', // update path or replace with a gradient/color if desired
  color: '#fff',
  padding: '80px 20px',
  textAlign: 'center'
}));

// ─────────────────────────────────────────────────────────────────────────────
// Sidebar Component: Dynamically shows categories
// ─────────────────────────────────────────────────────────────────────────────
function Sidebar({ categories, selectedCategory, onSelectCategory }) {
  return (
    <Box sx={{ width: '240px', backgroundColor: '#f5f5f5', p: 2, borderRadius: 1 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Categories
      </Typography>
      <List>
        {categories.map((cat) => (
          <ListItem key={cat.id} disablePadding>
            <ListItemButton
              selected={selectedCategory === cat.id}
              onClick={() => onSelectCategory(cat.id)}
            >
              <ListItemText primary={cat.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

// ─────────────────────────────────────────────────────────────────────────────
// ForumList: Shows categories (fetched from backend) & displays relevant threads
// ─────────────────────────────────────────────────────────────────────────────
function ForumList() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0); // 0 = "All"
  const [threads, setThreads] = useState([]);
  const [guidelinesOpen, setGuidelinesOpen] = useState(false);
  const navigate = useNavigate();
  const coreApi = useCoreApi();

  // Fetch categories on mount
  useEffect(() => {
    coreApi
      .get('/forum/categories')
      .then((res) => {
        const dbCats = res.data.categories || [];
        // Insert an "All" item at the front
        const allItem = { id: 0, name: 'All' };
        setCategories([allItem, ...dbCats]);
      })
      .catch((err) => console.error('Error fetching categories:', err));
  }, []);

  // Helper: fetch threads for either "all" or a specific category
  async function fetchThreads(catId) {
    try {
      if (catId === 0) {
        // "All"
        const res = await coreApi.get('/forum/threads');
        setThreads(res.data.threads || []);
      } else {
        const res = await coreApi.get(`/forum/category/${catId}/threads`);
        setThreads(res.data.threads || []);
      }
    } catch (err) {
      console.error('Error fetching threads:', err);
    }
  }

  // Whenever the selected category changes, load the appropriate threads
  useEffect(() => {
    fetchThreads(selectedCategory);
  }, [selectedCategory]);

  return (
    <BackgroundBox>
      {/* Hero Banner */}
      <HeroBanner>
        <Typography
          variant="h2"
          sx={{
            fontSize: '6rem',
            color: 'blue',
            textShadow: '4px 2px 0 #000'
          }}
        >
          Home Education Forum
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: '3rem',
            color: 'blue',
            textShadow: '3px 1px 0 #000'
          }}
        >
          Join discussions, share ideas, and get support.
        </Typography>
      </HeroBanner>
      
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {/* Top controls: New Thread & Forum Guidelines */}
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Button variant="contained" color="primary" onClick={() => navigate('/forum/new')}>
            New Thread
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setGuidelinesOpen(true)}
            size="small"
          >
            Forum Guidelines
          </Button>
        </Box>
        
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Sidebar
              categories={categories}
              selectedCategory={selectedCategory}
              onSelectCategory={setSelectedCategory}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            {threads.length === 0 ? (
              <Typography sx={{ color: 'white' }}>No threads available.</Typography>
            ) : (
              <Grid container spacing={2}>
                {threads.map((thread) => (
                  <Grid item xs={12} key={thread.id}>
                    <Paper
                      sx={{ p: 2, cursor: 'pointer' }}
                      onClick={() => navigate(`/forum/thread/${thread.id}`)}
                    >
                      <Typography variant="h5">{thread.title}</Typography>
                      <Typography variant="body1" sx={{ mt: 1 }}>
                        {thread.content.substring(0, 100)}...
                      </Typography>
                      <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
                        Posted by: {thread.users.username} on {new Date(thread.createdAt).toLocaleString()}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
      
      {/* Guidelines Dialog */}
<Dialog open={guidelinesOpen} onClose={() => setGuidelinesOpen(false)}>
  <DialogTitle>Forum Guidelines</DialogTitle>
  <DialogContent dividers>
    <Typography variant="body1" gutterBottom>
      We’re all here because we care about our children’s education, so let’s keep this a supportive and welcoming space. Please keep the following in mind:
    </Typography>
    <List>
      <ListItem>
        <ListItemText primary="Be respectful – Healthy discussions are great, but let’s keep things kind." />
      </ListItem>
      <ListItem>
        <ListItemText primary="No spam or excessive self-promotion – Sharing useful resources is always welcome, just don’t overdo it." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Keep it appropriate – Please avoid anything harmful, offensive, or NSFW." />
      </ListItem>
      <ListItem>
        <ListItemText primary="If an issue comes up, report it – Moderators are here to help, so flag any concerns rather than letting things escalate." />
      </ListItem>
    </List>
    <Typography variant="body1">
      That’s it! Thanks for being part of the community and making this a great place to chat.
    </Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setGuidelinesOpen(false)} color="primary">
      Close
    </Button>
  </DialogActions>
</Dialog>

    </BackgroundBox>
  );
}

// ─────────────────────────────────────────────────────────────────────────────
// NewThread: Lets the user select a category from a dropdown and create a thread
// ─────────────────────────────────────────────────────────────────────────────
function NewThread() {
  const navigate = useNavigate();
  const coreApi = useCoreApi();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(''); // for the dropdown

  // For flagged content confirmation
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [pendingPostData, setPendingPostData] = useState(null);

  // Fetch categories to populate the category dropdown
  useEffect(() => {
    coreApi
      .get('/forum/categories')
      .then((res) => {
        setCategories(res.data.categories || []);
      })
      .catch((err) => console.error('Error fetching categories:', err));
  }, [coreApi]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const postData = { title, content, categoryId: Number(categoryId) };
      const res = await coreApi.post('/forum/thread', postData);
      if (res.status === 202 && res.data.flagged) {
        setPendingPostData(postData);
        setConfirmDialogOpen(true);
      } else {
        navigate(`/forum/thread/${res.data.thread.id}`);
      }
    } catch (error) {
      console.error('Error creating thread:', error);
    }
  };

  const handleConfirm = async () => {
    if (!pendingPostData) return;
    try {
      const forcedData = { ...pendingPostData, force: true };
      const res = await coreApi.post('/forum/thread', forcedData);
      setConfirmDialogOpen(false);
      navigate(`/forum/thread/${res.data.thread.id}`);
    } catch (error) {
      console.error('Error forcing thread post:', error);
    }
  };

  return (
    <BackgroundBox>
      <Container maxWidth="sm" sx={{ py: 4 }}>
        <Paper
          elevation={6}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 2
          }}
        >
          <Typography variant="h4" sx={{ mb: 3, color: '#333' }}>
            Create New Thread
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Content"
              variant="outlined"
              fullWidth
              required
              multiline
              rows={4}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              sx={{ mb: 2 }}
            />

            {/* Category dropdown */}
            <TextField
              select
              label="Category"
              variant="outlined"
              fullWidth
              required
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}
              sx={{ mb: 2 }}
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">-- Choose a category --</option>
              {categories.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))}
            </TextField>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button variant="outlined" color="secondary" onClick={() => navigate('/forum')}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Post Thread
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>

      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle>Confirm Post</DialogTitle>
        <DialogContent>
          <Typography>
            Our moderation system detected potentially offensive language. Are you sure you want to post this?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirm} variant="contained" color="secondary">
            Yes, Post It
          </Button>
        </DialogActions>
      </Dialog>
    </BackgroundBox>
  );
}

// ─────────────────────────────────────────────────────────────────────────────
// ThreadDetail: Displays a thread and its posts, with reply & reporting
// ─────────────────────────────────────────────────────────────────────────────
function ThreadDetail() {
  const { thread_id } = useParams();
  const [thread, setThread] = useState(null);
  const [reply, setReply] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [pendingReply, setPendingReply] = useState(null);
  const [reportReason, setReportReason] = useState('');
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);

  const navigate = useNavigate();
  const coreApi = useCoreApi();

  useEffect(() => {
    coreApi
      .get(`/forum/thread/${thread_id}`)
      .then((res) => setThread(res.data.thread))
      .catch((err) => console.error('Error fetching thread:', err));
  }, [thread_id]);

  const refreshThread = async () => {
    try {
      const res = await coreApi.get(`/forum/thread/${thread_id}`);
      setThread(res.data.thread);
    } catch (error) {
      console.error(error);
    }
  };

  const handleReplySubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await coreApi.post(`/forum/thread/${thread_id}/post`, { content: reply });
      if (res.status === 202 && res.data.flagged) {
        setPendingReply(reply);
        setConfirmDialogOpen(true);
      } else {
        refreshThread();
        setReply('');
      }
    } catch (error) {
      console.error('Error posting reply:', error);
    }
  };

  const handleConfirmReply = async () => {
    try {
      const res = await coreApi.post(`/forum/thread/${thread_id}/post`, { content: pendingReply, force: true });
      setConfirmDialogOpen(false);
      setPendingReply(null);
      refreshThread();
      setReply('');
    } catch (error) {
      console.error('Error forcing reply post:', error);
    }
  };

  const handleReport = (postId) => {
    setSelectedPostId(postId);
    setReportDialogOpen(true);
  };

  const submitReport = async () => {
    try {
      await coreApi.post('/forum/report', { postId: selectedPostId, reason: reportReason });
      setReportDialogOpen(false);
      setReportReason('');
      setSelectedPostId(null);
    } catch (err) {
      console.error('Error reporting post:', err);
    }
  };

  if (!thread) {
    return (
      <BackgroundBox>
        <Container>
          <Typography sx={{ color: 'white' }}>Loading thread...</Typography>
        </Container>
      </BackgroundBox>
    );
  }

  return (
    <BackgroundBox>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Button
          variant="outlined"
          onClick={() => navigate('/forum')}
          sx={{ mb: 2, color: 'white', borderColor: 'white' }}
        >
          ← Back to Forum Threads
        </Button>
        <Typography variant="h3" sx={{ mb: 2, color: 'white' }}>
          {thread.title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2, color: 'white' }}>
          {thread.content}
        </Typography>
        <Typography variant="caption" sx={{ display: 'block', mb: 3, color: 'white' }}>
          Posted by: {thread.users.username} on {new Date(thread.createdAt).toLocaleString()}
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, color: 'white' }}>
            Replies
          </Typography>
          {thread.forumPost.map((post) => (
            <Paper key={post.id} sx={{ p: 2, mb: 2 }}>
              <Typography variant="body1">{post.content}</Typography>
              <Typography variant="caption" sx={{ display: 'block', mb: 1 }}>
                Posted by: {post.users.username} on {new Date(post.createdAt).toLocaleString()}
              </Typography>
              <Button variant="text" color="secondary" onClick={() => handleReport(post.id)}>
                Report
              </Button>
            </Paper>
          ))}
        </Box>

        <Box component="form" onSubmit={handleReplySubmit}>
          <TextField
            label="Your Reply"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            required
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button type="submit" variant="contained" color="primary">
            Post Reply
          </Button>
        </Box>

        <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
          <DialogTitle>Confirm Post</DialogTitle>
          <DialogContent>
            <Typography>
              Our moderation system detected potentially offensive language. Are you sure you want to post this?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleConfirmReply} variant="contained" color="secondary">
              Yes, Post It
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={reportDialogOpen} onClose={() => setReportDialogOpen(false)}>
          <DialogTitle>Report Post</DialogTitle>
          <DialogContent>
            <TextField
              label="Reason"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              required
              value={reportReason}
              onChange={(e) => setReportReason(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setReportDialogOpen(false)}>Cancel</Button>
            <Button onClick={submitReport} variant="contained" color="secondary">
              Submit Report
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </BackgroundBox>
  );
}

export { ForumList, NewThread, ThreadDetail };
