import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
  Button,
  Card,
  CardContent,
  Grid,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import useApi from '../Utils/useApi';
import useCoreApi from '../Utils/useCoreApi'; 
import LoadingSpinner from '../Utils/LoadingSpinner';  // Make sure this path is correct
const SUBJECT_ID = 6;
const defaultTheme = createTheme();

function ICT() {
  const navigate = useNavigate();
  const api = useApi();
  const coreApi = useCoreApi();


  // ----------------------------------------------------------------
  // 1) AUTH & INITIAL SETUP
  // ----------------------------------------------------------------
  const access_token = localStorage.getItem('accessToken');
  const student_id = localStorage.getItem('user_id');

  // Safely parse "student_year" from localStorage or default to 1
  const parsedYear = parseInt(localStorage.getItem('student_year'), 10);
  const initialYear = Number.isNaN(parsedYear) ? 1 : parsedYear;

  // ----------------------------------------------------------------
  // 2) HOOK STATES
  // ----------------------------------------------------------------
  const [selectedYear, setSelectedYear] = useState(initialYear);

  const [subTopics, setSubTopics] = useState([
    // (Your entire subtopic array for ICT)
    {id: 146, title: "Digital Discovery: Exploring Computers and Devices", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
    {id: 147, title: "My First Steps in Programming: Understanding Algorithms and Making Simple Programs", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
    {id: 148, title: "Magic of Logic: Predicting What Programs Will Do", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 149, title: "Digital Art and Storytelling: Creating and Organising Our Ideas", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
    {id: 150, title: "Safe Surfers: Using Technology Respectfully and Safely", year: 1, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
    {id: 151, title: "Tech Detectives: Discovering More About Computers Around Us", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
    {id: 152, title: "Coding Quests: Taking Our Programs to the Next Level", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
    {id: 153, title: "Logic Labyrinths: Solving Puzzles and Challenges with Predictions", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 154, title: "Digital Storymasters: Advanced Digital Creation and Expression", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
    {id: 155, title: "Cyber Protectors: Becoming Digital Citizens and Advocates", year: 2, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
    {id: 156, title: "Programming Pioneers: Designing and Debugging Our Own Programs", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
    {id: 157, title: "Code Crafters: Sequences, Choices, and Loops in Programs", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
    {id: 158, title: "Algorithm Architects: Understanding and Fixing Algorithms", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 159, title: "Net Navigators: Exploring the World of Computer Networks and the Internet", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
    {id: 160, title: "Search Savvy: Mastering Digital Searches and Evaluating Content", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalFootprint.avif" },
    {id: 161, title: "Digital Designers: Crafting Projects with Varied Software and Devices", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
    {id: 162, title: "Online Guardians: Safe and Responsible Tech Use", year: 3, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
    {id: 163, title: "Advanced Algorithms: Diving Deeper into Program Design", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 164, title: "Coding Champions: Advanced Structures and Data Handling in Programs", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
    {id: 165, title: "Network Nomads: The Wider World of Networks and Their Uses", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
    {id: 166, title: "Digital Detectives: Advanced Search Techniques and Digital Evaluation", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalFootprint.avif" },
    {id: 167, title: "Creative Coders: Merging Art and Technology in Digital Projects", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
    {id: 168, title: "Data Dynamics: Exploring the Power of Data in the Digital World", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 169, title: "Cyber Stewards: Evolving Digital Safety and Online Etiquette", year: 4, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
    {id: 170, title: "Mastering Modules: Developing Modular Programs and Software Components", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
    {id: 171, title: "Binary Boffins: Introduction to Binary and Data Representation", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Binary.avif" },
    {id: 172, title: "Web Wizards: Building Basic Web Pages and Understanding the Internets Structure", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
    {id: 173, title: "Algorithm Analysis: Evaluating Efficiency and Effectiveness", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 174, title: "Dynamic Designers: Advanced Multimedia Projects and Interactive Designs", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
    {id: 175, title: "Cloud Climbers: Introduction to Cloud Computing and its Advantages", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
    {id: 176, title: "Digital Citizens: The Broader Impacts of Technology on Society", year: 5, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalFootprint.avif" },
    {id: 177, title: "Complex Coders: Exploring Advanced Programming Techniques", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
    {id: 178, title: "Database Dynamos: Introduction to Relational Databases and SQL", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
    {id: 179, title: "Network Navigators: Delving Deeper into Network Protocols and Services", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
    {id: 180, title: "Game Design Gurus: Foundations of Game Development and Logic", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 181, title: "Digital Ethics Explorers: Addressing the Ethical Implications of Technology", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalFootprint.avif" },
    {id: 182, title: "Cybersecurity Captains: Basic Principles of Digital Security and Threats", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
    {id: 183, title: "Future Innovators: Exploring Emerging Technologies and Trends", year: 6, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalFootprint.avif" },
    {id: 184, title: "Computational Models: Designing and Evaluating Models of Real-world Problems", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 185, title: "Algorithm Architects: Understanding Key Algorithms and Logical Reasoning", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 186, title: "Polyglot Programmers: Exploring Multiple Programming Languages and Modular Design", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
    {id: 187, title: "Binary Basics and Boolean Logic: Foundations of Digital Number Systems and Logic Circuits", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Binary.avif" },
    {id: 188, title: "Inside the Machine: A Glimpse into Computer Hardware and Software", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
    {id: 189, title: "Digital Artistry: Creating and Repurposing Digital Artefacts", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
    {id: 190, title: "Cybersecurity Cadets: Responsible and Safe Use of Technology", year: 7, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
    {id: 191, title: "Advanced Computational Models: Refining and Optimising Models for Complex Systems", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 192, title: "Algorithmic Masterminds: Delving Deeper into Algorithms and Enhanced Logical Reasoning", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 193, title: "Next-Level Programming: Advanced Language Features and Data Structures", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
    {id: 194, title: "Beyond Binary: Advanced Operations and Boolean Logic in Action", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Binary.avif" },
    {id: 195, title: "System Synergies: Exploring Advanced Computer Interactions", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
    {id: 196, title: "Digital Creations: Advanced Projects for Specific Audiences and Purposes", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
    {id: 197, title: "Cybersecurity Champions: Deepening Understanding of Online Security and Ethics", year: 8, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
    {id: 198, title: "Complex Systems and Abstractions: Advanced Modeling and Real-World Applications", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 199, title: "Algorithmic Excellence: Mastery of Complex Algorithms and Evaluative Techniques", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 200, title: "Code Craftsmanship: Mastery in Programming and Advanced Data Management", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
    {id: 201, title: "Digital Number Wizardry: Dive into Advanced Binary Operations and Logic Circuits", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Binary.avif" },
    {id: 202, title: "Systems Unleashed: Delving into Computer Architectures and Advanced Processing Techniques", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
    {id: 203, title: "Creative Digital Mastery: Designing Sophisticated Digital Projects with a User-Centric Focus", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
    {id: 204, title: "Guardians of the Web: Advanced Cybersecurity Techniques and Digital Etiquette", year: 9, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
    {id: 205, title: "GCSE Foundations: Revisiting Core Concepts in Computer Science and Digital Media", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
    {id: 206, title: "Problem-Solving Powerhouses: Advanced Analytic and Computational Techniques", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 207, title: "Digital Future Preparedness: Adapting to Technological Changes and Predicting Trends", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-ManScreens.avif" },
    {id: 208, title: "Online Shield: Advanced Cybersecurity Protocols and Digital Etiquette", year: 10, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Security1.avif" },
    {id: 209, title: "GCSE Mastery: Comprehensive Revision and Exam Techniques for Success", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-Code.avif" },
    {id: 210, title: "Beyond the Code: Advanced Projects in Computer Science and Digital Media", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalArt.avif" },
    {id: 211, title: "Think Like a Computer Scientist: Advanced Computational Thinking Challenges", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DataStructures.avif" },
    {id: 212, title: "The Digital Footprint: Advanced Digital Safety, Privacy, and Ethical Considerations", year: 11, image: "https://websitecontent.ams3.cdn.digitaloceanspaces.com/ICT-DigitalFootprint.avif" }


  ]);

  const [lessonsBySubtopic, setLessonsBySubtopic] = useState({});
  const [completedLessons, setCompletedLessons] = useState([]);

  // Single loading and error states
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userHasChosenYear, setUserHasChosenYear] = useState(false);

  // ----------------------------------------------------------------
  // 3) AXIOS INTERCEPTORS
  //    Moved near the top so it’s not in a conditional area
  // ----------------------------------------------------------------
  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response && err.response.status === 401) {
        navigate('/StudentLogin');
      }
      return Promise.reject(err);
    }
  );

  // ----------------------------------------------------------------
  // 4) REDIRECT IF NO TOKEN (USE EFFECT)
  // ----------------------------------------------------------------
  useEffect(() => {
    if (!access_token) {
      console.warn('Missing access token. Redirecting to login.');
      navigate('/StudentLogin');
    }
  }, [access_token, navigate]);

  // ----------------------------------------------------------------
  // 5) DATA FETCHING FUNCTIONS
  // ----------------------------------------------------------------

  // 5A) Fetch the last completed year for ICT(subjectID 6)
  const fetchLastCompletedYear = async () => {
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/last_completed_year_for_subject/${SUBJECT_ID}`,
        { headers: { Authorization: `Bearer ${access_token}` }, timeout: 5000 }
      );
      if (
        response.status >= 200 && 
        response.status < 300 && 
        response.data?.year_id
      ) {
        // Only setSelectedYear if userHasChosenYear is still false
        // meaning the user hasn't manually changed it yet
        if (!userHasChosenYear) {
          setSelectedYear(response.data.year_id);
        }
      }
    } catch (err) {
      console.error('Error fetching last completed year:', err);
    }
  };
  

  // 5B) Fetch “completed lessons” for subtopics in the chosen year
  const fetchAllCompletedLessons = async (year) => {
    const uniqueSubTopics = [
      ...new Set(subTopics.filter((st) => st.year === Number(year)).map((st) => st.id)),
    ];

    let allCompleted = [];
    for (let subTopicId of uniqueSubTopics) {
      const theseCompleted = await fetchCompletedLessons(subTopicId, year);
      allCompleted = [...allCompleted, ...theseCompleted];
    }
    setCompletedLessons(allCompleted);
    console.log('All Completed Lessons:', allCompleted);
  };

  // Helper: fetch completed lessons for a single subtopic
  const fetchCompletedLessons = async (subTopicId, year) => {
    // For subTopics like “quiz links” that aren’t numeric, skip
    if (typeof subTopicId !== 'number') return [];
    try {
      const response = await coreApi.get(
        `/progress/student/${student_id}/completed_lessons/6/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        return response.data.map((lesson) => lesson.lesson_id);
      }
      return [];
    } catch (err) {
      console.error('Error fetching completed lessons:', err);
      return [];
    }
  };

  // 5C) Fetch “lessons” for subtopics in the chosen year
  const fetchLessonsForYear = async (year) => {
    setLessonsBySubtopic({}); // reset

    const uniqueSubTopics = [
      ...new Set(subTopics.filter((st) => st.year === Number(year)).map((st) => st.id)),
    ];

    for (let subTopicId of uniqueSubTopics) {
      const lessons = await fetchLessonsBySubtopic(subTopicId, year);
      const completedIds = completedLessons;

      // Mark them as completed if in completedLessons
      const updated = lessons.map((lesson) => ({
        ...lesson,
        is_completed: completedIds.includes(lesson.id),
      }));

      setLessonsBySubtopic((prev) => ({ ...prev, [subTopicId]: updated }));
    }
  };

  // Helper: fetch lessons for a single subtopic
  const fetchLessonsBySubtopic = async (subTopicId, year) => {
    // skip if subTopicId is not numeric
    if (typeof subTopicId !== 'number') return [];
    try {
      const response = await coreApi.get(
        `/lessons/lessons_by_subject/6/${subTopicId}/${year}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        // In your original code, you filtered out only is_completed==true
        // If you still want that logic, you can do:
        //   return response.data.filter(lesson => lesson.is_completed);
        // But if you want all lessons, do:
        return response.data;
      }
      return [];
    } catch (err) {
      console.error('Error fetching lessons:', err);
      return [];
    }
  };

  // 5D) Master fetch function: fetch last year, then completed lessons, then lessons
  const fetchAllData = async (year) => {
    setIsLoading(true);
    setError(null);
    try {
      // 1) Possibly fetch last completed year first:
      await fetchLastCompletedYear();

      // 2) Then fetch all completed lessons for that year
      await fetchAllCompletedLessons(year);

      // 3) Then fetch the actual lesson data
      await fetchLessonsForYear(year);
    } catch (err) {
      console.error('fetchAllData error:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------------------------------------------------
  // 6) EFFECTS
  // ----------------------------------------------------------------

  // On mount, fetch last completed year & relevant data
  useEffect(() => {
    (async () => {
      await fetchAllData(selectedYear);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When user changes selectedYear, refetch
  useEffect(() => {
    fetchAllData(selectedYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  // ----------------------------------------------------------------
  // 7) RENDER HELPERS
  // ----------------------------------------------------------------
  const CustomMenuItem = ({ completed, children, ...props }) => {
    return (
      <MenuItem {...props}>
        {completed ? (
          <ListItemText
            primary={children}
            primaryTypographyProps={{
              style: { textDecoration: 'line-through', color: 'green' },
            }}
          />
        ) : (
          <ListItemText primary={children} />
        )}
      </MenuItem>
    );
  };

  const renderYearDropdown = () => (
    <FormControl fullWidth>
      <Select
              value={selectedYear}
              // once the user picks a new year, mark userHasChosenYear = true
              onChange={(e) => {
                setUserHasChosenYear(true);
                setSelectedYear(Number(e.target.value));
              }}
      >
        {[...Array(11).keys()].map((_, idx) => {
          const yearVal = idx + 1;
          return (
            <MenuItem key={yearVal} value={yearVal}>
              Year {yearVal}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const renderSubtopicCards = () => {
    const relevantSubTopics = subTopics.filter(
      (subTopic) => subTopic.year === Number(selectedYear)
    );

    return (
      <Grid container spacing={4}>
        {relevantSubTopics.map((subTopic, idx) => (
          <Grid item key={idx} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia component="img" height="140" image={subTopic.image} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {subTopic.title}
                </Typography>
                {/* If it's a static link or special quiz link, handle that differently */}
                {subTopic.isStaticLink ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/TimesTableQuiz')}
                  >
                    Go to Quiz
                  </Button>
                ) : (
                  <FormControl fullWidth>
                    <Select
                      defaultValue=""
                      onChange={(event) => navigate(`/Classroom/${event.target.value}`)}
                    >
                      <MenuItem value="" disabled>
                        Select a lesson
                      </MenuItem>
                      {isLoading && <MenuItem disabled>Loading...</MenuItem>}
                      {error && !lessonsBySubtopic[subTopic.id]?.length && (
                        <MenuItem disabled>Error: {error}</MenuItem>
                      )}
                      {lessonsBySubtopic[subTopic.id]?.map((lesson) => {
                        const isCompleted = completedLessons.includes(lesson.id);
                        return (
                          <CustomMenuItem
                            key={lesson.id}
                            value={lesson.id}
                            completed={isCompleted}
                          >
                            {lesson.title}
                          </CustomMenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  // ----------------------------------------------------------------
  // 8) FINAL RENDER
  // ----------------------------------------------------------------

  // If no token, short-circuit (after useEffect tries to redirect)
  if (!access_token) {
    return null;
  }

  // Show spinner if loading
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate('/LessonSelect')}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            ICT
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a year first.
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Don't be scared to drop down or move up years, there's value in
          practicing the basics, or going for a challenge!
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <b>Year Selector</b>
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
          (Note: This will default to the year of the last ICT lesson
          you completed, but feel free to change it whenever!)
        </Typography>

        <Container maxWidth="lg">
          {renderYearDropdown()}
          <Box sx={{ mt: 3 }}>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              <b>Lesson Selector</b>
            </Typography>
            {renderSubtopicCards()}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default ICT;
